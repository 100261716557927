import { fansupportPrivate } from "../index";
/**
 * Sends a POST request to change the user's email.
 * 
 * @async
 * @function
 * @param {string} email - The new email address.
 * @throws {Error} If the request fails.
 * @returns {Promise} A Promise that resolves to the response data.
 */
export const postChangeEmail = async ( email ) => {
    try {
        const data =  fansupportPrivate.post('/identity/change-email', {
            email: email,
    })
        return data;

    } catch (error) {
        console.log("postChangeEmail",error);
    }
}