import { fansupportPublic } from "../index";
/**
 * `getAdsMain` is an asynchronous function that fetches main ad data from the API based on the provided countryId, languageId, and platformType. It returns a Promise that resolves to the fetched data in JSON format.
 *
 * @param {Object} options - An object containing the following properties:
 * @param {string} options.countryId - The ID of the country to fetch ad data for.
 * @param {string} options.languageId - The ID of the language to fetch ad data in.
 * @param {number} options.platformType - The type of platform to fetch ad data for.
 * @return {Promise<Object>} A Promise that resolves to the fetched data in JSON format.
 */
export const getAdsMain = async ({ countryId, languageId, platformType }) => {
    return fansupportPublic.get('/media-content/main-media', {params: {
        countryId,
        languageId,
        platformType
    }})
}