/* This code snippet is a React component named `AppFeatures` that displays a list of app features
along with an image. Here's a breakdown of what the code is doing: */
import React from 'react'
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import AboutusSecond from "../../assets/about-us/static_phone_left.webp"


const AppFeatures = () => {
  const { t } = useTranslation();
  return (
    <>
    <AppFeaturesContainer>

      <AppFeaturesLeft>
        <AppFeaturesTitle>{t("appFeatures")}</AppFeaturesTitle>
        <AppFeaturesUl>
            <AppFeaturesLi className='app-features-list'>{t("appFeatureOne")}</AppFeaturesLi>
            <AppFeaturesLi className='app-features-list'>{t("appFeatureTwo")}</AppFeaturesLi>
            <AppFeaturesLi className='app-features-list'>{t("appFeatureThree")}</AppFeaturesLi>
            <AppFeaturesLi className='app-features-list'>{t("appFeatureFour")}</AppFeaturesLi>
            <AppFeaturesLi className='app-features-list'>{t("appFeatureFive")}</AppFeaturesLi>
            <AppFeaturesLi className='app-features-list'>{t("appFeatureSix")}</AppFeaturesLi>
        </AppFeaturesUl>
      </AppFeaturesLeft>

      <AppFeaturesRight>
        <AppFeaturesİmageWrapper>
          <AppFeaturesİmage src={AboutusSecond} alt="" />  
        </AppFeaturesİmageWrapper>        
      </AppFeaturesRight>
    </AppFeaturesContainer>
    </>
  )
}

export default AppFeatures


export const AppFeaturesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    gap: 20px;
    margin: 0px 50px;

    @media (min-width: 720px) {
      display: flex;
      gap: 50px;
    }
`;
export const AppFeaturesRight = styled.div``;
export const AppFeaturesTitle = styled.h2`
  color: orange;
  text-align: start;
  margin-bottom: 10%;
`;
export const AppFeaturesUl = styled.ul`
  color: black;
  margin: 0;
  padding: 0;
`;
export const AppFeaturesLi = styled.li`
  color: black;
  text-align: start;
`;
export const AppFeaturesLeft = styled.div``;
export const AppFeaturesİmage = styled.img`
  max-width: 350px;
  height: 400px;
`;
export const AppFeaturesİmageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;