import { fansupportPublic } from "../index";
/**
 * Asynchronously fetches detailed information about a team from the server.
 *
 * @async
 * @function
 * @name getTeamDetail
 * @param {Object} options - An object containing the following properties:
 * @param {string} options.teamId - The ID of the team to fetch details for.
 * @return {Promise<Object>} A Promise that resolves to the team details in JSON format.
 * @throws {Error} If an error occurs during the request.
 */
export const getTeamDetail = async ({ teamId }) => {
    return fansupportPublic.get('/sport/team-detail',{ params: { 
        teamId,
    }})
}