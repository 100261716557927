import { fansupportPublic } from "../index";
/**
 * Fetches campaign data from the server.
 *
 * @async
 * @function
 * @name getCampaign
 * @param {Object} options - An object containing the following properties:
 * @param {string} options.id - The ID of the campaign to fetch.
 * @param {number} options.campaignType - The type of campaign to filter by.
 * @param {string} options.sportId - The ID of the sport to filter by.
 * @param {string} options.countryId - The ID of the country to filter by.
 * @param {string} options.tournamentId - The ID of the tournament to filter by.
 * @param {string} options.teamId - The ID of the team to filter by.
 * @param {string} options.organisationId - The ID of the organisation to filter by.
 * @param {number} options.status - The status of the campaign to filter by.
 * @param {string} options.sort - The sort order of the campaigns to fetch.
 * @param {number} options.page - The page number of campaigns to fetch.
 * @param {number} options.perPage - The number of campaigns to fetch per page.
 * @return {Promise<Object>} A Promise that resolves to campaign data in JSON format.
 * @throws {Error} If an error occurs during the request.
 */
export const getCampaign = async ({ id, campaignType, sportId, countryId, tournamentId, teamId, organisationId, status, sort, page, perPage }) => {
    return fansupportPublic.get('/campaign/campaigns', {params: {
        id,
        campaignType,
        sportId, 
        countryId,
        tournamentId,
        teamId,
        organisationId,
        status,
        sort,
        page,
        perPage,
    }})
}