import { fansupportPublic } from "./index";
/**
 * Asynchronously fetches the rounds of a specific tournament season from the API.
 *
 * @async
 * @function
 * @name getRound
 * @param {Object} options - An object containing the following properties:
 * @param {string} options.sportId - The ID of the sport to fetch rounds for.
 * @param {string} options.tournamentId - The ID of the tournament to fetch rounds for.
 * @param {string} options.seasonId - The ID of the season to fetch rounds for.
 * @returns {Promise<Object>} A Promise that resolves to round data in JSON format.
 * @throws {Error} If an error occurs during the request.
 */
export const getRound = async ({sportId, tournamentId, seasonId}) => {
    if(!sportId || !tournamentId || !seasonId) {
        return Promise.reject(new Error("Sport ID, Tournament ID is missing."));
    }
    return fansupportPublic.get('/sport/stages-rounds', {params: {
        sportId,
        tournamentId,
        seasonId
    }})
}