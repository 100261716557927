/* 
This React component, `UserJoinedCampaigns`, is used to display a section of campaigns that a user has joined. It relies on `styled-components` for styling and imports the `CampaignsUserJoinedCard` component to render the specific content related to joined campaigns.

The component:
- Defines a main container `CampaignsUserJoinedMain` with a light gray background and vertical padding to space the content.
- Uses `CardContainer` to create a flexible grid layout for displaying `CampaignsUserJoinedCard` components. This layout is centered, allows items to wrap onto multiple lines, and maintains a gap between cards.
- Ensures the card container is centered horizontally and has a maximum width of 1200px to maintain consistency in design.

The `UserJoinedCampaigns` component renders the main container and places the `CampaignsUserJoinedCard` inside the `CardContainer`.
*/
import React from 'react';
import styled from "styled-components";
import { CampaignsUserJoinedCard } from "../../components";

const UserJoinedCampaigns = () => {
  return (
    <CampaignsUserJoinedMain className='campaigns-user-joined-main'>
      <CardContainer>
        <CampaignsUserJoinedCard />
      </CardContainer>
    </CampaignsUserJoinedMain>
  )
}

export default UserJoinedCampaigns

export const CampaignsUserJoinedMain = styled.main`
  background-color: #f8f8f8;
  padding: 80px 0;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 50px;
  max-width: 1200px;
  margin: 0 auto;
`;