/* 
This React component, `UserCertificates`, is designed to display a section of user certificates within a campaign. It utilizes `styled-components` for styling and imports the `CampaignsUserCertificatesCard` component to render the actual content.

The component:
- Defines a main container `CampaignsUserCertificatesMain` with a light gray background and vertical padding.
- Uses `CardContainer` to style a flexible grid layout where the `CampaignsUserCertificatesCard` components are displayed. This layout centers the cards, allows them to wrap, and provides a gap between them.
- Positions the card container centrally and restricts its width to a maximum of 1200px.

The `UserCertificates` component renders the main container and places the `CampaignsUserCertificatesCard` inside the `CardContainer`.
*/
import React from 'react';
import styled from "styled-components";
import { CampaignsUserCertificatesCard } from "../../components";

const UserCertificates = () => {
  return (
    <CampaignsUserCertificatesMain className='campaigns-user-certificates-main'>
    <CardContainer>
      <CampaignsUserCertificatesCard />
    </CardContainer>
  </CampaignsUserCertificatesMain>
  )
}

export default UserCertificates

export const CampaignsUserCertificatesMain = styled.main`
  background-color: #f8f8f8;
  padding: 80px 0;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 50px;
  max-width: 1200px;
  margin: 0 auto;
`;