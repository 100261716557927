/*
  ErrorComponent: This React functional component displays an error message in a modal when an error is present in the Redux state.

  - `isError` and `errorMessage`: Extracted from the Redux store, `isError` indicates if an error exists, and `errorMessage` contains the corresponding error message.
  - `useDisclosure`: Custom hook from Mantine to manage the open/close state of the modal.
  - `handleCloseError`: Function that clears the error from the Redux store and closes the modal.
  
  Styled Components:
  - `ErrorImage`: Styled `img` tag for displaying the error image with responsive width.
  - `ModalMantine`: Styled Mantine `Modal` component with custom padding.
  - `ErrorMessage`: Styled `p` tag for displaying the error message with specific styling.
  - `ButtonWrapper`: Styled `div` for aligning buttons to the end of the modal, though not used in this component.
*/
import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { clearError } from "../../redux/features/error/errorSlice";
import { Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

const ErrorComponent = () => {
  const isError = useSelector((state) => state.error.isError);
  const errorMessage = useSelector((state) => state.error.errorMessage);
  const dispatch = useDispatch();
  const [opened, { open, close }] = useDisclosure(true);

  const handleCloseError = () => {
    dispatch(clearError());
    close();
  };

  return (
    <>
      {isError && (
        <ModalMantine opened={opened} onClose={handleCloseError} centered>
            <ErrorImage src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/f430d17e-b310-4dda-d842-6fd11e6a6d00/original" alt="" />
            <ErrorMessage>{errorMessage}</ErrorMessage>
        </ModalMantine>
      )}
    </>
  );
};

export default ErrorComponent;

export const ErrorImage = styled.img`
  width: 100%;
  height: auto;
`;

export const ModalMantine = styled(Modal)`
  padding: 0px;
`;

export const ErrorMessage = styled.p`
  color: black;
  margin-top: 20px;
  font-size: 18px;
`;

export const ButtonWrapper = styled.div`
  text-align: end;
`;
