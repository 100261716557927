import { configureStore } from '@reduxjs/toolkit';
import  authSlice  from "../features/auth/authSlice";
import filterSlice from "../features/filter/filterSlice";
import errorSlice from "../features/error/errorSlice";

// Configure the Redux store
export const store = configureStore({
  reducer: {
    // Authentication related state
    user: authSlice,
    // Filtering options state
    filter: filterSlice,
    // Error handling state
    error: errorSlice,
  },

});
