import React from "react";
import styled from 'styled-components';
import { Breadcrumbs } from '../../components';
import image from "../../assets/3-slider.webp"
import { useTranslation } from "react-i18next";
/**
 * `ForTheGoals` Component
 * 
 * This component displays detailed information about the "Football For The Goals" campaign. It includes:
 * 
 * 1. **CampaignDetailBackground**: A section that contains the background image and title for the campaign.
 *    - **CampaignDetailImg**: The background image for the campaign.
 *    - **CampaignDetailTitleBreadcrumbsContainer**: Container for the title and breadcrumb navigation.
 *      - **CampaignDetailTitleContainer**: Holds the title of the campaign.
 *      - **CampaignDetailTitle**: Displays the main title of the campaign.
 *      - **Breadcrumbs**: A component for showing the navigation trail.
 * 
 * 2. **DetailContainer**: Contains the main content of the campaign details.
 *    - **DetailWrapper**: A styled container that holds the title, description, and additional information. Includes hover effects for improved user interaction.
 *      - **Title**: Main heading for the campaign details.
 *      - **Description**: Brief description of the campaign.
 *      - **Section**: Contains additional information and links.
 *        - **SubTitle**: Subtitle for the section.
 *        - **Paragraph**: Contains links with additional information about the campaign and related topics.
 * 
 * **Key Functionalities**:
 * - Utilizes `react-i18next` for text translation, allowing dynamic content based on the selected language.
 * - Employs styled-components for custom styling and responsive design.
 * - Includes links to external resources for further information about the campaign.
 * 
 * **Components and Elements**:
 * - `DetailContainerMain`: Main wrapper for the component, with a background color.
 * - `CampaignDetailBackground`: Section that contains the background image and title.
 * - `CampaignDetailImg`: Displays the campaign's background image.
 * - `CampaignDetailTitleBreadcrumbsContainer`: Holds the title and breadcrumb navigation.
 * - `DetailContainer`: Wrapper for the detailed content.
 * - `DetailWrapper`: Contains the main content with styling.
 * - `Title`, `Description`, `Body`, `Section`, `SubTitle`, `Paragraph`, `Link`: Various styled elements used to format and display the content.
 * 
 * **Styling**:
 * - Styled-components are used to manage custom styles and layout.
 * - Hover effects and responsive design are included to enhance user experience.
 * 
 * **Usage**:
 * - This component should be used in a route or layout where detailed information about the "Football For The Goals" campaign needs to be displayed.
 */

const ForTheGoals = () => {

  const { t } = useTranslation();

  return (
    <>
      <DetailContainerMain className="home-main">
        <CampaignDetailBackground>
          <CampaignDetailImg src={image} alt=""/>

          <CampaignDetailTitleBreadcrumbsContainer>
            <CampaignDetailTitleContainer>
              <CampaignDetailTitle>
              Football For The Goals
              </CampaignDetailTitle>
            </CampaignDetailTitleContainer>

            <Breadcrumbs />
          </CampaignDetailTitleBreadcrumbsContainer>
        </CampaignDetailBackground>

        <DetailContainer className="home-main">
          <DetailWrapper className="campaign-card">
            <Title>
              {t("forTheGoalsTitle")}</Title>
            <Description className="news-from-us-detail">
              {t("forTheGoalsDesc")}
            </Description>
            
            <Section className="news-from-us-detail">
              <SubTitle>{t("detailedInformation")}</SubTitle>
              <Paragraph>
                <Link href="https://sdgs.un.org/goals" target="_blank" rel="noopener noreferrer">THE 17 GOALS | Sustainable Development</Link>
              </Paragraph>
              <Paragraph>
                <Link href="https://www.un.org/en/footballforthegoals" target="_blank" rel="noopener noreferrer">Football for the Goals | United Nations</Link>
              </Paragraph>
            </Section>

          </DetailWrapper>
        </DetailContainer>
        
      </DetailContainerMain>
    </>
  );
};

export default ForTheGoals;

export const DetailContainerMain = styled.main`
    background-color: #F8F9FA;
`;

export const DetailContainer = styled.div`
    margin: 30px 0;
    background-color: #fff;
`;
export const DetailWrapper = styled.div`
    max-width: 800px;
    margin: 0 auto;
    padding: 20px 20px;
    padding-bottom: 30px;
    border-radius: 8px;
    border: 1px solid rgb(219, 224, 229); 
    &:hover{
      // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; 
       box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
`;

export const Title = styled.h1`
    font-size: 24px;
    margin-bottom: 10px;
`;


export const Description = styled.p`
    font-size: 16px;
    color: #333;
    margin-bottom: 20px;
`;

export const Body = styled.div`
    font-size: 18px;
    line-height: 1.6;
    color: #555;
`;


export const CampaignDetailBackground = styled.div`
  width: 100%;
`;

export const CampaignDetailImg = styled.img`
  width: 100%;
  height: auto;
`;

export const CampaignDetailTitleBreadcrumbsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  bottom: 4px;
  padding: 20px 30px;
  background-color: #033036;
`;

export const CampaignDetailTitleContainer = styled.div`
  display: flex;
`;


export const CampaignDetailTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
  color: white;
`;

export const Section = styled.section`
  margin-bottom: 20px;
`;

export const SubTitle = styled.h6`
  font-size: 15px;
  color: black;
  margin-bottom: 10px;
  font-weight: 500;
`;

export const Paragraph = styled.div`
  font-size: 16px;
  color: rgb(127, 140, 141);
  line-height: 1.6;
`;

export const Link = styled.a`
  color: #3498db;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
