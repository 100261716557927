/**
 * `homeRail` and `homeRail2` are arrays of objects that represent items for display in different sections of the homepage.
 * Each object in these arrays contains details for an individual item, including an image, a title, a description, and a URL.
 *
 * `homeRail` contains items with local images and corresponding text keys. Each item includes:
 * - `img`: The path to a local SVG image used as the visual representation.
 * - `title`: The key for the title text, which will be translated using internationalization (i18n).
 * - `desc`: The key for the description text, which will also be translated.
 * - `url`: The URL to which the item links. Currently, set to "#".
 *
 * Example of an item:
 * {
 *   img: conditionalStadium,
 *   title: "matchSupportTitle",
 *   desc: "matchSupportDesc",
 *   url: "#"
 * }
 *
 * `homeRail2` contains items with external image URLs and similar structure as `homeRail`. Each item includes:
 * - `img`: The URL to an external image used as the visual representation.
 * - `title`: The key for the title text, which will be translated using internationalization (i18n).
 * - `desc`: The key for the description text, which will also be translated.
 * - `url`: The URL to which the item links. Currently, set to "#".
 *
 * Example of an item:
 * {
 *   img: "https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/00412628-3cb2-4bae-f8c9-0b6d8c882500/original",
 *   title: "whyFirstTitle",
 *   desc: "whyFirstDesc",
 *   url: "#"
 * }
 *
 * These arrays are used to dynamically generate sections of the homepage with content that is configured and localized.
 */
import conditionalStadium from "../assets/conditional-banner/static-stadium.svg";
import conditionalTrophy from "../assets/conditional-banner/static-trophy.svg";
import conditionalTransfer from "../assets/conditional-banner/static-transfer.svg";


export const homeRail=
[
    {
        img: conditionalStadium,
        title: "matchSupportTitle",
        desc: "matchSupportDesc",
        url: "#"
    },
    {
        img: conditionalTrophy,
        title: "generalSuccesSupportTitle",
        desc: "generalSuccesSupportDesc",
        url: "#"
    },
    {
        img: conditionalTransfer,
        title: "transferSupportTitle",
        desc: "transferSupportDesc",
        url: "#"
    }
]
  
export const homeRail2=
[
    {
        img: "https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/00412628-3cb2-4bae-f8c9-0b6d8c882500/original",
        title: "whyFirstTitle",
        desc: "whyFirstDesc",
        url: "#"
    },
    {
        img: "https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/76e0add1-bdd6-4c53-692c-98f0b7f47a00/original",
        title: "whySecondTitle",
        desc: "whySecondDesc",
        url: "#"
    },
    {
        img: "https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/f8a45a78-89f1-4891-ac0a-2a2918483100/original",
        title: "whyThirdTitle",
        desc: "whyThirdDesc",
        url: "#"
    }
]