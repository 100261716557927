import React, { useEffect, useState } from "react";
// import { useTranslation } from "react-i18next";
import { getDetailedCountries } from "../../services/sport/detailedCountryService";
import styled from "styled-components";
// import { fansupportPublic } from "../../services/index";
// import flagTurkey from "../../assets/tr/flag/flagTurkey.png";
// import flagUK from "../../assets/tr/flag/flagUK.png";
// import flagSpain from "../../assets/tr/flag/flagSpain.png";

 
const LanguageData = () => {

    const [countryData, setCountryData] = useState([]);

    // const { t } = useTranslation();

    const fetchCountry = async () => {
      try {
        const res = await getDetailedCountries();
        const filteredCountries = res?.data?.data.filter(country => (
          country.name !== "Uluslararası" && country.id !== "6498b1e91d6341510544c3bb"
        ));
        setCountryData(filteredCountries);
      } catch (error) {
        console.log("Country", error);
      }
    };
    
    // console.log("languageDataCountry",countryData);

    // const languageData = [
    //     { code: "tr", label: t("turkish"), tag: "(TR)", flag: <LanguageFlag src={flagTurkey} /> },
    //     { code: "en", label: t("english"), tag: "(EN)", flag: <LanguageFlag src={flagUK} /> },
    //     { code: "es", label: t("spain"), tag: "(ES)", flag: <LanguageFlag src={flagSpain} /> },
    // ];

    const languageData = countryData?.map((country) => ({
      languageId: country.id || "",
      code: country.languageCode ? country.languageCode.split('-')[0] : '', 
      languageCode: country.languageCode || "", 
      label: country.name || "",
      languageName: country.language || "",
      tag: `(${country.shortName || ""})`,
      flag: <LanguageFlag src={country.mediumBadge || ""} />
    }));

  useEffect(() => {
    fetchCountry();
  }, []);

  return languageData;

 
}
export default LanguageData

export const LanguageFlag = styled.img`
  width: 50px;
  height: auto;
`;