import { fansupportPrivate } from "../index";
/**
 * Asynchronously adds a team to the user's favorite teams list.
 *
 * @async
 * @function
 * @name getAddFavoriteTeam
 * @param {Object} options - An object containing the following properties:
 * @param {string} options.teamId - The ID of the team to add to favorite teams.
 * @param {string} options.tournamentId - The ID of the tournament the team belongs to.
 * @return {Promise<Object>} A Promise that resolves to the server's response data.
 * @throws {Error} If an error occurs during the request.
 */
export const getAddFavoriteTeam = async ({ teamId, tournamentId }) => {
    try {
        const data = fansupportPrivate.post('/sport/add-favorite-team', {
            teamId,
            tournamentId,
        })
        return data;
        
    } catch (error) {
        console.log("getAddFavoriteTeam",error)
    }
}