/**
 * The CurrencyFormat component formats a given amount as currency based on the selected language and
 * currency ISO code.
 * @returns The CurrencyFormat component is returning the formatted currency amount based on the input
 * `amount` prop. The formatting is done using the Intl.NumberFormat constructor with options such as
 * currency, minimumFractionDigits, maximumFractionDigits, and roundingMode. The formatted currency
 * amount is then rendered within a fragment <>{formatCurrency}</>.
 */
import React from 'react';
import { getStartLanguage } from '../../logic/getStartLanguage';

const CurrencyFormat = ({amount }) => {

  const startLanguage = getStartLanguage();

  const formatCurrency = new Intl.NumberFormat(startLanguage, {
    currency: localStorage.getItem("selectedCurrencyIso") || "EUR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    roundingMode: 'floor'
  }).format(amount);

  return <>{formatCurrency}</>
}

export default CurrencyFormat