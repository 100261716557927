/* 
The `CampaignAllDetail` component is used to display detailed information about various campaigns based on the selected filters and type. It incorporates several components and styles for organizing and displaying the campaign data and advertisements.

**Component Overview:**
1. **State Management:**
   - `campaignAll`: Stores the list of campaigns fetched from the API.
   - `campaignType`: Stores the type of the campaign to determine which component to render.
   - `campaignTotal`: Stores the total number of campaigns available for pagination.
   - `activePage`: Keeps track of the current page for pagination.
   - `adsData`: Stores advertisement data fetched from the API.

2. **Selectors:**
   - Retrieves filter criteria such as sport ID, country ID, tournament ID, team ID, and language ID from the Redux store.

3. **Fetch Functions:**
   - `fetchAds`: Fetches advertisements from the API based on the selected country and language. Sets the `adsData` state with the fetched results.
   - `fetchCampaign`: Fetches campaigns from the API based on various filters and sets `campaignAll`, `campaignTotal`, and `campaignType` states.

4. **Effects:**
   - `useEffect` is used to call `fetchCampaign` and `fetchAds` whenever any of the filter criteria or the active page changes.

5. **Rendering Logic:**
   - Displays advertisements using `AdImageCard` components.
   - Shows a `NotFound` component if no advertisement data is available.
   - Renders different campaign components based on the campaign type:
     - **Event:** Displays `CampaignCompetitionCard` components.
     - **Championship:** Displays `CampaignGeneralSuccess` components.
     - **Transfer:** Displays `CampaignTransfer` components.
   - Includes a `Pagination` component to navigate through the pages of campaigns.

**Styled Components:**
- `CampaignDetailMain`: Sets the main container's maximum width, margin, and padding.
- `BreadcrumbsWrapper`: Provides styling for the breadcrumbs section.
- `EventWrapper`, `GeneralSuccessWrapper`, `TransferWrapper`: Layout containers for different types of campaigns, using a grid layout that adapts to mobile screens.
- `PaginationContainer`: Centers the pagination component and provides margin for spacing.

*/
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import { AdImageCard, Breadcrumbs, CampaignCompetitionCard, CampaignGeneralSuccess, CampaignTransfer, CampaignTypeCard, Filterr } from "../../components";
import { Container, Pagination } from "@mantine/core";
import { useSelector } from "react-redux";
import { getCampaign } from "../../services/campaign/campaignService";
import AdsLocation from "../../enums/AdsLocation";
import LanguageTag from "../../enums/LanguageTag";
import { getAds } from "../../services/ads/adsService";
import NotFound from "../not-found/NotFound";

const CampaignTypes = {
    TRANSFER: 0,
    EVENT: 1,
    CHAMPIONSHIP: 2,
    SCORE: 3,
    UNCONDITIONAL: 4,
    ROUND: 5,
    SOCIAL: 6,
  };

 
const CampaignAllDetail = () => {

    const [campaignAll,setCampaignAll] = useState();
    const [campaignType, setCampaignType] = useState();
    const [campaignTotal, setCampaignTotal] = useState();
    const [activePage, setPage] = useState(1);
    const [adsData, setAdsData] = useState([]);

    const selectedSportId = useSelector(state => state.filter.sportId);
    const selectedCountryId = useSelector(state => state.filter.countryId);
    const selectedTournamentId = useSelector(state => state.filter.tournamentId);
    const selectedTeamId = useSelector(state => state.filter.teamId);
    const languageId = useSelector((state) => state.filter.languageId);

    const fetchAds = async () => {
      try {          
        const countryId = selectedCountryId || "6498b1e91d6341510544c3b0";
        const platformType = 1;
        const location = AdsLocation['WEB_LEFT'];
    
        const res = await getAds({ countryId, languageId, platformType, location })
    
        setAdsData(res?.data?.data || {});
      } catch (error) {
        console.log("Ads Error:",error);
      }
    }
    
    const fetchCampaign = async () => {

      const campaignType = localStorage.getItem("type");
      const sportId = selectedSportId;
      const countryId = selectedCountryId;
      const tournamentId = selectedTournamentId;
      const teamId = selectedTeamId;
      const status = 1;
      const sort = "ASC";
      const page = activePage;
      const perPage = 10;
  
      const resCampaign = await getCampaign({ campaignType, sportId, countryId, tournamentId, teamId, status, sort, page, perPage })
  
      setCampaignAll(resCampaign?.data?.data?.data || {});
      setCampaignTotal(resCampaign?.data?.data?.total || {});
      setCampaignType(resCampaign?.data?.data?.data[0]?.type);
    };


    useEffect(() => {  
        fetchCampaign();
        fetchAds();
    },[selectedSportId, selectedCountryId, selectedTournamentId, selectedTeamId, activePage])

  return (
    <>
      {adsData ? 
        <>
          <AdImageCard adsData={adsData} scrollValue={250} right="0" />
          <AdImageCard adsData={adsData} scrollValue={250} left="0" />
        </> : <NotFound />
      }
      <Filterr useNavigateProp={true} />
      <CampaignDetailMain>
        <BreadcrumbsWrapper>
          <Breadcrumbs/>    
        </BreadcrumbsWrapper>

        <Container size="1160px" id="media-content">

          {campaignType === CampaignTypes.EVENT && (
            <>
              <EventWrapper>
                  {campaignAll?.map((campaignData) => (
                      <CampaignCompetitionCard
                          key={campaignData.id}
                          campaignData={campaignData}
                      />
                  ))}
              </EventWrapper>
            </>  
          )}

          {campaignType === CampaignTypes.CHAMPIONSHIP && (
            <>
              <GeneralSuccessWrapper>
                  {campaignAll?.map((campaignData) => (
                      <CampaignGeneralSuccess
                          key={campaignData.id}
                          campaignData={campaignData}
                      />
                  ))}
              </GeneralSuccessWrapper>
            </>  
          )}

          {campaignType === CampaignTypes.TRANSFER && (
            <>
              <TransferWrapper>
                  {campaignAll?.map((campaignData) => (
                      <CampaignTransfer
                          key={campaignData.id}
                          campaignData={campaignData}
                      />
                  ))}
              </TransferWrapper>
            </>  
          )}

        </Container>

        <PaginationContainer>
          <Pagination
              value={activePage} 
              onChange={setPage} 
              total={campaignTotal / 10 + 1} 
              siblings={3} 
              defaultValue={10}
              color="yellow"
              size="lg"
              withEdges 
          />
        </PaginationContainer>
        

      </CampaignDetailMain>
    </>
  );
};

export default CampaignAllDetail;

export const CampaignDetailMain = styled.main`
    max-width: 960px;
    margin: 60px auto;
    padding: 15px;
`;

export const BreadcrumbsWrapper = styled.div`
  margin: 10px 0 70px 5px;
`;

export const EventWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-gap: 10px;

  @media (max-width: 720px) {
    display: grid;
    grid-template-columns: repeat(1,1fr);
    grid-gap: 10px;
  }
`;

export const GeneralSuccessWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-gap: 10px;

  @media (max-width: 720px) {
    display: grid;
    grid-template-columns: repeat(1,1fr);
    grid-gap: 10px;
  }
`;

export const TransferWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-gap: 10px;

  @media (max-width: 720px) {
    display: grid;
    grid-template-columns: repeat(1,1fr);
    grid-gap: 10px;
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 55px;
`;