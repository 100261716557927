/**
 * The function `Accordions` creates an accordion component in React that displays a list of items with
 * icons, labels, descriptions, and content.
 * @returns The `Accordions` component is being returned. It maps over the `charactersList` array to
 * create individual `Accordion.Item` components for each item in the list. Each `Accordion.Item`
 * contains an `Accordion.Control` component with the `AccordionLabel` component as its child, and an
 * `Accordion.Panel` component that displays the content related to the item. Finally, all these
 * `Accordion
 */
import { Group, Avatar, Text, Accordion } from '@mantine/core';
import { useTranslation } from "react-i18next";
import { FaUserPlus, FaMoneyBill } from 'react-icons/fa';
import { GiReturnArrow } from "react-icons/gi";
import { RiTeamFill } from "react-icons/ri";
import { MdOutlineMoneyOff, MdOutlineAddCircle } from "react-icons/md";
import { IoMdHelp } from "react-icons/io";
 

const charactersList = [
    {
      id: 'bender',
      icon: <MdOutlineMoneyOff />,
      label: "faqOneTitle",
      description: '...',
      content: "faqOneDesc",
    },
  
    {
      id: 'carol',
      icon: <FaUserPlus />,
      label: 'faqTwoTitle',
      description: '...',
      content: "faqTwoDesc",
    },
  
    {
      id: 'homer',
      icon: <GiReturnArrow />,
      label: 'faqThreeTitle',
      description: '...',
      content: 'faqThreeDesc',
    },

    {
      id: 'platform',
      icon: <MdOutlineAddCircle />,
      label: 'faqFourTitle',
      description: '...',
      content: "faqFourDesc",
    },
  
    {
      id: 'can use',
      icon: <RiTeamFill />,
      label: 'faqFiveTitle',
      description: '...',
      content: "faqFiveDesc",
    },
  
    {
      id: 'condition',
      icon: <IoMdHelp />,
      label: 'faqSixTitle',
      description: '...',
      content: 'faqSixDesc',
    },
    {
      id: 'donate',
      icon: <FaMoneyBill />,
      label: 'faqSevenTitle',
      description: '...',
      content: 'faqSevenDesc',
    }
  ];
  
  
  
  function AccordionLabel({ label, icon, description }) {
    return (
      <Group noWrap>
        <Avatar radius="xl" size="lg" >
          {icon}
        </Avatar>
        <div>
          <Text>{label}</Text>
          <Text size="sm" color="dimmed" weight={400}>
            {description}
          </Text>
        </div>
      </Group>
    );
  }

const Accordions = () => {
  const { t } = useTranslation();
    const items = charactersList.map((item) => (
        <Accordion.Item value={item.id} key={item.label} className='accordion'>
          <Accordion.Control className='accordion-control'>
            <AccordionLabel label={t(item.label)} icon={item.icon} description={item.description} />
          </Accordion.Control>
          <Accordion.Panel>
            <Text size="sm">{t(item.content)}</Text>
          </Accordion.Panel>
        </Accordion.Item>
      ));
    
      return <Accordion chevronPosition="right" variant="contained" bg="white">{items}</Accordion>;
}

export default Accordions