import { fansupportPublic } from "../index";
/**
 * Asynchronously fetches a slider from the server based on the specified filters.
 * 
 * @async
 * @function
 * @name getSlider
 * @param {Object} options - An object containing the following properties:
 * @param {string} options.languageId - The ID of the language of the slider.
 * @param {string} options.sportId - The ID of the sport of the slider.
 * @param {string} options.countryId - The ID of the country of the slider.
 * @param {string} options.tournamentId - The ID of the tournament of the slider.
 * @param {string} options.teamId - The ID of the team of the slider.
 * @param {number} options.page - The page number of the slider to fetch.
 * @param {number} options.perPage - The number of slider items to fetch per page.
 * @return {Promise<Object>} A Promise that resolves to slider data in JSON format.
 * @throws {Error} If an error occurs during the request.
 */
export const getSlider = async ({ languageId, sportId, countryId, tournamentId, teamId, page, perPage }) => {
    return fansupportPublic.get('/slider', {params: {
        languageId,
        sportId, 
        countryId, 
        tournamentId, 
        teamId,
        page,
        perPage
    }})
}