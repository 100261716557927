/* This code is a React component called `CampaignCompetitionCard` that displays information about a
campaign competition. It uses styled-components for styling and includes functions to handle logo
clicks and join clicks. The component renders the home team logo, name, and amount, a join button,
the start date of the campaign, and the away team name, amount, and logo. The component also
includes logic to format URLs based on the campaign data and handle navigation when clicking on
logos or the join button. Additionally, it handles translations using the `useTranslation` hook from
react-i18next. */
import React from "react";
import styled from "styled-components";
import { JoinButton, CurrencyData, DateTimeFormatter, CurrencyFormat } from "../../components";
import { useTranslation } from "react-i18next";
import { NotFound } from "../../pages";
import RemoveTurkishChars from "../../logic/RemoveTurkishChars";
import { getStartLanguage } from "../../logic/getStartLanguage";

const CampaignCompetitionCard = ({ campaignData }) => {

  const symbol = '₺';

  // console.log("campaignData",campaignData);

  const { t } = useTranslation();


  const convertToTitleCase = (str) => {
    return str.charAt(0).toLowerCase() + str.slice(1);
  };

  const handleLogoClick = (team, teamId) => {

    if (team, teamId) {
      const startLanguage = getStartLanguage();
      const sportType= campaignData.detail.sportName.replace(/ /g, "-").toLowerCase();
      const formattedCampaignType = RemoveTurkishChars({ text: t("team") });
      const formattedTeamName = convertToTitleCase(team);

      window.location.href = `/${startLanguage}/${sportType}/${formattedCampaignType}/${formattedTeamName}/${teamId}`;
      // console.log(`Tıklanan takım: ${formattedTeamName}`);
      // console.log("Tıklanan takım", teamId);

    } else{
      return <NotFound />
    }


  };

const handleJoinClick = (campaign) => {

  if (campaign) {
    const startLanguage = getStartLanguage();
    const sportType = campaignData.detail.sportName.replace(/ /g, "-").toLowerCase();
    const formattedCampaignType = RemoveTurkishChars({ text: t("competition") });
    const leagueTypeSlug = campaignData.detail.tournamentName?.replace(/ /g, "-").toLowerCase();
    const campaignTitle = campaignData.title.replace(/ /g, "").toLowerCase(); 
    const formattedCampaignTitle = RemoveTurkishChars({ text: campaignTitle });
    
    const url  = `/${startLanguage}/${sportType}/${formattedCampaignType}/${leagueTypeSlug}/${formattedCampaignTitle}/${campaign}`;
    
    window.location.href = url;

  } else  {
    return <NotFound />
    // console.log("Geçersiz campaignId, sayfaya gidilemiyor.");
  }
}

  return (
    <>
      <CampaignsBoxContent className='campaign-box-content'>
        <CampaignsBoxLeft>
        <div onClick={() => handleLogoClick(campaignData.detail.homeTeam.name, campaignData.detail.homeTeam.id)}>
          <LogoFirstImage src={campaignData?.detail?.homeTeam?.extraLargeBadge ? campaignData?.detail?.homeTeam?.extraLargeBadge : "https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/6f6a87c5-fd75-45c3-0f8c-33977a168700/original"} alt={""} />
        </div>
          
          <CampaignsBoxLeftDesc>
            <CampaignsBoxLeftName>{campaignData?.detail?.homeTeam?.mediumName || ""}</CampaignsBoxLeftName>
            <CampaignsBoxAmount symbol={symbol}>
              <CurrencyFormat amount={campaignData?.detail?.homeAmount || 0}/>
            </CampaignsBoxAmount>
          </CampaignsBoxLeftDesc>
        </CampaignsBoxLeft>

        <CampaignsBoxCenter>

          <div onClick={() => handleJoinClick(campaignData.id) }>
            <JoinButton>{t("join")}</JoinButton>
          </div>
          {/* <small>{campaignData.startDate || ""}</small> */}
          <DateTimeText>
            <DateTimeFormatter apiTarih={new Date(campaignData.startDate || "")} dil="tr" />
          </DateTimeText>
          
          
        </CampaignsBoxCenter>

        <CampaignsBoxRight>
          <CampaignsBoxRightDesc>
            <CampaignsBoxRightName>{campaignData?.detail?.awayTeam?.mediumName || ""}</CampaignsBoxRightName>
            <CampaignsBoxAmount symbol={symbol}>
            <CurrencyFormat amount={campaignData?.detail?.awayAmount || 0}/>
              </CampaignsBoxAmount>
          </CampaignsBoxRightDesc>

          <div onClick={() => handleLogoClick(campaignData.detail.awayTeam.name, campaignData.detail.awayTeam.id)}>
            <LogoSecondImage src={campaignData?.detail?.awayTeam?.extraLargeBadge ? campaignData?.detail?.awayTeam?.extraLargeBadge : "https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/6f6a87c5-fd75-45c3-0f8c-33977a168700/original"} alt={""} />
          </div>     

        </CampaignsBoxRight>
      </CampaignsBoxContent>
    </>
  );
};

export default CampaignCompetitionCard;

export const CampaignsBoxContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #eee;
  font-size: 13px;
  // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

  &:hover {
    scale: 1.1;
    transition-duration: 0.6s;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
`;
export const CampaignsBoxLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const LogoFirstImage = styled.img`
  width: 36px;
  height: 36px;
  cursor: pointer;

  @media screen and (min-width: 1024px) and (max-width: 1514px) {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
`;
export const CampaignsBoxLeftDesc = styled.div``;
export const CampaignsBoxCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export const CampaignsBoxRight = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
`;
export const LogoSecondImage = styled.img`
  width: 36px;
  height: 36px;
  cursor: pointer;

  @media screen and (min-width: 1024px) and (max-width: 1514px) {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
`;
export const CampaignsBoxRightDesc = styled.div``;

export const CampaignsBoxAmount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #555;

  ::after {
    content: "${(props) => props.symbol}";
    margin-left: 8px;
    color: #4caf50;
    font-size: 16px;
  }
`;

export const CampaignsBoxLeftName = styled.div`
  max-width: 110px;
  width: 110px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media screen and (min-width: 1230px) and (max-width: 1407px) {
    max-width: 85px;
  }
  @media screen and (min-width: 1024px) and (max-width: 1230px) {
    max-width: 60px;
  }
`;
export const CampaignsBoxRightName = styled.div`
  max-width: 110px;
  width: 110px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media screen and (min-width: 1230px) and (max-width: 1407px) {
    max-width: 85px;
  }
  @media screen and (min-width: 1024px) and (max-width: 1230px) {
    max-width: 60px;
  }
`;

export const DateTimeText = styled.small`
  max-width: 90px;
  text-align: center;
  color: #888;
`;