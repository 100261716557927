import { fansupportPrivate } from "../index";
/**
 * Sends an answer to a poll.
 *
 * @async
 * @function postAnswer
 * @param {Object} param - An object containing the pollId and answerId.
 * @param {number} param.pollId - The ID of the poll.
 * @param {number} param.answerId - The ID of the answer.
 * @return {Promise} A promise that resolves with the response data.
 * @throws {Error} If there is an error.
 */
export const postAnswer = async ({pollId, answerId}) => {
     console.log("payload", pollId, answerId);
    try {
        const data =  fansupportPrivate.post('/poll/answer', {
            pollId: pollId,
            answerId: answerId
    })
        return data;

    } catch (error) {
        console.log("postAnswer",error);
    }
}