/**
 * The `ShareButtons` component in this code snippet renders a popover with social media share buttons
 * for Facebook, Twitter, Telegram, Whatsapp, Linkedin, and Email, along with a general share button
 * and styling for each share item.
 * @returns The `ShareButtons` component is being returned. It consists of a popover containing a share
 * button with various social media share options like Facebook, Twitter, Telegram, Whatsapp, Linkedin,
 * and Email. Each share option is represented by an icon button that users can click to share the
 * provided URL and title. Additionally, there is a commented-out section for copying the URL to the
 * clipboard. The styling for the
 */
import React from 'react';
import { Popover, Text, Button } from '@mantine/core';
import { 
  FacebookShareButton, 
  TwitterShareButton, 
  TelegramShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  EmailShareButton 
} from 'react-share';
import { 
  FacebookIcon, 
  XIcon,
  TelegramIcon,
  WhatsappIcon,
  LinkedinIcon,
  EmailIcon
} from "react-share";
import { IoMdShare } from "react-icons/io";
import styled from 'styled-components';


const ShareButtons = ({ url, title }) => {

  // console.log("url",url);

  // const handleCopyClick = (url) => {
  //   navigator.clipboard.writeText(url)
  //     .then(() => {
  //       console.log('URL copied to clipboard:', url);
  //       // Kopyalama başarılı oldu, isteğe bağlı olarak kullanıcıya bildirim gösterebilirsiniz
  //     })
  //     .catch((error) => {
  //       console.error('Error copying to clipboard:', error);
  //       // Kopyalama başarısız oldu, isteğe bağlı olarak kullanıcıya hata bildirimi gösterebilirsiniz
  //     });
  // };
  
  return (
    <>
      <Popover width={50} position="left" offset={{ mainAxis: 30, crossAxis: -90 }} withArrow shadow="md">
        <Popover.Target>
          <ShareButton>
            <IoMdShare size={25} />
          </ShareButton>
        </Popover.Target>
        <Popover.Dropdown>
          <Text size="xs">

          <ShareItem>
            <FacebookShareButton url={url} quote={title} >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
          </ShareItem>

          <ShareItem>
            <TwitterShareButton url={url} title={title} >
              <XIcon size={32} round />
            </TwitterShareButton>
          </ShareItem>

          <ShareItem>
            <TelegramShareButton url={url} title={title}>
              <TelegramIcon size={32} round />
            </TelegramShareButton>
          </ShareItem>

          <ShareItem>
            <WhatsappShareButton url={url} title={title}>
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </ShareItem>

          <ShareItem>
            <LinkedinShareButton url={url} title={title}>
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
          </ShareItem>

          <ShareItem>
            <EmailShareButton url={url} title={title}>
              <EmailIcon size={32} round />
            </EmailShareButton>
          </ShareItem>

          {/* <ShareItem>
            <Button onClick={handleCopyClick(url)} style={{ fontSize: '12px', padding: '5px' }}>
              Copy Link
            </Button>
          </ShareItem> */}

          </Text>
        </Popover.Dropdown>
      </Popover>

    </>
  );
};

export default ShareButtons;

export const ShareItem = styled.div`
  text-align: center;

  &:hover{
    // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transform: scale(1.2);
    transition: transform 0.3s ease-in-out; 
    background-color: transparent;
  }
`;

export const ShareButton = styled(Button)`
  background-color: transparent;
  color: black;
  margin: 0;
  padding: 0;

  &:hover{
    transform: scale(1.2);
    transition: transform 0.3s ease-in-out; 
    background-color: transparent;
    // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
`;