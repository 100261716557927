import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fansupportPublic, fansupportPrivate } from "../../../services/index";

const modulePrefix = "user";

// Define a prefix for the module to be used in actions
const initialState = {
  user: JSON.parse(localStorage?.getItem("user")) || null, // Retrieves user info from local storage if available, sets it as initial state.
  phoneVerificationData: null, // Stores data for phone verification
  resendSmsData: null, // Stores data for resending SMS
  isExistData: false, // Stores data indicating whether the user exists
  loading: false, // Loading status for API requests
  success: false, // Flag indicating whether an operation was successful
  error: false, // Flag indicating whether an error occurred
  errorMessage: "", // Stores error messages
};

// Selector function to check if the user is authenticated
export const selectIsAuthenticated = (state) => state.user.user?.data?.status === 0;

// AsyncThunk for user login
export const login = createAsyncThunk(
  `${modulePrefix}/login`,
  async ({ username, password }, { getState, rejectWithValue }) => {
    try {
      // Make API request for login
      const res = await fansupportPublic.post("identity/sign-in", {
        username,
        password,
      });

      // If login is unsuccessful, return the error message
      if (!res.data?.isSuccess) {
        return rejectWithValue(res.data.message);
      }
      // If successful, return the response data
      return res.data;
    } catch (error) {
      // Handle errors during the API request
      return rejectWithValue(error.toString());
    }
  }
);

// AsyncThunk for user logout
export const logout = createAsyncThunk(
  `${modulePrefix}/logout`,
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState(); // Retrieve the current state
      const refreshToken = state.user.user?.data?.accessTokenDto?.refreshToken; // Get the refresh token

      const data = {
        value: refreshToken, // Send the refresh token to the API
      };

      const res = await fansupportPrivate.post(`identity/sign-out`, data, {
        headers: {
          'Content-Type': 'application/json', // Specify JSON content type
        },
      });
      
      // If logout fails, return the error message
      if (!res.data?.isSuccess) {
        return rejectWithValue(res.data.message);
      }

      // localStorage.removeItem("user"); // Remove user data from local storage
      return null; // Logout successful
    } catch (error) {
      // Handle errors during the API request
      console.error("Logout error:", error);
      return rejectWithValue(error.toString());
    }
  }
);

// AsyncThunk for deleting a user
export const deleteUser = createAsyncThunk(
  `${modulePrefix}/deleteUser`,
  async (_, { getState }) => {
    const state = getState(); // Retrieve the current state

    // Make API request to delete the user
    const res = await fansupportPrivate.delete(`identity/delete`, {
      headers: { authorization: `Bearer ${state.user.user?.data?.accessTokenDto?.accessToken}` }, // Include authorization header
    });
    // Return the data from the API response
    return res.data;
  }
);

// AsyncThunk for user registration
export const register = createAsyncThunk(
  `${modulePrefix}/register`,
  async ({ 
      username,
      firstname,
      lastname,
      email,
      birthday,
      countryId,
      phoneCode,    
      phoneNumber,
      genderId,
      password,
      passwordConfirmation,
      isTIOM,
      isKvKK,}, { getState, rejectWithValue }) => {
    try {
      // Make API request for registration
      const res = await fansupportPublic.post("identity/register", {
        username,
        firstname,
        lastname,
        email,
        birthday,
        countryId,
        phoneCode,    
        phoneNumber,
        genderId,
        password,
        passwordConfirmation,
        isTIOM,
        isKvKK,
      });
      // If registration fails, return the error message
      if (!res.data?.isSuccess) {
        return rejectWithValue(res.data.message);
      }
      return res.data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

// AsyncThunk for phone verification
export const verifyPhone = createAsyncThunk(
  `${modulePrefix}/verifyPhone`,
  async ( otp , { getState, rejectWithValue }) => {
    try {
      // Make API request for phone verification
      const res = await fansupportPublic.post("identity/verify-phone", {
        value: otp // Send OTP value
      } );

      // If verification fails, return the error message
      if (!res.data?.isSuccess) {
        return rejectWithValue(res.data.message);
      }
      // console.log(otp);
      // console.log(res.data);
      return res.data;      
    }
    catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);
// AsyncThunk for resending SMS
export const resendSms = createAsyncThunk(
  `${modulePrefix}/resendSms`,
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState(); // Retrieve the current state
      // console.log("state:", state);
      const { phoneCode, phoneNumber } = state.user.user?.data; // Get the user's phone information
      // console.log("phoneCode:", phoneCode);
      // console.log("phoneNumber:", phoneNumber);

      // Make API request to resend SMS
      const res = await fansupportPrivate.post(`identity/resend-sms`, {
        phoneCode,
        phoneNumber,
      });

      // If SMS resending fails, return the error message
      if (!res.data?.isSuccess) {
        return rejectWithValue(res.data.message);
      }
      // console.log(res.data); 
      return res.data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

// AsyncThunk to check if the user exists
export const isExist = createAsyncThunk(
  `$(modulePrefix)/isExist`,
  async ({
    username,
    email,
    phoneCode,
    phoneNumber,}, { rejectWithValue }) => {
      try {
        // Make API request to check if the user exists
        const res = await fansupportPublic.post("identity/is-exist", {
          username,
          email,
          phoneCode,
          phoneNumber,
        });
  
        // If the user exists, return the error message
        if (!res.data?.isSuccess) {
          return rejectWithValue(res.data.message);
        }
        // console.log(res.data);
        return res.data;
      } catch (error) {
        return rejectWithValue(error.toString());
      }
    }
); 

// Define the user slice
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Update state while login is loading
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorMessage = "";
      })
      // Update state when login is successful
      .addCase(login.fulfilled,(state, action) => {
          state.loading = false;
          state.success = true;
          localStorage.setItem("user", JSON.stringify(action.payload)); // Store user data in local storage
          state.user = action.payload;
      })
      // Update state when login fails
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.errorMessage = action.payload;
      })
      // Update state while registration is loading
      .addCase(register.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorMessage = "";
      })
      // Update state when registration is successful
      .addCase(register.fulfilled,(state, action) => {
          state.loading = false;
          state.success = true;
          // state.user = action.payload;
      })
      // Update state when registration fails
      .addCase(register.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.errorMessage = action.payload;
      })

      .addCase(verifyPhone.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorMessage = "";
      })
      .addCase(verifyPhone.fulfilled,(state, action) => {
          state.loading = false;
          state.success = true;
          state.phoneVerificationData = action.payload;
      })
      .addCase(verifyPhone.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.errorMessage = action.payload; 
      })

      .addCase(isExist.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorMessage = "";
      })
      .addCase(isExist.fulfilled,(state, action) => {
          state.loading = false;
          state.success = true;
          state.isExistData = action.payload;
      })
      .addCase(isExist.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.errorMessage = action.payload; 
      })
      
      
      .addCase(logout.fulfilled, (state) => {
        localStorage.removeItem("user");
        state.user = null;
        state.success = false;
        state.error = false;
      })

      .addCase(deleteUser.pending, (state) => {
        state.success = false;
        state.error = false;
      })
      .addCase(deleteUser.fulfilled, (state) => {
        state.success = true;
        localStorage.removeItem("user");
        state.user = null;
      })
      .addCase(deleteUser.rejected, (state) => {
        state.error = true;
      })
  },
});

export default userSlice.reducer;