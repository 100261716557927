import { fansupportPrivate } from "../index";
/**
 * Asynchronously fetches poll user answers data from the server.
 *
 * @async
 * @function
 * @name getPollUserAnswers
 * @param {Object} options - An object containing the following properties:
 * @param {number} options.page - The page number of poll user answers to fetch.
 * @param {number} options.perPage - The number of poll user answers to fetch per page.
 * @return {Promise<Object>} A Promise that resolves to poll user answers data in JSON format.
 * @throws {Error} If an error occurs during the request.
 */
export const getPollUserAnswers = async ({page, perPage}) => {
    try {
        return fansupportPrivate.get('/poll/user-answers',{ params: { 
            page,
            perPage 
        }})
    } catch (error) {
        console.log("getPollUSerAnswers",error)
    }
} 