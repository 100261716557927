import { fansupportPublic } from "./index";
/**
 * Asynchronously fetches countries that are associated with a sport based on the provided sport ID.
 *
 * @async
 * @function
 * @name getCountry
 * @param {Object} options - An object containing the following properties:
 * @param {string} options.sportId - The ID of the sport to fetch countries for.
 * @return {Promise<Object>} A Promise that resolves to countries in JSON format.
 * @throws {Error} If an error occurs during the request.
 */
export const getCountry = async ({ sportId }) => {
    return fansupportPublic.get('/sport/filter', {params: {
        sportId,
    }})
}