import { fansupportPublic } from "./index";
/**
 * Asynchronously fetches the standings for a specific sport, country, and tournament.
 *
 * @async
 * @function
 * @name getStandings
 * @param {Object} options - An object containing the following properties:
 * @param {string} options.sportId - The ID of the sport to filter standings by.
 * @param {string} options.countryId - The ID of the country to filter standings by.
 * @param {string} options.tournamentId - The ID of the tournament to filter standings by.
 * @return {Promise<Object>} A Promise that resolves to standings data in JSON format.
 * @throws {Error} If any of the required parameters (sportId, countryId, tournamentId) are missing.
 */
export const getStandings = async ({ sportId, countryId, tournamentId }) => {
    if (!sportId || !countryId || !tournamentId) {
        return Promise.reject(new Error("Sport ID, country ID, or tournament ID is missing."));
    }
    return fansupportPublic.get('/sport/standings', {
        params: {
            sportId,
            countryId,
            tournamentId,
        },
        headers: {
            "Accept-Language": "tr-TR"
        }
    });
};