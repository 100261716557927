/* This code is a React component called `FavoriteTeamsDropdown` that displays a dropdown menu of
favorite teams. Here is a summary of what the code does: */
import React, { useState, useEffect } from 'react';
import { getFavoriteTeams } from '../../services/favoriteTeamsService';
import styled from 'styled-components';
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { FiPlusCircle } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import { useDisclosure } from '@mantine/hooks';
import { Modal } from '@mantine/core';
import { AiFillCaretDown } from "react-icons/ai";
import { IoMdArrowDropdown } from "react-icons/io";
import { NotFound } from '../../pages';
import { setCountryId, setSportId, setTeamId, setTournamentId } from '../../redux/features/filter/filterSlice';
import { getStartLanguage } from '../../logic/getStartLanguage';
import RemoveTurkishChars from '../../logic/RemoveTurkishChars';
import { FaTrash } from "react-icons/fa";


const FavoriteTeamsDropdown = () => {

  const SPORT_ID_KEY = "selectedSportId";
  const COUNTRY_ID_KEY = "selectedCountryId";
  const TOURNAMENT_ID_KEY = "selectedTournamentId";
  const TEAM_ID_KEY = "selectedTeamId";

  const [favoriteTeams, setFavoriteTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(JSON.parse(localStorage?.getItem("selectedTeam")));
  const [footballOpen, setFootballOpen] = useState(false);
  const [basketballOpen, setBasketballOpen] = useState(false);
  const [volleyballOpen, setVolleyballOpen] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);

  const user  = useSelector((state) => state.user);

  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const languageCode = pathSegments[1];

  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();

  if (i18n.language !== languageCode) {
    i18n.changeLanguage(languageCode);
  }

  const navigate = useNavigate();
  const startLanguage = getStartLanguage();


  const fetchFavoriteTeams = async () => {
    try {

      const sportId = '';
      const page = 1;
      const perPage = 25;

      const res = await getFavoriteTeams({ sportId, page, perPage });
      const teams = res.data?.data?.data || [];
      setFavoriteTeams(teams);
    } catch (error) {
      console.error('Favori takımları getirme hatası:', error);
    }
  };
  // console.log("favoriteTeams",favoriteTeams);

  const handleFavoritePage = () => {
    if(user.user){
      navigate(`/${startLanguage}/${RemoveTurkishChars({ text: t("favouriteTeams") })}`)
    }else{
      navigate(`/${startLanguage}/${RemoveTurkishChars({ text: t("login") })}`)
    }
  }


  const handleTeamClick = (team) => {
    localStorage.removeItem(SPORT_ID_KEY);
    localStorage.removeItem(COUNTRY_ID_KEY);
    localStorage.removeItem(TOURNAMENT_ID_KEY);
    localStorage.removeItem(TEAM_ID_KEY);
    localStorage.removeItem("selectedTeam");

    setSelectedTeam(team);

    // dispatch(setSportId(team.sportId));
    // dispatch(setCountryId(team.countryId));
    // dispatch(setTournamentId(team.tournamentId));
    dispatch(setTeamId(team.teamId));

    // localStorage.setItem(SPORT_ID_KEY, team.sportId);
    // localStorage.setItem(COUNTRY_ID_KEY, team.countryId);
    // localStorage.setItem(TOURNAMENT_ID_KEY, team.tournamentId);
    localStorage.setItem(TEAM_ID_KEY, team.teamId);
    localStorage.setItem("selectedTeam",JSON.stringify(team));
    close();
    window.location.href= `/${startLanguage}/${team.sportName.toLowerCase()}`;

    
    // console.log("selectTeam",team);
    // console.log("selectTeamm",team.teamId);
  }

  const handleResetFilter = () => {
    localStorage.removeItem(TEAM_ID_KEY);
    localStorage.removeItem("selectedTeam");
    localStorage.removeItem("selectedTeamName");
    dispatch(setTeamId(""));
    close();
    window.location.href= `/${startLanguage}`;
  };

  const groupedTeams = {
    Football: [],
    Basketball: [],
    Volleyball: [],
  };

  favoriteTeams.forEach((team) => {
    switch (team.sportId) {
      case "6498b1e91d6341510544c3a7":
        groupedTeams.Football.push(team);
        break;
      case "6498b1e91d6341510544c3a8": 
        break;
      case "6498b1e91d6341510544c3a9": 
        break;
      default:
        break;
    }
  });

  useEffect(() => {
    fetchFavoriteTeams();
  }, [dispatch]);

  return (
    <DropdownContainer>

      <LanguageButtonWrapper 
          onClick={open}
        >
          <IconLanguageContainer>
            {/* <TbWorld size={22}/> */}
            { selectedTeam ? (
              <>
                <TeamLogo src={selectedTeam.mediumBadge ? selectedTeam.mediumBadge : "https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/6f6a87c5-fd75-45c3-0f8c-33977a168700/original"} alt="Team Logo" />
                <TeamText>{selectedTeam.teamName}</TeamText>             
              </>
            ) : (
              <>
                {t("myClubs")}
                <AiFillCaretDown size={22} color="white" /> 
              </>
            )}
          </IconLanguageContainer>  
          {/* {showIcon && <IoMdArrowDropdown size={20} />}     */}
        </LanguageButtonWrapper>


        <Modal opened={opened} onClose={() => { close() }} title={t("teams")} centered>

        <RouteAddFavourite>

          <ClearButton  onClick={handleResetFilter}>
            <TrashIcon />
            {t("cleanFilter")}
          </ClearButton>

          <RouteAddFavouriteButton onClick={handleFavoritePage}>
            <FiPlusCircle size={16}/>
            {t("addx")}
          </RouteAddFavouriteButton>

        </RouteAddFavourite>

                  
          <ModalContent>



            <FavoriteTeamsButtonChange 
                onClick={() => setFootballOpen(!footballOpen)}>
              <IconLanguageContainer>
                <TeamLogo src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/d37bf258-a3ef-453d-bb1f-90bbaa846300/original" alt="" />
                <span>{t("football")}</span>
              </IconLanguageContainer>
              <IoMdArrowDropdown size={20} />
            </FavoriteTeamsButtonChange>

            {footballOpen && (
              <>
                <SportSelect>
                  {groupedTeams.Football.length > 0 ? (
                    <div >
                      {groupedTeams.Football.map((team) => (
                        <SportOption key={team.id} onClick={() => handleTeamClick(team)}>
                          <TeamLogoGroup src={team.mediumBadge ? team.mediumBadge : "https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/6f6a87c5-fd75-45c3-0f8c-33977a168700/original"} alt="" />
                          {team.teamName}
                        </SportOption>
                      ))}
                    </div>
                  ) : (
                    <>
                      <NotFound text={t("anyClubs")} />
                    </>
                  )}
                </SportSelect>
              </>
            )}

            {/* <FavoriteTeamsButtonChange 
              onClick={() => setBasketballOpen(!basketballOpen)}
            > 
            <IconLanguageContainer>
              <TeamLogo src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/5b92e6dd-5fbf-4578-1ab3-9c61648d3400/original" alt="BasketballIcon" />
                <span>{t("basketball")}</span>           
            </IconLanguageContainer>
            <IoMdArrowDropdown size={20} />

            </FavoriteTeamsButtonChange>

            {basketballOpen && (
              <>
                <SportSelect>
                  {groupedTeams.Basketball.length > 0 ? (
                    <div >
                      {groupedTeams.Basketball.map((team) => (
                        <SportOption key={team.id} onClick={() => handleTeamClick(team)}>
                          <TeamLogoGroup src={team.mediumBadge ? team.mediumBadge : "https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/6f6a87c5-fd75-45c3-0f8c-33977a168700/original"} alt="" />
                          {team.teamName}
                        </SportOption>
                      ))}
                    </div>
                  ) : (
                    <>
                      <NotFound text={t("anyClubs")} />
                    </>
                  )}
                </SportSelect>
              </>
            )}

            <FavoriteTeamsButtonChange 
              onClick={() => setVolleyballOpen(!volleyballOpen)}
            > 
            <IconLanguageContainer>
              <TeamLogo src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/98523bb1-26a9-494c-3be7-3c96d38fb800/original" alt="VolleyballIcon" />
                <span>{t("volleyball")}</span>                
            </IconLanguageContainer>
            <IoMdArrowDropdown size={20} />

            </FavoriteTeamsButtonChange>

            {volleyballOpen && (
              <>
                <SportSelect>
                  {groupedTeams.Volleyball.length > 0 ? (
                    <div >
                      {groupedTeams.Volleyball.map((team) => (
                        <SportOption key={team.id} onClick={() => handleTeamClick(team)}>
                          <TeamLogoGroup src={team.mediumBadge ? team.mediumBadge : "https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/6f6a87c5-fd75-45c3-0f8c-33977a168700/original"} alt="" />
                          {team.teamName}
                        </SportOption>
                      ))}
                    </div>
                  ) : (
                    <>
                      <NotFound text={t("anyClubs")} />
                    </>
                  )}
                </SportSelect>
              </>
            )} */}

          </ModalContent>

          <ModalButtonContainer>
            <ModalCloseButton onClick={ () => {close();}}>{t("close")}</ModalCloseButton>
          </ModalButtonContainer>
          
        </Modal>

    </DropdownContainer>
  );
};

export default FavoriteTeamsDropdown;


export const DropdownContainer = styled.div`
  display: inline-block;
  position: relative;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px 0 30px 5px;
`;

export const IconLanguageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;


  @media (min-width: 900px) and (max-width: 1050px) {
    font-size: 11px;
  }
`;

export const SportSelect = styled.div`

`;

export const SportOption  = styled.button`
  display: flex;
  justify-content: start;
  align-items: center;
  background-color: #fff;
  color: black;
  padding: 15px;
  width: 100%;
  text-align: left;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #f1f1f1;
  }
`;

export const LanguageButtonWrapper = styled.button`
  display : flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 100%;
  min-width: 150px;
  background-color: transparent;
  color: rgb(192, 192, 192);
  padding: 10px;
  font-size: 16px;
  // border: 1px solid #033036;
  border: ${(props) => props.border || "none"};
  border-radius: 5px;
  cursor: pointer;

  @media (min-width: 900px) and (max-width: 1185px) {
    min-width: auto;
  }
`;
export const FavoriteTeamsButtonChange = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #f8f9fa;
  color: #212529;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e9ecef;
  }
`;
export const CurrencyButtonChange = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #f8f9fa;
  color: #212529;
  margin-top: 15px;
  padding: 10px 15px;
  font-size: 16px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e9ecef;
  }
`;

export const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 15px;
`;

export const ModalCloseButton = styled.button`
  background-color: transparent;
  color: #333;
  padding: 10px 15px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f1f1f1;
  }
`;

export const Select = styled.select`
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  outline: none;
  color: #eee;
  background: var(--bg-navbar);
  cursor: pointer;
`;

export const SelectedOption = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  // background-color: var(--bg-navbar);
  background-color: #f9f9f9; /* Arka plan rengi */
  font-size: 18px;
  border: none;
  border-top: none;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`;

export const Option = styled.option`
  padding: 10px;
  color: grey;
`;

export const TeamLogo = styled.img`
  width: 20px;
  height: 20px;
`;
export const TeamLogoGroup = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

export const TeamText = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: rgb(192, 192, 192);
`;

export const RouteAddFavourite = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 30px 0;
`;

export const RouteAddFavouriteButton = styled.div`
  display: flex;
  justify-conten: center;
  align-items: center;
  gap: 5px;
  color: #4CAF50;
  text-decoration: none;
  padding: 6px;
  border: 2px solid #4CAF50;
  border-radius: 15px;
  cursor: pointer;

  &:hover {
    color: white;
    background-color: #4CAF50;
  }
`;
export const ClearButton = styled.button`
  background-color: #e74c3c;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #c0392b;
  }
`;
const TrashIcon = styled(FaTrash)`
  margin-right: 8px;
`;