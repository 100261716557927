import { fansupportPublic } from "../index";
/**
 * Asynchronously retrieves RSS data from the API based on the specified filter criteria.
 *
 * @async
 * @function getRss
 * @param {Object} options - An object containing the following properties:
 * @param {number} options.LanguageId - The ID of the language the RSS data is in.
 * @param {number} options.SportId - The ID of the sport the RSS data is about.
 * @param {number} options.CountryId - The ID of the country the RSS data is about.
 * @param {number} options.TournamentId - The ID of the tournament the RSS data is about.
 * @param {number} options.page - The page number of the RSS data to retrieve.
 * @param {number} options.perPage - The number of RSS data to retrieve per page.
 * @returns {Promise<Object>} A Promise that resolves to the fetched data in JSON format.
 * @throws {Error} If there is an error during the API request.
 */
export const getRss = async ({ LanguageId, SportId, CountryId, TournamentId, page, perPage }) => {
    return fansupportPublic.get('/rss', {params: {
        LanguageId,
        SportId,
        CountryId,
        TournamentId,
        page,
        perPage,
    }})
}