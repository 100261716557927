import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./redux/app/store";
import { Loader } from "../src/components";
import App from "./App";
import "./index.css";
import './i18next';

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    <Suspense fallback={(<Loader/>)} >
      <Provider store={store}>
          <App />
      </Provider>
    </Suspense>  
);
 