import { fansupportPrivate } from "../index";
/**
 * Sends a POST request to update the user's picture.
 *
 * @async
 * @function
 * @param {string} picture - The new picture in base64 format.
 * @throws {Error} If the request fails.
 * @returns {Promise} A Promise that resolves to the server's response data.
 */
export const postUserPicture = async (picture) => {
    try {
        const data = await fansupportPrivate.post('identity/set-user-picture-base64',{
            picture: picture,
    })
        return data;        
    } catch (error) {
        console.log("postUserPicture Error:", error)
    }
};