/**
 * `Teams` Component
 * 
 * This component displays a list of sports teams and advertisements in a web application. It fetches advertisements and team data from external services and renders them on the page. It also manages loading states and handles cases where no ads data is available.
 * 
 * **Key Functionalities**:
 * - Fetches advertisement data and team data asynchronously using API calls.
 * - Displays ads on the left and right sides of the page.
 * - Shows a `NotFound` component if no ads data is available.
 * - Renders a list of teams with an option to add them to favorites.
 * - Utilizes `react-redux` to get selected country and language information from the state.
 * - Manages state for ads data, team data, and loading status.
 * 
 * **Components and Elements**:
 * - `AdImageCard`: Displays advertisement images on the left and right sides of the page.
 * - `Breadcrumbs`: Displays navigation breadcrumbs at the top of the page.
 * - `AddFavoritesCard`: Displays a list of teams with the option to add them to favorites.
 * - `NotFound`: Displays a not found message if no ads data is available.
 * 
 * **State Management**:
 * - `adsData`: Stores the fetched ads data.
 * - `teamsData`: Stores the fetched team data.
 * - `loading`: Indicates whether the team data is currently being fetched.
 * - `localCountryId`: Retrieves the country ID from local storage.
 * 
 * **API Calls**:
 * - `fetchAds()`: Fetches advertisement data based on the selected or stored country ID, language ID, platform type, and location. Handles errors by logging them to the console.
 * - `fetchTeams()`: Fetches team data for a specific sport, country, and tournament. Handles loading state and errors by logging them to the console.
 * 
 * **Effects**:
 * - `useEffect()`: Calls `fetchAds` and `fetchTeams` when the component mounts. Scrolls to the top of the page on mount.
 * 
 * **Styling**:
 * - `TeamsContainer`: Styles the main container for the teams section, including maximum width, margin, padding, and responsive design adjustments.
 * - `BreadcrumbsWrapper`: Styles the container for the breadcrumbs with margin adjustments.
 * 
 * **Usage**:
 * - This component is used in a sports application to display a list of teams and advertisements. It ensures that users see relevant ads and can view and manage their favorite teams.
 */
import React,{ useState, useEffect } from 'react';
import styled from 'styled-components';
import { AddFavoritesCard, Filterr, Breadcrumbs, AdImageCard } from '../../components';
import LanguageTag from '../../enums/LanguageTag';
import { getAds } from '../../services/ads/adsService';
import NotFound from '../not-found/NotFound';
import { useSelector } from 'react-redux';
import AdsLocation from '../../enums/AdsLocation';
import { getTeams } from '../../services/teamsService';

const Teams = () => {

  const [adsData, setAdsData] = useState([]);
  const [teamsData, setTeamsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [localCountryId, setLocalCountryId] = useState(localStorage.getItem("startCountryId"))


  const selectedCountryId = useSelector(state => state.filter.countryId);
  const languageId = useSelector((state) => state.filter.languageId);




  const fetchAds = async () => {
    try {

      const countryId = selectedCountryId === "" ? localCountryId : selectedCountryId;
      const platformType = 1;
      const location = AdsLocation['WEB_LEFT'];
  
      const res = await getAds({ countryId, languageId, platformType, location })
  
      setAdsData(res?.data?.data || {});
    } catch (error) {
      console.log("Ads Error:",error);
    }
  }

  const fetchTeams = async () => {
    try {
      setLoading(true);
      const res = await getTeams({ sportId: "6498b1e91d6341510544c3a7", countryId: "6498b1e91d6341510544c3b0", tournamentId: "64f0411f7bf55f571f120cb4" })
  
      setTeamsData(res?.data?.data?.teams || []);
    } catch (error) {
      console.log("Teams Error:", error);
    } finally {
      setLoading(false);
    }
  }


  useEffect(() => {
    window.scrollTo(0, 0);
    fetchAds();
    fetchTeams();
  }, []);

  return (
    <>
      {adsData ? 
        <>
          <AdImageCard adsData={adsData} scrollValue={250} right="0" />
          <AdImageCard adsData={adsData} scrollValue={250} left="0" />
        </> : <NotFound />
      }  
      {/* <Filterr useNavigateProp={true} showTeamSelect={false} /> */}
      <TeamsContainer>
        <BreadcrumbsWrapper>
          <Breadcrumbs color="white"/>    
        </BreadcrumbsWrapper>

        <AddFavoritesCard teamsData={teamsData} loading={loading} />
      </TeamsContainer>
      <div style={{paddingTop: '618px'}} id='media-content'></div>
    </>
  )
}

export default Teams

export const BreadcrumbsWrapper = styled.div`
  margin: 10px 0 70px 5px;
`;

export const TeamsContainer = styled.div`
  max-width: 960px;
  margin: 60px auto 0 auto;
  padding: 15px;

  @media screen and (min-width: 1100px) and (max-width: 1412px) {
    width: 60%;
  }
  @media screen and (min-width: 900px) and (max-width: 1100px) {
    width: 50%;
  }
`;
