/**
 * This code defines a `ScrollToTop` component using React and React Router.
 * The `ScrollToTop` component automatically scrolls the window to the top 
 * whenever the URL path changes. This is useful in single-page applications 
 * to ensure the user starts at the top of the page when navigating to a new route.
 * The component uses the `useLocation` hook to track the current path and the 
 * `useEffect` hook to trigger the scroll behavior whenever the path changes.
 */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default ScrollToTop; 
