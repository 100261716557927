/**
 * This code defines a `Standing` component using React, styled-components, Mantine, and i18next for localization.
 * The `Standing` component is responsible for displaying sports standings, including teams and their statistics,
 * such as games played, won, lost, goals scored, and points. The component supports loading states, responsive 
 * design based on screen width, and dynamic meta tags using the `SEO` component. It also handles switching between 
 * different standings views (overall, home, away) based on user interaction.
 */
import { Container, Skeleton } from "@mantine/core";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { NotFound } from "../../pages";
import SEO from "../seo/SEO";
import { useViewportSize } from "@mantine/hooks";

const Standing = ({standingsData, loading}) => {

  const {t} = useTranslation();
  const { width: screenWidth } = useViewportSize();

  const [isActive, setIsActive] = useState("overall");


  return (
    <>
      <div>
        <SEO
          title={`${localStorage.getItem("selectedSportName") || ""} ${localStorage.getItem("selectedCountryName") || ""} ${localStorage.getItem("selectedTournamentName") || ""} ${localStorage.getItem("selectedTeamName") || ""} ${t("standingsMetaTitle")}`}
          description={`${localStorage.getItem("selectedSportName") || ""} ${localStorage.getItem("selectedCountryName") || ""} ${localStorage.getItem("selectedTournamentName") || ""} ${localStorage.getItem("selectedTeamName") || ""} ${t("standingsMetaDescription")}`}
          keywords={`${localStorage.getItem("selectedSportName") || ""} ${localStorage.getItem("selectedCountryName") || ""} ${localStorage.getItem("selectedTournamentName") || ""} ${localStorage.getItem("selectedTeamName") || ""} ${t("standingsMetaKeywords")}`}
          url="https://www.taraftardestek.com/"
        />
      </div>
      <Container>
        <StandingsContainer className="standings-container">
        {loading ? (
            <SkeletonSection />
          ) : (
          <StandingsTable>
              {standingsData ? (
                standingsData?.map((list) => (
                  <StandingsContent key={list?.id}>
                    <StandingsContentHeader>{list?.name}</StandingsContentHeader>

                    <StandingsButtonContainer>
                      <StandingsButton
                        className="standings-button"
                        active={isActive === "overall"}
                        onClick={() => setIsActive("overall")}
                      >
                        {t("all")}
                      </StandingsButton>
                      <StandingsButton
                      className="standings-button"
                        active={isActive === "home"}
                        onClick={() => setIsActive("home")}
                      >
                        {t("insideField")}
                      </StandingsButton>
                      <StandingsButton
                      className="standings-button"
                        active={isActive === "away"}
                        onClick={() => setIsActive("away")}
                      >
                        {t("outsideField")}
                      </StandingsButton>
                    </StandingsButtonContainer>

                    <StandingsHeader>
                      <StandingsSpanId></StandingsSpanId>
                      <TeamSort></TeamSort>
                      <StandingsSpan>{t("shortPlayed")}</StandingsSpan>
                      <StandingsSpan>{t("shortWinned")}</StandingsSpan>
                      <StandingsSpan>{t("shortTied")}</StandingsSpan>
                      <StandingsSpan>{t("shortLost")}</StandingsSpan>
                      <StandingsSpan>{t("shortGoalsFor")}</StandingsSpan>
                      <StandingsSpan>{t("shortGoalsAgainst")}</StandingsSpan>
                      <StandingsSpan>{t("shortGoalsDiff")}</StandingsSpan>
                      <StandingsSpan>{t("shortPoint")}</StandingsSpan>
                    </StandingsHeader>

                    {list?.standings?.[isActive].map((team) => (
                      <PuanDurumu key={team?.team?.id}>

                      <PuanDurumuSpanId>{team?.position}</PuanDurumuSpanId>
                      <TeamLogo src={ screenWidth <= 720 ? team?.team?.mediumBadge : team?.team?.largeBadge} alt={team?.team?.name} />
                      <TeamNameSpan>{screenWidth <= 720 ? t(team?.team?.shortName) : t(team?.team?.name)}</TeamNameSpan>
                      <PuanDurumuSpan>{team?.played}</PuanDurumuSpan>
                      <PuanDurumuSpan>{team?.won}</PuanDurumuSpan>
                      <PuanDurumuSpan>{team?.draw}</PuanDurumuSpan>
                      <PuanDurumuSpan>{team?.lost}</PuanDurumuSpan>
                      <PuanDurumuSpan>{team?.scored}</PuanDurumuSpan>
                      <PuanDurumuSpan>{team?.against}</PuanDurumuSpan>
                      <PuanDurumuSpan>{team?.average}</PuanDurumuSpan>
                      <PuanDurumuSpan>{team?.points}</PuanDurumuSpan>
    
                    </PuanDurumu>
                    ))}
                  </StandingsContent>
                ))
              ) : (
                <NotFound text={t("noStandings")} />
              )}
            </StandingsTable>
          )}
        </StandingsContainer>
      </Container>
    </>
  );
};

const SkeletonSection = () => (
  <div>
    <Skeleton height={70} width={150} mb="md" />
    <Skeleton height={50} width="100%" mb="xs" />
    <Skeleton height={50} width="100%" mb="xs" />
    <Skeleton height={50} width="100%" mb="xs" />
    <Skeleton height={50} width="100%" mb="xs" />
    <Skeleton height={50} width="100%" mb="xs" />
    <Skeleton height={50} width="100%" mb="xs" />
    <Skeleton height={50} width="100%" mb="xs" />
    <Skeleton height={50} width="100%" mb="xs" />
    <Skeleton height={50} width="100%" mb="xs" />
    <Skeleton height={50} width="100%" mb="xs" />
  </div>
);

export default Standing;

export const StandingsContainer = styled.div`
  margin: 30px 0;
`;

export const StandingsButtonContainer = styled.div`
  display: flex;
  gap: 15px;
`;

export const StandingsTable = styled.div`
  display: block;
`;

export const PuanDurumu = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  border-top: 1px solid rgba(10,9,18,.12);

  // &:nth-child(even) {
  //   background-color: #f9f9f9;
  // }

  // &:hover {
  //   background-color: #ddd;
  //   // background-color: blue;
  // }
`;

export const TeamNameSpan = styled.div`
  display: table-cell;
  width: 225px;
  height: 20px;
  padding-left: 20px;
`;

export const PuanDurumuSpanId = styled.div`
  display: table-cell;
  background-color: #f3f6f8;
  border-radius: 50%;
  width: 32px;
  height: 24px;
  line-height: 24px;
  color: black;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  margin-right: 15px;
`;
export const PuanDurumuSpan = styled.div`
  display: table-cell;
  width: 70px;
  height: 20px;
  text-align: end;
  white-space: nowrap;
  font-size: 17px;
  padding: 5px;
`;

export const StandingsButton = styled.div`
  padding: 10px 5px;
  // background-color: ${({ active }) => (active ? "blue" : "transparent")};
  color: ${({ active }) => (active ? "black" : "black")};
  border-bottom: ${({ active }) => (active ? "3px solid orange" : "none")};
  font-weight: ${({ active }) => (active ? "bold" : "none")};
  margin-right: 10px;
  cursor: pointer;

  &:hover {
    // background-color: orange;
    color: black;
    transform: scale(1.05);    
    font-weight: bold;
  }
`;

export const StandingsHeader = styled.div`
  display: flex; 
  padding: 10px;
  color: grey;
`;
export const StandingsSpanId = styled.div`
  display: table-cell;
  width: 30px;
  height: 20px;
  text-align: center;
`;
export const StandingsSpan = styled.div`
  display: table-cell;
  width: 70px;
  height: 20px;
  text-align: end;
  font-size: 17px;
  padding: 5px;
`;
export const TeamSort = styled.div`
  display: table-cell;
  width: 300px;
  height: 20px;
  text-align: center;
`;
export const StandingsContent = styled.div`

`;
export const StandingsContentHeader = styled.h3`
  margin: 50px 0;
`;

export const TeamLogo = styled.img`
  width: 36px;
  height: 36px;

  @media (max-width: 720px) {
    display: none;
  }
`;
