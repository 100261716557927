import React, {useEffect, useState} from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { getCampaign } from "../../services/campaign/campaignService";
import announcement from "../../assets/announcement.svg";
// import teamIcon from "../../assets/place-holder/team.webp";

import {
  Breadcrumbs,
  CampaignCompetitionDetailCard,
  CampaignNumbersDetailCard,
  CampaignCard,
  CampaignDonorsList,
  CampaignTransferDetailCard,
  SEO,
} from "../../components";

import {ErrorComponent} from "../../pages"
import { CampaignTypes } from "../../enums/CampaignType";

/* 
  CampaignDetail component displays detailed information about a campaign.
  It uses React hooks to manage state and effects.
  - `useState` is used to manage state variables related to the campaign.
  - `useEffect` is used to fetch campaign details when the component mounts.
  - `useTranslation` is used for internationalization to support multiple languages.
*/

 
const CampaignDetail = () => {

  /* 
    State variables to manage campaign data.
    - `campaignType`: The type of campaign (TRANSFER, EVENT, etc.).
    - `campaignId`: The unique identifier of the campaign.
    - `campaignStartDate`: The start date of the campaign.
    - `eventData`: General data about the event.
    - `like`: Array of likes associated with the campaign.
    - `eventDetail`: Detailed information about the event.
    - `campaignTitle`: The title of the campaign.
    - `transferChampionShipCampaign`: Data specific to the championship or transfer campaign.
  */

  const [campaignType, setCampaignType] = useState(CampaignTypes.TRANSFER);
  const [campaignId, setCampaignId] = useState();
  const [campaignStartDate, setCampaignStartDate] = useState();
  const [eventData, setEventData] = useState();
  const [like, setLike] = useState([]);
  const [eventDetail, setEventDetail] = useState();
  const [campaignTitle, setCampaignTitle] = useState();
  const [transferChampionShipCampaign, setTransferChampionShipCampaign] = useState();
  
  /* 
    Translation hook for managing multi-language support.
    - `t`: A function used to retrieve translations for different texts based on the current language.
  */
  const {t} = useTranslation();

  
  /* 
    Asynchronous function to fetch campaign details from the server.
    - The function extracts the campaign ID from the URL.
    - It then fetches campaign data using the `getCampaign` service function.
    - The response data is used to update state variables with relevant campaign information.
  */
  const getCampaignDetail = async () => {
    // Extract campaign ID from the URL
    const url = window.location.href;
    const pathId = url.substring(url.lastIndexOf('/') + 1);
    
    const id = pathId; 
    const status = 1;
    const sort = "DESC";
    const page = 1;
    const perPage = 1;

    // Fetch campaign data using the extracted ID
    const resCampaign = await getCampaign({ id, status, sort, page, perPage })

    // Update state variables with the fetched campaign data
    setEventData(resCampaign?.data?.data?.data[0] || {});
    setLike(resCampaign?.data?.data?.data[0]?.isLiked || []);
    setEventDetail(resCampaign?.data?.data?.data[0]?.detail || {});
    setCampaignTitle(resCampaign?.data?.data?.data[0]?.title || {});
    setCampaignType(resCampaign?.data?.data?.data[0]?.type);
    setCampaignId(resCampaign?.data?.data?.data[0]?.id);
    setCampaignStartDate(resCampaign?.data?.data?.data[0]?.startDate);
    setTransferChampionShipCampaign(resCampaign?.data?.data?.data[0])
  };

  /* 
    `useEffect` hook to call `getCampaignDetail` function when the component mounts.
    - Ensures that campaign details are fetched and state is updated when the component is first rendered.
  */
  useEffect(() => {
    getCampaignDetail();
  },[])

  return (
    <>
      <div>
        <SEO
          title={`${campaignTitle} , ${t("campaignMetaTitle")}`}
          description={`${campaignTitle} , ${t("campaignMetaDescription")}`}
          keywords={`${campaignTitle} , ${t("campaignMetaKeywords")}`}
          url="https://www.taraftardestek.com/"
        />
      </div>

      <CampaignDetailMain className="campaign-detail-main">

        <ErrorComponent />

        <CampaignDetailBackground>

          <CampaignDetailImg src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/adee39a7-f3c1-43f4-81da-8cbd1a26f500/original" />

          <CampaignDetailTitleBreadcrumbsContainer>
            <CampaignDetailTitleContainer>
              {/* <CampaignDetailIcon src={teamIcon} /> */}
              <CampaignDetailTitle>
                {/* {t("campaignDetail")}  */}
                {campaignType === CampaignTypes.EVENT && (
                  <>
                    {campaignTitle}
                  </>                  
                )}
                {campaignType === CampaignTypes.TRANSFER && (
                  <>
                    {campaignTitle}
                  </>
                )}
                {campaignType === CampaignTypes.CHAMPIONSHIP && (
                  <>
                    {campaignTitle}
                  </>
                )}
                {campaignType === CampaignTypes.SCORE && (
                  <>
                    {campaignTitle}
                  </>
                )}
                </CampaignDetailTitle>
            </CampaignDetailTitleContainer>

            <Breadcrumbs />
          </CampaignDetailTitleBreadcrumbsContainer>

        </CampaignDetailBackground>

        <CampaignDetailContent>

          <CampaignDetailContentContainer>

            <CampaignDetailJoinDescContainer>

              {campaignType === CampaignTypes.TRANSFER && (
                <>
                  <CampaignTransferContent className="campaign-transfer-content">
                    {campaignTitle && (
                      <CampaignDetailContentLeftTitle>
                        {campaignTitle}
                      </CampaignDetailContentLeftTitle>
                    )}
                    <CampaignTransferDetailCard
                      data={transferChampionShipCampaign}
                    />
                  </CampaignTransferContent>
                </>  
              )}

              {campaignType === CampaignTypes.EVENT && (
                <CampaignDetailContentLeft className="campaign-detail-content-left">
                <>
                  {campaignTitle && (
                    <CampaignDetailContentLeftTitle>
                      {campaignTitle}
                    </CampaignDetailContentLeftTitle>
                  )}
                  <CampaignCompetitionDetailCard 
                    homeTeam={{
                      name: eventDetail?.homeTeam?.name || "",
                      shortName: eventDetail?.homeTeam?.shortName || "",     
                      largeBadge: eventDetail?.homeTeam?.largeBadge || "",     
                      homeAmount:eventDetail?.homeAmount || 0,
                      homeJoinId:eventDetail?.homeJoinId || "",                                            
                    }}
                    homeAmount={eventDetail?.homeAmount || 0}

                    awayTeam={{
                      name: eventDetail?.awayTeam?.name || "",
                      shortName: eventDetail?.awayTeam?.shortName || "",     
                      largeBadge: eventDetail?.awayTeam?.largeBadge || "", 
                      awayAmount:eventDetail?.awayAmount || 0,
                      awayJoinId:eventDetail?.awayJoinId || "",
                    }}
                    awayAmount={eventDetail?.awayAmount || 0}
                    
                    campaign={{
                      id: campaignId || "",
                      startDate: campaignStartDate || "",
                          }}
                          
                        />
                      </>
                      </CampaignDetailContentLeft>
              )}
              
              {campaignType === CampaignTypes.CHAMPIONSHIP && (
                <>
                  <CampaignTransferContent className="campaign-transfer-content">
                    {campaignTitle && (
                      <CampaignDetailContentLeftTitle>
                        {campaignTitle}
                      </CampaignDetailContentLeftTitle>
                    )}
                    <CampaignTransferDetailCard
                      data={transferChampionShipCampaign}
                    />
                  </CampaignTransferContent>
                </>           
              )}

              {campaignType === CampaignTypes.SCORE && (
                <>
                  <CampaignTransferContent className="campaign-transfer-content">
                    {campaignTitle && (
                      <CampaignDetailContentLeftTitle>
                        {campaignTitle}
                      </CampaignDetailContentLeftTitle>
                    )}
                    <CampaignTransferDetailCard
                      data={transferChampionShipCampaign}
                    />
                  </CampaignTransferContent>
                </>           
              )}
                  
              

                {campaignType === CampaignTypes.EVENT && (
                  <CampaignDetailContentRight>
                  <>
                    <CampaignNumbersDetailCard 
                      homeTeam={{
                        name: eventDetail?.homeTeam?.name || "",   
                        homeAmount:eventDetail?.homeAmount || 0,                                         
                      }}
                      homeAmount={eventDetail?.homeAmount || 0}

                      awayTeam={{
                        name: eventDetail?.awayTeam?.name || "",
                        awayAmount:eventDetail?.awayAmount || 0,
                      }}
                      awayAmount={eventDetail?.awayAmount || 0}
                      campaign={{
                        id: campaignId || "",
                        like: eventData?.likes || 0,
                        participant: eventData?.participantCount || 0,
                        liked: eventData?.isLiked,
                      }}
                    />
                  </>
                  </CampaignDetailContentRight>
                )}              

            </CampaignDetailJoinDescContainer>

            <CampaignDetailTopTenContainer>

            <CampaignCard
              icon={announcement}
              title={t("campaignDonors")}
              desc={t("top10List")}
              cardWidth="100%"
              responsiveSmallWidth="100%"
              responsiveWidth="100%"
              responsiveDonorListWidth="100%"
              cardHeight="none"
              contentHeight="100%"
              cardPadding="10px"
              seeAllText=" "
              contents={
                <CampaignDonorsListContainer>

                {eventDetail?.topParticipants ? (
                  <CampaignDonorsList
                    title={t("general")}
                    participants={eventDetail?.topParticipants}
                    homeId={eventDetail?.homeTeam?.id} 
                    homeLogo={eventDetail?.homeTeam?.largeBadge} 
                    awayId={eventDetail?.awayTeam?.id}              
                    awayLogo={eventDetail?.awayTeam?.largeBadge}              
                  />
                ) : (
                  <CampaignDonorsList
                    title={t("general")}                  
                  />
                )}

                {eventDetail?.homeTeamParticipants ? (
                  <CampaignDonorsList
                    title={eventDetail?.homeTeam?.name || ""}
                    participants={eventDetail?.homeTeamParticipants}
                    logo={eventDetail?.homeTeam?.largeBadge}
                  />
                ) : (
                  <CampaignDonorsList
                    title={eventDetail?.homeTeam?.name || ""}                  
                  />
                )}

                {eventDetail?.awayTeamParticipants ? (
                  <CampaignDonorsList
                    title={eventDetail?.awayTeam?.name || ""}
                    participants={eventDetail?.awayTeamParticipants}
                    logo={eventDetail?.awayTeam?.largeBadge}
                  />
                ) :(
                  <CampaignDonorsList
                    title={eventDetail?.awayTeam?.name || ""}                  
                  />
                )}

              </CampaignDonorsListContainer>
              }
            >
            </CampaignCard>
              
            </CampaignDetailTopTenContainer>

          </CampaignDetailContentContainer>

        </CampaignDetailContent>

      </CampaignDetailMain>
    </>
  );
};

export default CampaignDetail;

export const CampaignDetailMain = styled.main`
  background-color: #FAFAFA;
  padding-bottom: 80px;
`;

export const CampaignDetailBackground = styled.div`
  width: 100%;
`;

export const CampaignDetailImg = styled.img`
  width: 100%;
  height: 300px;
`;

export const CampaignDetailTitleBreadcrumbsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  bottom: 4px;
  padding: 20px 30px;
  background: var(--bg-navbar);
`;

export const CampaignDetailTitleContainer = styled.div`
  display: flex;
`;

export const CampaignDetailIcon = styled.img`
  position: relative;
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 15px;
  padding: 5px;
`;

export const CampaignDetailTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
  color: white;
`;

export const CampaignDetailContent = styled.section`
  max-width: 1130px;
  margin: 50px auto;
`;
export const CampaignDetailContentContainer = styled.div`
  margin: 0 20px;
`;

export const CampaignDetailJoinDescContainer = styled.div`
  display: flex;
  gap: 22px;
  width: 100%;
  margin: 50px auto;

  @media (max-width: 720px) {
    flex-wrap: wrap;
  } 
`;
export const CampaignTransferContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
  width: 100%;
  padding: 50px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #dbe0e5;

  &:hover {
    box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16); 
  }

  @media (max-width: 720px) {
    width: 100%;
    padding: 20px;
  } 
`;
export const CampaignDetailContentLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
  width: 50%;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #dbe0e5;

  &:hover {
    box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16); 
  }

  @media (max-width: 720px) {
    width: 100%;
    padding: 20px;
  } 
`;
export const CampaignDetailContentLeftTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
`;
export const CampaignDetailContentLeftDesc = styled.div`
  font-size: 14px;
  background-color: #80808069;
  padding: 10px 15px;
  border-radius: 5px;
`;
export const CampaignDetailContentRight = styled.div`
  width: 48%;

  @media (max-width: 720px) {
    width: 100%;
    margin: 0 20px;
  }
`;

export const CampaignDetailTopTenContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const CampaignDonorsListContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin: 0 20px;

  @media screen and (max-width: 1024px) {
    flex-wrap: wrap;
    // gap: 10px;
  }
`;

