import { fansupportPublic } from "../index";
/**
 * Asynchronously fetches the document types based on the given language ID.
 *
 * @async
 * @function
 * @name getDocumentTypes
 * @param {Object} options - An object containing the following properties:
 * @param {string} options.languageId - The ID of the language to fetch the document types for.
 * @return {Promise<Object>} A Promise that resolves to the fetched document types data in JSON format.
 * @throws {Error} If an error occurs during the request.
 */
export const getDocumentTypes = async ({ languageId }) => {
    return fansupportPublic.get('/document/types', {params: {
        languageId,
    }})
}