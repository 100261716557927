import { useState } from "react";
import { Outlet } from "react-router-dom";
import { NavbarHome, NavbarLandingPage } from "../../components";
import styled from "styled-components";
/* 
  HomeLayout Component:
  - This component serves as the main layout for the page.
  - It toggles between two different navigation bars based on user interaction.
  - The content area is dynamically updated through the Outlet component based on nested routes.
*/

const HomeLayout = () => {
  /* 
    State:
    - showNavbarHome determines which navigation bar to display.
    - Initially set to false, so the NavbarLandingPage is displayed first.
  */
  const [showNavbarHome, setShowNavbarHome] = useState(false);

  /*
    Event Handler:
    - handleKesfetButtonClick is triggered when the "Discover" button is clicked.
    - This function sets showNavbarHome to true, causing the NavbarHome to be displayed.
  */
  const handleKesfetButtonClick = () => {
    setShowNavbarHome(true);
  };

  return (
    <>
    <NavbarContainerWrapper>
    {showNavbarHome ? <NavbarHome /> : 
      <NavbarLandingPage 
      onKesfetButtonClick={handleKesfetButtonClick} />}
    </NavbarContainerWrapper>
    
    <Outlet/>
    </>
  )
}

export default HomeLayout

export const NavbarContainerWrapper = styled.div`
  padding-top: 60px;
`;