import { Outlet } from "react-router-dom";
import { NavbarSubHome } from "../../components";
import styled from "styled-components";

/**
 * SportsLayout bileşeni, üst kısımda sabit bir navigasyon çubuğu ve altında değişen içerik sağlayan bir düzen şablonudur.
 * - `NavbarSubHome` bileşeni, sayfanın üst kısmındaki navigasyon çubuğunu render eder.
 * - `Outlet` bileşeni, alt bileşenlerin render edilmesini sağlar.
*/

const SportsLayout = () => {
  return (
    <>
    <NavbarContainerWrapper>
      <NavbarSubHome/>
    </NavbarContainerWrapper>

    <Outlet/>
    </>
  )
}

export default SportsLayout

export const NavbarContainerWrapper = styled.div`
`;