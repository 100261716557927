import React from 'react';
import { NavbarLandingPage } from '../../components';
import AboutUs from '../help/AboutUs';
import styled from "styled-components";

/**
  * The LandingPage component represents a page layout with a navigation bar at the top and an information section below.
  * - The `NavbarLandingPage` component renders the navigation bar at the top of the page and receives the onButtonClick function as a prop.
  * - The `AboutUs` component renders an informational section at the bottom of the page.
  *
  * @param {Function} onButtonClick - Function to handle button click events in the navigation bar.
  */

const LandingPage = ({ onButtonClick }) => {
  return (
    <>
        <NavbarContainerWrapper>
            <NavbarLandingPage onButtonClick={onButtonClick}/>
        </NavbarContainerWrapper>
        <AboutUs />
    </>
  )
}

export default LandingPage

export const NavbarContainerWrapper = styled.div`
  padding-top: 60px;
`;