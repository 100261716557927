import { fansupportPublic } from "../index";
/**
 * This function sends a POST request to the server to reset the user's password.
 *
 * @async
 * @function
 * @param {string} token - The token from the email to reset the password.
 * @param {string} password - The new password for the user.
 * @param {string} passwordConfirm - The confirmation of the new password.
 * @throws {Error} If the request fails.
 * @return {Promise} A Promise that resolves to the response data or rejects with an error.
 */
export const getResetPassword = async ( token, password, passwordConfirm ) => {
    // console.log("payload", token);
    try {
        const data = await fansupportPublic.post('/identity/reset-password', {
            token: token,
            password: password,
            passwordConfirm: passwordConfirm
    })
        return data;

    } catch (error) {
        console.log("getResetPassword", error);
    }
}