/**
 * TeamDetail Component
 * 
 * This component is responsible for rendering detailed information about a specific sports team, including 
 * campaign details, team details, top contributors, and various statistics related to the team and its campaigns.
 * It handles user interactions such as selecting a donation amount and initiating a campaign donation process.
 * The component uses multiple state variables to manage data and user inputs, and leverages various services
 * to fetch the necessary data.
 * 
 * Dependencies:
 * - React: For creating and managing component state and lifecycle.
 * - React Router: For navigation within the application.
 * - Redux: For accessing global state, specifically the user state.
 * - Mantine: For handling UI elements like Modal.
 * - i18next: For internationalization and localization.
 * - styled-components: For styling React components with CSS-in-JS.
 * 
 * Services:
 * - campaignService: To fetch campaign details and join campaigns.
 * - teamDetailService: To fetch detailed information about the team.
 * - teamTopOneHundredService: To fetch the top 100 contributors.
 * - sportTopOneHundredService: To fetch the top 100 contributors in sports.
 * - campaignInNumbersService: To fetch statistical data about the campaign.
 * 
 * Utilities:
 * - RemoveTurkishChars: Utility function to remove Turkish characters from text.
 * - getStartLanguage: Utility function to retrieve the starting language of the application.
 * - windowOpener: Utility function to open URLs in a new window with specific dimensions.
 * 
 * Usage:
 * - The component fetches data when it mounts using the `useEffect` hook.
 * - It handles user interactions like selecting donation amounts and opening modals for payments.
 * - SEO metadata is set based on the team details.
 */
import React,{useState, useEffect} from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useDisclosure } from "@mantine/hooks";
import { Modal } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import announcement from "../../assets/announcement.svg";
import JoinButton from "../../components/button/JoinButton";
import { getCampaign } from "../../services/campaign/campaignService";
import { getTeamDetail } from "../../services/sport/teamDetailService";
import { getTopOneHundred } from "../../services/payment/teamTopOneHundredService"; 
import { getSportTopOneHundred } from "../../services/campaign/sportTopOneHundredService";
import { getCampaignInNumbers } from "../../services/campaign/campaignInNumbersService";
import {
  Breadcrumbs,
  CampaignCard, 
  MatchStaticsCard, 
  CampaignNumbersCard, 
  CampaignCompetitionCard, 
  GoalKingCard, 
  AllPlayersCard, 
  CampaignDonorsCard,
  SEO
}from "../../components"
import NotFound from "../not-found/NotFound";
import { postCampaign } from "../../services/campaign/campaignJoinService";
import RemoveTurkishChars from "../../logic/RemoveTurkishChars";
import { getStartLanguage } from "../../logic/getStartLanguage";

const windowOpener = (link) => {
  const newWindow = window.open(link, '_blank', 'fullscreen=yes');
  if (newWindow) {
    newWindow.moveTo(0, 0);
    newWindow.resizeTo(window.screen.width, window.screen.height);
  }
};

const TeamDetail = () => {

  const symbol = '₺';

  const [teamData, setTeamData] = useState([]);
  const [teamDetailData, setTeamDetailData] = useState([]);
  const [topOneHundred, setTopOneHundred] = useState();
  const [campaignNumbersData, setCampaignNumbersData] = useState({});
  const [opened, { open, close }] = useDisclosure(false);
  const [selectAmount, setSelectAmount] = useState("");
  const [campaignMessage, setCampaignMessage] = useState("");
  const [iframeSrc, setIframeSrc] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [warningEmpty, setWarningEmpty] = useState(false);
  const [activeValue, setActiveValue] = useState(symbol);
  const [activeSymbol, setActiveSymbol] = useState(symbol);
  const [isEmpty, setIsEmpty] = useState(false);

  const user  = useSelector((state) => state.user);

  
  const navigate = useNavigate();
  const {t} = useTranslation();
  const startLanguage = getStartLanguage();

  const url = window.location.href;
  const pathId = url.substring(url.lastIndexOf('/') + 1);

  const predefinedAmounts = [50, 100, 250, 500, 1000, 5000];
  const predefinedAmountsGlobal = [10, 50, 100, 200, 500, 1000];





  const fetchCampaignDetail = async () => {

    const teamId = pathId;
    const page = 1;
    const perPage = 1;

    const res = await getCampaign({ teamId, page, perPage })

    setTeamData(res?.data?.data?.data[0]);
  };

  // console.log("ill:",teamData?.detail?.homeJoinId);

  const fetchTeamDetail= async () => {

    const teamId = pathId; 

    const res = await getTeamDetail({ teamId })

    setTeamDetailData(res?.data?.data || []);
  }
  // console.log("teamDetailData", teamDetailData);


  const fetchTopOneHundred = async () => {

    const teamId = pathId; 

    const res = await getSportTopOneHundred({ teamId })

    setTopOneHundred(res?.data?.data?.top100 || {});
  }

  // console.log("topOneHundred", topOneHundred);

  const fetchCampaignInNumbers = async () => {

    const sportId = "6498b1e91d6341510544c3a8";

    const resNumbers = await getCampaignInNumbers({sportId})

    setCampaignNumbersData(resNumbers?.data?.data || {});
  }
  // console.log("campaignNumbersData:", campaignNumbersData);




  const joinCampaign = async () => {
    const selectedCampaignId = teamData?.id;
    const selectedJoinId = teamData?.detail?.homeJoinId;
    const selectedAmount = selectAmount;
    // console.log("selectedJoinId:", selectedJoinId);
    // console.log("selectedAmount:", selectedAmount);
    // console.log("selectedCampaignId:",selectedCampaignId);
    if (user.user) {
      const res = await postCampaign(selectedCampaignId, selectedJoinId, selectedAmount);
      setCampaignMessage(res?.data?.message);
      setIframeSrc(res?.data?.data)
      // console.log("message",res?.data?.message);

      

      if(res?.data?.data){
         // Ödeme ekranı URL'sini aldık
         const iframeSrc = res?.data?.data;
         // İframe bileşenine URL'yi ileterek ödeme ekranını gösterdim
         setIframeSrc(iframeSrc);
         //open();
         windowOpener(iframeSrc);
        // console.log("CampaignUrl",res?.data?.data);
        // console.log("KampanyaUrl",iframeSrc);
      }
      if(res?.data?.message){
        open();
      }
    }
    else{
      navigate(`/${startLanguage}/${RemoveTurkishChars({ text: t("login") })}`)
    }
}

const checkMinimumAmount = (amount) => {
    if ((activeSymbol === '₺') && amount < 50) {
      setWarningMessage(`Minimum tutar 50 ${activeSymbol} olmalıdır.`);
      return false;
    } else if ((activeSymbol === '$' || activeSymbol === '€') && amount < 10) {
      setWarningMessage(`Minimum tutar 10 ${activeSymbol} olmalıdır.`);
      return false;
    }
    else{
      setWarningMessage(""); // Clear the warning message
      return true;
    }
  };

const handleAmountSelect = (amount) => {
    setSelectAmount(amount);
};

const handleJoinClick = () => {
    open();
}


const handleContinue = () => {
    if (!selectAmount || selectAmount === "") {
        setIsEmpty(true);
    } else if (!checkMinimumAmount(selectAmount)) {
        setWarningEmpty(true);
        setIsEmpty(false);
    } else {
        setIsEmpty(false);
        setWarningEmpty(false);
        joinCampaign();
        close();
    }
};

const handleCloseModal = () => {
    setSelectAmount("");
    setIsEmpty(false);
    setWarningMessage("");
    close();
};

const currencyOptions = [
  { value: '₺', label: "₺", number: '949' },
  { value: '$', label: "$", number: '840' },
  { value: '€', label: "€", number: '978' },
];  

const handleSegmentChange = (value) => {
  setActiveValue(value);
  setActiveSymbol(value);
  const selectedCurrency = currencyOptions.find((currency) => currency.value === value);
  localStorage.setItem("selectedCurrency",value);
  localStorage.setItem("selectedCurrencyCode", selectedCurrency?.number);
};


  useEffect(() => {
    fetchCampaignDetail();
    fetchTeamDetail();
    fetchTopOneHundred();
    fetchCampaignInNumbers();
  },[])

  return (
    <>
      <div>
        <SEO
          title={`${teamDetailData?.name} , ${t("teamMetaTitle")}`}
          description={`${teamDetailData?.name} , ${t("teamMetaDescription")}`}
          keywords={`${teamDetailData?.name} , ${t("teamMetaKeywords")}`}
          url="https://www.taraftardestek.com/"
        />
      </div>
      <TeamDetailMain className="home-main">

        <TeamDetailBackground>
          <TeamDetailImg src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/adee39a7-f3c1-43f4-81da-8cbd1a26f500/original" />

          <TeamDetailTitleBreadcrumbsContainer>
            <TeamDetailTitleContainer>
            <TeamDetailIcon src={teamDetailData?.largeBadge ? teamDetailData?.largeBadge : "https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/5a644523-45d9-4e66-7609-a62c110a2f00/original"} alt="" />
              <TeamDetailTitle>{teamDetailData ? (teamDetailData?.name) : ("")}</TeamDetailTitle>
            </TeamDetailTitleContainer>

            <Breadcrumbs />
          </TeamDetailTitleBreadcrumbsContainer>
        </TeamDetailBackground>

        <TeamDetailContent>

          <CoachStadiumDonateSection className="home-main">

            <CoachStadiumDonateLeft className="campaign-card">

              <CoachContainer>
                <CoachImage src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/baec06f5-d947-4db0-ad25-ee8d69702a00/original" />
                <CoachName>{teamDetailData?.manager?.knownName || ""}</CoachName>
                <CoachDesc>{t("coach")}</CoachDesc>
              </CoachContainer>

              <StadiumContainer>
                <StadiumImage src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/f34930aa-3d83-4659-c030-1d7161ef3400/original" />
                <StadiumName>{teamDetailData?.stadium?.name || ""}</StadiumName>
                <StadiumDesc>{teamDetailData?.stadium?.stadiumCapacity0 || "..."} {t("personCapacity")}</StadiumDesc>
              </StadiumContainer>

            </CoachStadiumDonateLeft>

            <CoachStadiumDonateRight className="campaign-card">
              <div onClick={() => handleJoinClick(teamData?.id) }>
                <JoinButton
                  width="100%"
                  height="70px"
                  border="none"
                  borderRadius="15px"
                  backgroundColor="linear-gradient(to right, rgb(27, 138, 47) 0px, rgb(49, 172, 71) 100%)"
                  textColor="white"
                  fontSize="15px"
                  padding="20px"
                  // hoverBgColor="linear-gradient(to right, rgb(27, 138, 47) 0px, green 100%)"
                  // hoverColor="white"
                >
                  {t("unconditionalDonate")}
                </JoinButton>
              </div>
            </CoachStadiumDonateRight>

          </CoachStadiumDonateSection>

          <MatchDonationStaticsContainer>

            <MatchStaticsCardContainer>

              <CampaignCard 
                icon="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/baec06f5-d947-4db0-ad25-ee8d69702a00/original"
                title={t("matchStatics")}
                cardWidth="98%"
                responsiveWidth="100%"
                responsiveSmallWidth="97%"
                cardHeight="none"
                cardMargin="0 auto"
                cardPadding="10px"
                contents={
                  <MatchStaticsCard />
                }
              /> 
            
            </MatchStaticsCardContainer>

            <CampaignNumbersDonorsCard>

              <CampaignNumbersCard
                title={t("campaignsFigures")}
                flexContainer="flex"
                flexDirection="column"
                alignItems="center"
                gap="0px"
                marginRight="0px"
                campaignNumbers={campaignNumbersData}
              />


                <CampaignCard
                  icon={announcement}
                  darkIcon={"icon-dark"}
                  title={t("campaignDonors")}
                  desc={t("top100List")}
                  cardWidth="96%"
                  responsiveWidth="96%"
                  responsiveSmallWidth="97%"
                  cardHeight="390px"
                  contentHeight="390px"
                  cardPadding="10px"
                  overflow="auto"
                  overflowX="hidden"
                  >
                  {topOneHundred?.length === 0 && topOneHundred?.length === 0 && (
                    <CardContent className='campaign-card-content'>
                      <NotFound text={t("noDonationYet")} />
                    </CardContent>
                  )}
                  {topOneHundred ? (
                      topOneHundred.map(({ id, username, amount }, index) => (
                        <CampaignDonorsCard
                          key={id}
                          id={index}
                          username={username}
                          amount={amount}
                        />
                      )
                    )) : (
                      <NotFound text={t("noDonationYet")} />
                    )
                  }
                </CampaignCard>

            </CampaignNumbersDonorsCard> 

          </MatchDonationStaticsContainer>

          {/* <AdvertSecondImage src="https://wp.pts.net/Content/images/header-image.jpg" /> */}

          {/* <TeamCampaignsPlayersSection>

            <CampaignCard 
              icon={announcement}
              title={t("campaignsOfTeam")}
              cardWidth="50%"
              cardHeight="none"
              cardPadding="10px"
              children={
                campainCompetitionData.map(({firstName, firstAmount, logoFirst, secondName, secondAmount, logoSecond}) => (
                  <CampaignCompetitionCard 
                    firstName={firstName}
                    firstAmount={firstAmount}
                    logoFirst={logoFirst}
                    secondName={secondName}
                    secondAmount={secondAmount}
                    logoSecond={logoSecond}
                  />
                ))         
              }
            /> 

            <KingdomGoalsAllPlayerContainer>

              <CampaignCard 
                icon={announcement}
                title={t("kingdomOfGoals")}
                cardWidth="100%"
                cardHeight="none"
                cardPadding="0px"
                children={
                  donationNumbersData.map(({firstName, firstAmount, logoFirst, secondName, secondAmount, logoSecond}) => (
                    <GoalKingCard 
                      firstName={firstName}
                      firstAmount={firstAmount}
                      logoFirst={logoFirst}
                      secondName={secondName}
                      secondAmount={secondAmount}
                      logoSecond={logoSecond}
                    />
                  ))         
                }
              /> 

              <CampaignCard 
                icon={stadium}
                title={t("allPlayers")}
                cardWidth="100%"
                cardHeight="none"
                cardPadding="0px"
                children={
                  donationNumbersData.map(({firstName, firstAmount, logoFirst, secondName, secondAmount, logoSecond}) => (
                    <AllPlayersCard 
                      firstName={firstName}
                      firstAmount={firstAmount}
                      logoFirst={logoFirst}
                      secondName={secondName}
                      secondAmount={secondAmount}
                      logoSecond={logoSecond}
                    />
                  ))         
                }
              /> 
              
            </KingdomGoalsAllPlayerContainer>

          </TeamCampaignsPlayersSection> */}

          {/* <AdvertCampaignDonorsSection>

            <AdvertThirdImageWrapper>
                <AdvertThirdImage src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/6a130eec-8156-4143-a1c8-cfeec4e05200/original" />
            </AdvertThirdImageWrapper>

            <AdvertFirstImageWrapper>
                <AdvertFirstImage src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/6a130eec-8156-4143-a1c8-cfeec4e05200/original" />
            </AdvertFirstImageWrapper>

          </AdvertCampaignDonorsSection> */}

        </TeamDetailContent>

      </TeamDetailMain>

      {!campaignMessage && 
        <Modal opened={opened} onClose={handleCloseModal} title={t("payment")} centered>
        <ModalContent>
          <ModalText>{t("selectAnAmount")}</ModalText>
          <PredefinedAmounts>
            {activeSymbol === '₺' // Check if symbol is Türk lirası
              ? predefinedAmounts.map((amount) => (
                  <PredefinedAmountButton
                    key={amount}
                    onClick={() => handleAmountSelect(amount)}
                    isSelected={selectAmount === amount}
                  >
                    {amount && parseFloat(amount).toLocaleString()} {activeSymbol}
                  </PredefinedAmountButton>
                ))
              : activeSymbol === '$' || activeSymbol === '€' || !activeSymbol // Check if symbol is Dollar or Euro
              ? predefinedAmountsGlobal.map((amount) => (
                  <PredefinedAmountButton
                    key={amount}
                    onClick={() => handleAmountSelect(amount)}
                    isSelected={selectAmount === amount}
                  >
                    {amount && parseFloat(amount).toLocaleString()} {activeSymbol}
                  </PredefinedAmountButton>
                ))
              : null // Handle other currencies or no symbol
            }
          </PredefinedAmounts>

          <CustomAmountInput
            type="number"
            placeholder={t("determineTheAmount")}
            value={selectAmount}
            onChange={(e) => setSelectAmount(e.target.value)}
            isEmpty={isEmpty}
          />
          {isEmpty && (
            <InputErrorMessage>
              {t("quantityCannotBeEmpty")}
            </InputErrorMessage>
          )}
          {warningEmpty && (
            <InputErrorMessage>
              {warningMessage}
            </InputErrorMessage>
          )}
            <InputAmountDescContainer>
              <InputAmountDesc >{t("mySupportAmount")}</InputAmountDesc>
              <InputAmount activeSymbol={activeSymbol}>{selectAmount && parseFloat(selectAmount).toLocaleString()}</InputAmount>               
            </InputAmountDescContainer>
          <ContinueButton onClick={handleContinue}>{t("cContinue")}</ContinueButton>
        </ModalContent>
        </Modal>
      }

        {campaignMessage && campaignMessage.length > 0 ? (
            <Modal
            opened={opened}
            onClose={() => {
                close();
            }}
            title={t("payment")}
            centered
            transitionProps={{ transition: "fade", duration: 200 }}
            overlayProps={{
                backgroundOpacity: 0,
                blur: 3,
            }}
            >
            <ModalContentMessage>  
                <NotFound text={campaignMessage} />
            </ModalContentMessage>
            <ModalButtonContainer>
                <ModalCloseButton
                onClick={() => {
                    close();
                }}
                >
                {t("close")}
                </ModalCloseButton>
            </ModalButtonContainer>
            </Modal>
        ) : null}    
    </>
  );
};

export default TeamDetail;

export const TeamDetailMain = styled.main`
  background-color: #eee;
  padding-bottom: 80px;
`;

export const TeamDetailBackground = styled.div`
  width: 100%;
`;

export const TeamDetailImg = styled.img`
  width: 100%;
  height: 230px;
`;

export const TeamDetailTitleBreadcrumbsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  bottom: 4px;
  padding: 10px 30px;
  background-color: #033036;
`;

export const TeamDetailTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const TeamDetailIcon = styled.img`
  // position: relative;
  width: 50px;
  height: 50px;
  // background-color: white;
  // border-radius: 15px;
  padding: 5px;
`;

export const TeamDetailTitle = styled.div`
  position: relative;
  font-size: 24px;
  font-weight: 500;
  color: white;
`;

export const CoachStadiumDonateSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background-color: #eee;
  margin: 30px 0px;
  padding: 0 20px;

  @media (max-width: 720px) {
    display: flex;
    gap: 20px;
  }
`;
export const MatchDonationStaticsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: start;
  gap: 10px;
  max-width: 1100px;
  margin: 50px auto;
  height: 630px;
  padding: 0 20px;

  @media (max-width: 720px) {
    height: 100%;
  } 
`;

export const CampaignNumbersDonorsCard = styled.div`
  width: 47%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // gap: 20px;
  height: 630px;

  @media (max-width: 720px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
`;

export const MatchStaticsCardContainer = styled.div`
  width: 50%;

  @media (max-width: 720px) {
    width: 100%;
  }
`;
export const CoachStadiumDonateLeft = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px;
  width: 47%;
  height: 80px;
  background-color: white;

  @media (max-width: 930px) {
   width: 45%; 
  }
  @media (max-width: 720px) {
    width: 100%;
  }
`;
export const CoachContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const CoachImage = styled.img`
  width: 50px;
  height: 50px;
`;
export const CoachName = styled.div`
  
`;
export const CoachDesc = styled.small`
  color: grey;
`;
export const CoachStadiumDonateRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 43%;
  background-color: white;
  padding: 20px;
  height: 80px;

  @media (max-width: 930px) {
    width: 43%; 
  }
  @media (max-width: 720px) {
    width: 100%;
  }
`;

export const StadiumContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StadiumImage = styled.img`
  width: 50px;
  height: 50px;
`;
export const StadiumName = styled.div``;
export const StadiumDesc = styled.small`
  color: grey;
`;

export const TeamCampaignsPlayersSection = styled.section`
  display: flex;
  gap: 10px;
  background-color: #eee;
  margin: 30px 20px;
`;

export const KingdomGoalsAllPlayerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 50%;
  background-color: #eee;
`;

export const AdvertCampaignDonorsSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  margin: 30px 0px;
  background-color: rgb(238, 238, 238);

  @media (max-width: 900px) {
    display: none;
  }
`;

export const AdvertThirdImageWrapper = styled.div`
`;
export const AdvertFirstImageWrapper = styled.div`

`;
export const AdvertFirstImage = styled.img`
  width: 500px;
  // height: 100%;
  border-radius: 10px;
`;
export const AdvertSecondImage = styled.img`
  width: 100%;
`;
export const AdvertThirdImage = styled.img`
  width: 500px;
  // height: 100%;
  border-radius: 10px;
`;

export const TeamDetailContent = styled.section`
  max-width: 1100px;
  margin: 0 auto 50px auto;
`;

export const ModalContentMessage = styled.div`
  height: 300px;
  padding: 30px 5px;
`;

export const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 15px;
`;

export const ModalCloseButton = styled.button`
  background-color: transparent;
  color: #333;
  padding: 10px 15px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f1f1f1;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 20px;
  padding: 20px;
`;

export const ModalText = styled.div`
  font-size: 18px;
  margin-bottom: 10px;
`;

export const PredefinedAmounts = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 10px;
`;

export const PredefinedAmountButton = styled.button`
  width: 100px;
  padding: 12px 23px;
  font-size: 16px;
  border: none;
  background-color: ${(props) => (props.isSelected ? "#4caf50" : "#f0f0f0")};
  color: ${(props) => (props.isSelected ? "#ffffff" : "#000000")};
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #4caf50;
    color: #ffffff;
  }
`;

export const InputErrorMessage = styled.div`
  font-size: 14px;
  color: #f44336;
  margin-top: 5px;
`;

export const CustomAmountInput = styled.input`
  padding: 12px;
  font-size: 16px;
  border: 3px solid ${({ isEmpty }) => (isEmpty ? "#f44336" : "#ccc")};
  border: 3px solid ${({ warningMessage }) => (warningMessage ? "#f44336" : "#ccc")};
  border-radius: 4px;
  margin-bottom: 10px;
  transition: border-color 0.3s;
  position: relative;

  &:focus {
    border-color: ${({ isEmpty }) => (isEmpty ? "#f44336" : "#4caf50")};
    border-color: ${({ warningMessage }) => (warningMessage ? "#f44336" : "#4caf50")};
  }
`;

export const InputAmountDescContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 5px;
`;

export const InputAmountDesc = styled.div`

`;

export const InputAmount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  ::after {
    content: "${(props) => props.activeSymbol}";
    margin-left: 10px;
    color: green;
    font-size: 18px;
  }
`;

export const ContinueButton = styled.button`
  padding: 12px 24px;
  font-size: 18px;
  background-color: #4caf50;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #45a049;
  }
`;
export const CardContent = styled.div`
  display: ${props => props.display || 'flex'};
  flex-direction: column;
  justify-content: ${props => props.cardJustify || 'start'};
  justify-content: ${(props) => (props.children && props.children.length > 0 ? props.cardJustify : 'center')};
  gap: ${props => props.cardGap || '10px'};
  height: ${props => props.contentHeight || '550px'};
  max-height: ${props => props.contentHeight || '550px'};
  overflow: ${props => props.overflow || 'none'};
  overflow-x: ${props => props.overflowX || 'none'};
  background-color: white;

  /* Width and height of the scrollbar track */
  ::-webkit-scrollbar {
    width: 5px;
    height: 10px;
    border-radius: 15px;
  }

  /* Background color of the scrollbar track */
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  /* Color of the scrollbar handle */
  ::-webkit-scrollbar-thumb {
    background-color: #888;
  }

  /* Color of the scrollbar handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
`;