/*
  This file defines the `Login` component, which handles user login functionality. It integrates with Redux for state management and form handling.

  The component uses `useFormik` for form management and `useDispatch` to dispatch login actions. It checks authentication status with `useSelector`, and if the user is authenticated, it redirects to the home page using `Navigate`.

  Key features and functionalities:
  - **Form Handling**: `formik` manages form state and submission.
  - **Authentication Check**: If `isAuthenticated` is true, the user is redirected to the home page.
  - **Loading and Error Handling**: Displays a loading spinner and error messages based on the authentication state.
  - **Component Layout**: Includes an image, login form with inputs for username and password, and additional UI elements like Google login button, password reset link, and registration link.
  - **Styling**: Utilizes `styled-components` for styling, including responsive design with media queries.

  **Components and Styling**:
  - `AuthContainer`: Centered container for the login page with animations.
  - `ImgContainer` and `ImgLogin`: Container and image for the visual representation of the login page.
  - `Card`: Container for the login form with shadow and border styling.
  - `AuthContainerForm`: Contains the form elements with animation.
  - `LoginTitle`: Title for the form.
  - `LoginForm`: Styles for form inputs.
  - `GeneralButton`: Button for submitting the login form, disabled during loading.
  - `LoginGoogleButton`: Button for Google login, currently hidden.
  - `ResetContent`, `ResetLink`, `RegisterSpan`, `RegisterText`, `RegisterLink`: Links and text for password reset and registration.

  The component provides a user-friendly interface for logging in, handling errors, and navigating to related pages.
*/
import React from "react";
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FaGoogle } from "react-icons/fa" 
import { login, selectIsAuthenticated } from "../../redux/features/auth/authSlice";
import { Loaders } from "../../components/loader/Loader";
import { RegisterInput, GeneralButton, SEO } from "../../components";
import { getStartLanguage } from "../../logic/getStartLanguage";
import RemoveTurkishChars from "../../logic/RemoveTurkishChars";

const Login = () => {
  const  dispatch = useDispatch();
  const { loading, error, errorMessage, user} = useSelector((state) => state.user);
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const { t } = useTranslation();
  const startLanguage = getStartLanguage();

  const formik = useFormik({
    initialValues: {
      username: '',
      password: ''
    },
    onSubmit: (values) => {
      // { username: 'username', password: 'password' }
      dispatch(login(values))
    }
  });

  if (isAuthenticated) {
    return <Navigate replace={true} to="/" />;
  }
 
  return (
    <>
      <div>
        <SEO
          title={t("login")}
          description={t("login")}
          keywords={t("login")}
          url="https://www.taraftardestek.com/"
        />
      </div>
        <AuthContainer>
        <ImgContainer>
          <ImgLogin src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/11e41d03-2281-43b9-4f12-df4395b2b500/original" alt="Login" width="400" />
        </ImgContainer>
        <Card className="login-card">
        <AuthContainerForm onSubmit={formik.handleSubmit}>
            {loading && <Loaders />}
            <LoginTitle className="login-title">{t("login")}</LoginTitle>
            <LoginForm>

              <RegisterInput
                name="username" 
                type='text'
                value={formik.values.username} 
                onChange={formik.handleChange} 
                label={t("loginUsernameHint")}
              />
              <RegisterInput
                name="password" 
                type="password" 
                value={formik.values.password} 
                onChange={formik.handleChange}
                label={t("password")}
              />
              {error && <p style={{color: 'red'}}>{errorMessage}</p>}
              {user && user.isSuccess && <p style={{ color: "red" }}>{user.message}</p>}
              <GeneralButton 
              type="submit" 
              disabled={loading}
              width="100%"
              height="40px"
              >
              {loading && <Loaders size={15} color="grey" borderSize={3}/>} 
                {t("login")}
                </GeneralButton>
              <ResetContent>
                  <ResetLink to={`/${startLanguage}/${RemoveTurkishChars({ text: t("resetPassword") })}`}>{t("resetPassword")}</ResetLink>
              </ResetContent>
              <LoginOr>{t("or")}</LoginOr>
              <LoginGoogleButton type='submit'>
                  <FaGoogle color="#fff"/>
                  <LoginGoogleSpan>{t("loginWithGoogle")}</LoginGoogleSpan>
              </LoginGoogleButton>
              <RegisterSpan>
                  <RegisterText>{t("dontHaveAccount")}</RegisterText>
                  <RegisterLink to={`/${startLanguage}/${RemoveTurkishChars({ text: t("register") })}`}>{t("register")}</RegisterLink>
              </RegisterSpan>
            </LoginForm>
        </AuthContainerForm>
        </Card>
      </AuthContainer>
    </>
  );
};

export default Login;

export const AuthContainer = styled.section`
  width: 100%;
  padding: 4rem 0;
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  @keyframes slide-up {
    0% {
      transform: translateY(-5rem);
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes slide-down {
    0% {
      transform: translateY(5rem);
    }
    100% {
      transform: translateY(0);
    }
  }

  @media screen and (max-width: 700px) {
    .img {
      display: none;
    }
  }
`;
export const ImgContainer = styled.div`
  animation: slide-down 1.5s ease;
  margin-right: 30px;
`;
export const ImgLogin = styled.img`
  width: 600px;

  @media (max-width: 700px) {
    width: 400px;
  }
`;
export const Card = styled.div`
  width: 400px;
  border: 1px solid transparent;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;;
  overflow: hidden;
`;
export const AuthContainerForm = styled.div`
  padding: 1.5rem;
  animation: slide-up 1.5s ease;
`;
export const LoginTitle = styled.div`
  margin: 0px;
  font-size: 24px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 15px;
`;
export const LoginForm = styled.form`
  input[type="text"],
  input[type="email"],
  input[type="password"] {
    padding: 10px;
    margin: 10px 0;
    width: 94%;
    border: 1px solid rgb(119, 119, 119);
    border-radius: 5px;
    outline: none;
    height: 20px;
  }
`;
// export const LoginName = styled.input``;

export const LoginPassword = styled.input`

`;
export const ResetContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
`;
export const ResetLink = styled(Link)`
  font-weight: 500;
  color: black;
  text-decoration: none;
`;
export const LoginOr = styled.p`
  display: none;
  margin: 15px;
  color: grey;
  text-align: center;
`;
export const RegisterSpan = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
`;
export const RegisterText = styled.p`
  margin: 0;
  color: grey;
`;
export const RegisterLink = styled(Link)`
  font-weight: 500;
  text-decoration: none;
  margin-left: 5px;
`;
// export const GeneralButton = styled.button`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   transition: all 0.3s;
// `;

export const LoginGoogleButton = styled.button`
  display: none;
  width: 100%;
  color: #fff;
  background: orangered;
  font-weight: 400;
  padding: 6px 8px;
  margin: 0 5px 0 0;
  border: 1px solid transparent;
  border-radius: 3px;
  cursor: pointer;
  // display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
`;
export const LoginGoogleSpan = styled.span`
  margin-left: 5px;
`;

