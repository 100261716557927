/**
 * The `CampaignDonorsCard` component in JavaScript React displays information about campaign donors
 * with their username and donation amount formatted in a currency format.
 * @returns The `CampaignDonorsCard` component is being returned in the code snippet. It is a
 * functional component that displays information about campaign donors. The component includes styled
 * components for different parts of the card layout such as `CampaignsBoxContent`, `CampaignsBox`,
 * `CampaignsBoxDesc`, `CampaignsBoxTitle`, `CampaignsBoxDescAmount`, and `CampaignsBoxCenter`. The
 */
import React from "react";
import styled from "styled-components";
import { CurrencyFormat } from "../../components";

const CampaignDonorsCard = (props) => {
  const { id, username, amount } = props;

  const symbol = '₺';

  return (
    <>
      <CampaignsBoxContent>
        <CampaignsBox>
          {id + 1}-
          <CampaignsBoxCenter>
            <CampaignsBoxDesc>
              <CampaignsBoxTitle>{username}</CampaignsBoxTitle>
            </CampaignsBoxDesc>
          </CampaignsBoxCenter>
        </CampaignsBox>

        <CampaignsBoxDescAmount symbol={symbol}>
        <CurrencyFormat amount={amount}/>
        </CampaignsBoxDescAmount>
      </CampaignsBoxContent>
    </>
  );
};

export default CampaignDonorsCard;

export const CampaignsBoxContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  padding: 10px;
  border-top: 1px solid #eee;
`;
export const CampaignsBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const CampaignsBoxDesc = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 5px;
`;
export const CampaignsBoxTitle = styled.div`
  font-weight: 500;
`;
export const CampaignsBoxDescAmount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: green;
  font-size: 18px;
  font-weight: 500;

  ::after {
    content: "${(props) => props.symbol}";
    margin-left: 8px;
    color: #4caf50;
    font-size: 16px;
  }
`;
export const CampaignsBoxCenter = styled.div`
  display: flex;
`;
