/**
 * `Standings` Component
 * 
 * This component displays the standings of a specific tournament and ads in a web application. It fetches ads and standings data from external services, displays ads on the sides of the page, and renders the standings data in the main section.
 * 
 * **Key Functionalities**:
 * - Fetches advertisement data and standings data asynchronously using API calls.
 * - Displays ads on the left and right sides of the page.
 * - Displays a `NotFound` component if no ads data is available.
 * - Shows the standings data with a loading state indicator while data is being fetched.
 * - Uses `react-redux` to get selected country and language information from the state.
 * - Manages state for ads data, standings data, and loading status.
 * 
 * **Components and Elements**:
 * - `AdImageCard`: Displays advertisement images on the left and right sides of the page.
 * - `Breadcrumbs`: Displays navigation breadcrumbs at the top of the page.
 * - `Standing`: Displays the standings data.
 * - `NotFound`: Displays a not found message if no ads data is available.
 * 
 * **State Management**:
 * - `adsData`: Stores the fetched ads data.
 * - `standingsData`: Stores the fetched standings data.
 * - `loading`: Indicates whether the standings data is currently being fetched.
 * - `localCountryId`: Retrieves the country ID from local storage.
 * 
 * **API Calls**:
 * - `fetchAds()`: Fetches advertisement data based on the selected or stored country ID, language ID, platform type, and location. Handles errors by logging them to the console.
 * - `fetchStandings()`: Fetches standings data for a specific sport, country, and tournament. Handles loading state and errors by logging them to the console.
 * 
 * **Effects**:
 * - `useEffect()`: Calls `fetchAds` and `fetchStandings` when the component mounts.
 * 
 * **Styling**:
 * - `StandingsMain`: Styles the main container for the standings section, including maximum width, margin, padding, and responsive design adjustments.
 * 
 * **Usage**:
 * - This component is used in a sports application to display tournament standings and advertisements. It ensures that users see relevant ads and up-to-date standings information.
 */
import React, { useState, useEffect } from 'react';
import { Standing, Breadcrumbs, AdImageCard } from '../../components';
import styled from "styled-components";
import { Container } from '@mantine/core';
import LanguageTag from '../../enums/LanguageTag';
import { useSelector } from 'react-redux';
import NotFound from '../not-found/NotFound';
import { getAds } from '../../services/ads/adsService';
import AdsLocation from '../../enums/AdsLocation';
import { getStandings } from '../../services/standingsService';

const Standings = () => {

  const [adsData, setAdsData] = useState([]);
  const [standingsData, setStandingsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [localCountryId, setLocalCountryId] = useState(localStorage.getItem("startCountryId"))


  const selectedCountryId = useSelector(state => state.filter.countryId);
  const languageId = useSelector((state) => state.filter.languageId);



  const fetchAds = async () => {
    try {      
      const countryId = selectedCountryId === "" ? localCountryId : selectedCountryId;
      const platformType = 1;
      const location = AdsLocation['WEB_LEFT'];
  
      const res = await getAds({ countryId, languageId, platformType, location })
  
      setAdsData(res?.data?.data || {});
    } catch (error) {
      console.log("Ads Error:",error);
    }
  }

  const fetchStandings = async () => {
    try {
      setLoading(true);
      const res = await getStandings({ sportId: "6498b1e91d6341510544c3a7", countryId: "6498b1e91d6341510544c3b0", tournamentId: "64f0411f7bf55f571f120cb4" })
  
      setStandingsData(res?.data?.data || []);
    } catch (error) {
      console.log("Teams Error:", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchAds();
    fetchStandings();
  }, [])

  return (
    <>
      {adsData ? 
        <>
          <AdImageCard adsData={adsData} scrollValue={250} right="0" />
          <AdImageCard adsData={adsData} scrollValue={250} left="0" />
        </> : <NotFound />
      }
      <StandingsMain>   
        <Container>
          <Breadcrumbs color="white"/>
        </Container>
        <Standing standingsData={standingsData} loading={loading} />
      </StandingsMain>
      <div style={{paddingTop: '618px'}} id='media-content'></div>
    </>
  )
}

export default Standings

export const StandingsMain = styled.main`
  max-width: 1200px;
  margin: 0px auto;
  padding-top: 60px;

  @media screen and (min-width: 900px) and (max-width: 1412px) {
    width: 68%
  }
  @media screen and (min-width: 900px) and (max-width: 1200px) {
    width: 60%;
  }
`;