/**
 * LanguageTag is an object that maps unique identifiers (IDs) to language codes.
 * It is used to represent different language options available in the application.
 *
 * - '6498b1e91d6341510544c3b0': Represents the Turkish language with the language code 'tr'
 * - '6498b1e91d6341510544c3b1': Represents the English language with the language code 'en'
 * - '6498b1e91d6341510544c3b2': Represents the Spanish language with the language code 'es'
 *
 * This object allows for easy lookup and identification of language codes based on their unique IDs,
 * facilitating language selection and localization throughout the application.
 */
const LanguageTag = {
    '6498b1e91d6341510544c3b0': 'tr',
    '6498b1e91d6341510544c3b1': 'en',
    '6498b1e91d6341510544c3b2': 'es',
};

export default LanguageTag;