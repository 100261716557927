import { fansupportPrivate } from "../index";
/**
 * Retrieves the user's points from the server.
 *
 * @return {Promise<Object>} The server's response containing the user's points.
 * @throws {Error} If there was an error while retrieving the user's points.
 */
export const getUserPoint = async () => {
    try {
        const response = await fansupportPrivate.get('/payment/user-points');
        return response;
    } catch (error) {
        console.log("getUserPoint",error);
    }
}