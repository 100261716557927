import { fansupportPrivate } from "../index";
/**
 * Retrieves the user details from the server.
 *
 * @return {Promise<Object>} The server's response containing the user details.
 * @throws {Error} If there was an error while retrieving the user details.
 */
export const getUserDetails = async () => {
    try {
        const response = await fansupportPrivate.get('/identity/user-details');
        return response;
    } catch (error) {
        console.log("getUserDetails",error);
    }
}