/**
 * `SmartLink` Component
 * 
 * This component displays a full-screen background with a centered logo, description, and a button. It determines the user's device type and redirects to the appropriate app store or website based on the device.
 * 
 * **Key Functionalities**:
 * - Detects whether the user is on an Android or iOS device using the user agent.
 * - Redirects the user to the corresponding app store or website based on their device type when the button is clicked.
 * - Uses `react-i18next` for translating text content based on the user's language.
 * 
 * **Components and Elements**:
 * - `Container`: Full-screen container with a background image, centered content, and flexible layout.
 * - `Content`: Centered content area containing the logo, description, and button.
 * - `Description`: Displays a text description, styled for readability and centered alignment.
 * - `Logo`: Displays a logo image with a specified width and auto-adjusted height.
 * - `AnimatedButton`: A button styled with a background color, text color, and an animation effect. Changes background color on hover.
 * 
 * **Device Detection**:
 * - Uses regular expressions to detect if the user is on an Android or iOS device.
 * - Redirects to the appropriate store based on device type:
 *   - Android devices: Redirects to Google Play Store.
 *   - iOS devices: Redirects to Apple App Store.
 *   - Other devices: Redirects to a default website.
 * 
 * **Styling**:
 * - `Container` applies a background image and ensures the content is centered vertically and horizontally.
 * - `Content` centers its child elements and sets the text color to white.
 * - `Description` is styled with padding and center alignment for better readability.
 * - `Logo` is sized to fit the container while maintaining aspect ratio.
 * - `AnimatedButton` has a fade-in animation and a hover effect for interactive feedback.
 * 
 * **Usage**:
 * - This component is ideal for landing pages or promotional screens where you want to direct users to download an app based on their device type.
 */
import React from "react";
import styled, { keyframes } from "styled-components";
import background from "../../assets/background.webp";
import { useTranslation } from "react-i18next";

const SmartLink = () => {

  const { t } = useTranslation();
    
  const isAndroid = /Android/i.test(navigator.userAgent);
  const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

  const handleContinueClick = () => {
    let storeLink = "";

    if (isAndroid) {
      storeLink =
        "https://play.google.com/store/apps/details?id=com.fansupport.fansupport_mobile";
    } else if (isIOS) {
      storeLink = "https://apps.apple.com/tr/app/fansupport/id1569960194";
    } else {
      storeLink = 'https://www.taraftardestek.com/';
    }

    window.location.href = storeLink;
  };

  return (
    <Container>
      <Content>  
        <Logo src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/6ef27b40-e393-4ae6-8c5f-0d1442da7100/original" alt="Logo" />
        <Description>{t("shareApp")}</Description>
        <AnimatedButton onClick={handleContinueClick}>{t("cContinue")}</AnimatedButton>
      </Content>
    </Container>
  );
};

export default SmartLink;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  background: url(${background});
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
`;

const Description = styled.p`
  font-size: 1.2em;
  margin-bottom: 24px;
  padding: 0 30px;
  text-align: center;
`;

const Logo = styled.img`
  width: 400px;
  height: auto;
`;

const AnimatedButton = styled.button`
  padding: 12px 24px;
  font-size: 1.2em;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  animation: ${fadeIn} 1s ease;

  &:hover {
    background-color: #45a049;
  }
`;
