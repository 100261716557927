import { createSlice } from '@reduxjs/toolkit';

// Define the error slice to manage global error state in the application
const errorSlice = createSlice({
  // Name of the slice, which will be used as a prefix in the action types
  name: 'error',

  // Initial state of the slice
  initialState: {
    isError: false, // Flag to indicate if an error is present
    errorMessage: '', // Stores the error message
  },
  // Reducers to handle state changes
  reducers: {
    // Action to set an error
    setError: (state, action) => {
      state.isError = true; // Set error flag to true
      state.errorMessage = action.payload; // Set the error message from the action payload
    },
    // Action to clear the error
    clearError: (state) => {
      state.isError = false; // Reset error flag to false
      state.errorMessage = ''; // Clear the error message
    },
  },
});

// Export the actions so they can be dispatched from components or thunks
export const { setError, clearError } = errorSlice.actions;
// Export the reducer to be used in the store configuration
export default errorSlice.reducer;
