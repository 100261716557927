/**
 * AdsLocation is an enumeration for different advertisement placements in the application.
 * It maps specific placement names to numerical values to be used for positioning ads.
 * 
 * - 'MOBILE_TOP': Represents the top position on mobile devices (value: 0)
 * - 'MOBILE_CENTER': Represents the center position on mobile devices (value: 1)
 * - 'MOBILE_BOTTOM': Represents the bottom position on mobile devices (value: 2)
 * - 'WEB_TOP': Represents the top position on web (desktop) view (value: 3)
 * - 'WEB_CENTER': Represents the center position on web (desktop) view (value: 4)
 * - 'WEB_BOTTOM': Represents the bottom position on web (desktop) view (value: 5)
 * - 'WEB_LEFT': Represents the left position on web (desktop) view (value: 6)
 * - 'WEB_RIGHT': Represents the right position on web (desktop) view (value: 7)
 * 
 * This enumeration can be used to manage ad placements consistently across the application.
 */
const AdsLocation = {
    'MOBILE_TOP': 0,
    'MOBILE_CENTER': 1,
    'MOBILE_BOTTOM': 2,
    'WEB_TOP': 3,
    'WEB_CENTER': 4,
    'WEB_BOTTOM': 5,
    'WEB_LEFT': 6,
    'WEB_RIGHT': 7 
}

export default AdsLocation;