/* This code is a React component called `CampaignsUserJoinedCard` that displays a list of campaigns
that a user has joined. It fetches data about the campaigns from an API using
`getCampaignsUserJoined` and displays information such as campaign type, status, amount, point, and
certificate type. */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled, { keyframes } from "styled-components";
import { CurrencyFormat, JoinButton, Loader, ShareButtons } from "../../components";
import { getCampaignsUserJoined } from "../../services/campaign/campaignsUserJoinedService";
import fadedMedallion from "../../assets/certificates/faded_medallion.webp";
import { FaHourglassEnd } from "react-icons/fa";
import { AiFillCloseCircle } from "react-icons/ai";
import { BsFillCheckCircleFill, BsChevronRight } from "react-icons/bs";
import { MdDownload } from "react-icons/md";
import { NotFound } from "../../pages";
import { useDisclosure } from "@mantine/hooks";
import { Modal } from "@mantine/core";
import { getCertificate } from "../../services/document/certificateService";
import coin from "../../assets/coins-renkli.webp";
import { Loaders } from "../loader/Loader";
import { useLocation } from "react-router-dom";
import RemoveTurkishChars from "../../logic/RemoveTurkishChars";

const zoomIn = keyframes`
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
`;

const CampaignTypes = {
  0: "transferCampaigns",
  1: "eventCampaign",
  2: "championship",
  3: "SCORE",
  4: "UNCONDITIONAL",
  5: "ROUND",
  6: "SOCIAL",
};

// const CampaignId = {
//   TRANSFER: 0,
//   EVENT: 1,
//   CHAMPIONSHIP: 2,
//   SCORE: 3,
//   UNCONDITIONAL: 4,
//   ROUND: 5,
//   SOCIAL: 6,
// };

const statusTypes = {
  0: "created",
  1: "published",
  2: "success",
  3: "failure",
  4: "completed",
  5: "fransferToTeam",
  6: "backToUser",
};

const certificatesTypes = {
  0: "No",
  1: "SILVER",
  2: "GOLD",
  3: "PLATINUM",
};

const certificateImages = {
  No: fadedMedallion,
  SILVER: "https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/b7fd38a7-3fda-4bcd-71e3-2cd669375300/original",
  GOLD: "https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/12126cd9-dbbe-4b9e-376c-f9d2b18de000/original",
  PLATINUM: "https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/cce3f264-7daf-4a89-0cf8-8d469bbc4f00/original",
};

const CampaignsUserJoinedCard = () => {

  const [userJoinedData, setUserJoinedData] = useState([]);
  const [userJoinedCampaignId, setUserJoinedCampaignId] = useState(null);
  const [certificateData, setCertificateData] = useState([]);
  const [opened, { open, close }] = useDisclosure(false);
  const [downloadFormat, setDownloadFormat] = useState(0);
  const [downloadForm, setDownloadForm] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const symbol = '₺';

  const { t } = useTranslation();
  const location = useLocation();

  const title = 'TaraftarDestek Sertifika';

  const fetchCertificate = async (id) => {
    try {
      setLoading(true);

      const campaignId = id;
      const size = null;
      const format = null;
  
      const res = await getCertificate({ campaignId, size, format});
  
      setCertificateData(res?.data?.data || []);
      
    } catch (error) {
      console.log("certificates Error",error);
    } 
  }

  // console.log("certificeteData",certificateData);

  const imgUrl = `data:image/png;base64, ${certificateData}`;

  const fetchCampaignsUserJoined = async () => {
    try {
      const page = 1;
      const perPage = 25;
  
      const res = await getCampaignsUserJoined({ page, perPage });

      setUserJoinedData(res?.data?.data?.data || []);
    } catch (error) {
      console.log("fetchCampaignsUserJoined:",error);
    } 
    finally {
      setIsLoading(false);
    }
  };
  // console.log("userJoinedData", userJoinedData);
  
  const handleJoinClick = (campaign) => {
    if (campaign.id) {
      // const sportTypeSlug = campaignData.detail.sportName.replace(/ /g, "-").toLowerCase();
      //   const leagueTypeSlug = campaign.tournamentName.replace(/ /g, "-").toLowerCase();
      const campaignSlug = campaign.title.replace(/ /g, "-").toLowerCase();

      //   const url  = `/football/event/${leagueTypeSlug}/${campaignSlug}/${campaign.id}`;
      const url = `/football/event/tournamentName/${campaignSlug}/${campaign.id}`;

      window.location.href = url;
    } else {
      return <NotFound />;
      // console.log("Geçersiz campaignId, sayfaya gidilemiyor.");
    }
  };
  
  const handleCertificateClick = (id) => {
    setUserJoinedCampaignId(id)
    fetchCertificate(id);
    setLoading(false);
    open();
  };

  const handleDownload = async () => {
    if (downloadFormat !== 0 && downloadFormat !== 1 && downloadFormat !== 2) {
      console.error("Invalid download format");
    }
    if(downloadForm !== 0 && downloadForm !== 1 && downloadForm !== 2 && downloadForm !== 3 ){
      console.error("Invalid download size");
    }

    try {
      const campaignId = userJoinedCampaignId;
      const size = downloadForm;
      const format = downloadFormat;

      const res = await getCertificate({ campaignId, size, format });

      if (res?.data?.data) {
        const downloadLink = document.createElement("a");
        downloadLink.href = `data:image/${getFormatExtension(downloadFormat)};base64, ${res.data.data}`;
        downloadLink.download = `certificate.${getFormatExtension(downloadFormat)}`;
        downloadLink.click();
      } else {
        console.error("Error downloading certificate");
      }
    } catch (error) {
      console.error("Error downloading certificate", error);
    }
  };

  const getFormatExtension = (format) => {
    switch (format) {
      case 0:
        return "jpg";
      case 1:
        return "png";
      case 2:
        return "pdf";
      default:
        return "";
    }
  };

  const getNotFoundMessage = () => {
    const pathSegments = location.pathname.split('/');
    const lastSegment = pathSegments[pathSegments.length - 1];
    const decodedLastSegment = decodeURIComponent(lastSegment);
    const lastSegmentWithoutTurkishChars = RemoveTurkishChars({ text: decodedLastSegment });
  
    if (lastSegmentWithoutTurkishChars === RemoveTurkishChars({ text: t("myJoinedCampaigns") })) {
      return t("notFoundCampaigns");
    } else if (lastSegmentWithoutTurkishChars === RemoveTurkishChars({ text: t("userScore") })) {
      return t("notFoundUserScore");
    }
    return t("notFound");
  };
  
  useEffect(() => {
    fetchCampaignsUserJoined();
  }, [downloadForm,downloadFormat]);

  return (
    <>
      {isLoading ? (
        <Loaders />
      ) : userJoinedData && userJoinedData.length === 0 ? (
        <NotFound text={getNotFoundMessage()} />
      ) : (
        userJoinedData.map((campaign) => (
          <Card key={campaign.id}>
            <CardTitle status={campaign.status}>
              <CardTitleTop>{t(CampaignTypes[campaign.type])}</CardTitleTop>
  
              <CardTitleBottom>
                {campaign.status === 0 || campaign.status === 1 ? (
                  <>
                    <FaHourglassEnd size={35} color="white" />
                  </>
                ) : campaign.status === 2 || campaign.status === 4 || campaign.status === 5 ? (
                  <>
                    <BsFillCheckCircleFill size={35} color="white" />
                  </>
                ) : (
                  <>
                    <AiFillCloseCircle size={35} color="white" />
                  </>
                )}
              </CardTitleBottom>
            </CardTitle>
            <div>
              <CardContent>
                <CardContentLeft>
                  {campaign.certificateType === 0 ? (
                    <>
                      <CertificateFoundTitle>{campaign.description}</CertificateFoundTitle>
                      <CardContentTopRight>
                        <Certificateİmage
                          src={
                            certificateImages[
                              certificatesTypes[campaign.certificateType]
                            ]
                          }
                          alt=""
                        />
                        <NoCertificateText>
                          {t("noCertificateFound")}
                        </NoCertificateText>
                      </CardContentTopRight>
                    </>
                  ) : (
                    <>
                      <ShareButtonsWrapper>
                        <ShareButtons url={campaign.certificateUrl} title={title} />
                      </ShareButtonsWrapper>
                      <CardContentLeftError>                        
                        <CardContentLeftWrapper>
                          <Certificateİmage
                            src={
                              certificateImages[
                                certificatesTypes[campaign.certificateType]
                              ]
                            }
                            alt=""
                          />
                          <ShareButtonsContainer>   
                            <CardDescription>{campaign.description}</CardDescription>
                            <div onClick={() => handleCertificateClick(campaign.id)}>
                              <JoinButton width="100px" textColor="white">
                                {t("certificate")}
                              </JoinButton>
                            </div>
                          </ShareButtonsContainer>
                        </CardContentLeftWrapper>
                      </CardContentLeftError>
                    </>
                  )}
                </CardContentLeft>
                <CardContentRight>
                  <CardContentRightContainer>

                    <CampaignsBoxAmountTd>
                      <CampaignsBoxAmountSpan>
                        {t("campaignAmount")}
                      </CampaignsBoxAmountSpan>
                      <CardTypeTitle>{t("status")}</CardTypeTitle>
                      <Label>{t("userScore")}</Label>
                    </CampaignsBoxAmountTd>

                    <CampaignsBoxAmountTr>
                      <CampaignsBoxAmount symbol={symbol}>
                        <CurrencyFormat amount={campaign.amount || 0} />
                      </CampaignsBoxAmount> 
                      <span>{t(statusTypes[campaign.status])}</span> 
                      <AccountPointWrapper>
                        <CoinIcon src={coin} alt="coin" />   
                        <Value><CurrencyFormat amount={campaign.point || 0} /></Value>
                      </AccountPointWrapper>
                    </CampaignsBoxAmountTr>
                  
                  </CardContentRightContainer>
                </CardContentRight>
              </CardContent>
              <CardBottom>
                <div onClick={() => handleJoinClick(campaign)}>
                  <JoinButton width="35px" backgroundColor="transparent" textColor="black">
                    <BsChevronRight size={20} />
                  </JoinButton>
                </div>
              </CardBottom>
            </div>
          </Card>
        ))
      )}
      <CampaignsUserJoinedModal>
        <Modal
          opened={opened}
          onClose={() => {
            close();
          }}
          title={t("myCertificates")}
          centered
          transitionProps={{ transition: "fade", duration: 200 }}
          overlayProps={{
            backgroundOpacity: 0,
            blur: 3,
          }}
        >
          <ModalContent>
            {loading ? <Loader /> : <ModalCertificateImage src={imgUrl} alt="" />}            
          </ModalContent>
          <ModalButtonContainer>
            <DropdownContainer>
              <DropdownSelect onChange={(e) => setDownloadForm(Number(e.target.value))}>
                <DefaultOption>{t("chooseSize")}</DefaultOption>
                <DropdownSelectOption value={0}>INSTAGRAM POST</DropdownSelectOption>
                <DropdownSelectOption value={1}>INSTAGRAM STORY</DropdownSelectOption>
                <DropdownSelectOption value={2}>A4 VERTICAL</DropdownSelectOption>
                <DropdownSelectOption value={3}>A4 HORIZONTAL</DropdownSelectOption>
              </DropdownSelect>
            </DropdownContainer>
            <DropdownContainer>
              <DropdownSelect onChange={(e) => setDownloadFormat(Number(e.target.value))}>
                <DefaultOption>{t("selectFormat")}</DefaultOption>
                <DropdownSelectOption value={0}>JPG</DropdownSelectOption>
                <DropdownSelectOption value={1}>PNG</DropdownSelectOption>
              </DropdownSelect>
            </DropdownContainer>
            <ModalDownloadButton onClick={handleDownload}>
              <DownloadButtonContent>
                <MdDownload size={16} />
                <div>{t("download")}</div>    
              </DownloadButtonContent>
            </ModalDownloadButton>
          </ModalButtonContainer>
        </Modal>
      </CampaignsUserJoinedModal>
    </>
  );
  
};

export default CampaignsUserJoinedCard;

export const CampaignsUserJoinedModal = styled.div`
  display: inline-block;
  position: relative;
`;

export const Card = styled.div`
  max-width: 330px;
  width: 330px;
  margin: 0 auto;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 30px 30px 0 30px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  animation: ${zoomIn} 0.9s ease-in-out;
`;

export const CardTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: white;
  font-size: 18px;
  border-radius: 30px 30px 0 0;
  padding: 5px 20px;

  ${(props) =>
    props.status === 0 &&
    `
        background-color: orange;
    `}

  ${(props) =>
    props.status === 1 &&
    `
        background-color: orange;
    `}

    ${(props) =>
    props.status === 2 &&
    `
        background-color: green;
    `}
    ${(props) =>
    props.status === 3 &&
    `
        background-color: red;
    `}
    ${(props) =>
    props.status === 4 &&
    `
        background: var(--bg-navbar);
    `}
    ${(props) =>
    props.status === 5 &&
    `
        background-color: green;
    `}
    ${(props) =>
    props.status === 6 &&
    `
        background-color: red;
    `}
`;

export const CardDescription = styled.p`
  text-align: center;
  font-weight: bold;
  width: 192px;
`;

export const CardType = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  height: 65px;
`;

export const CampaignsBoxAmountTd = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  width: 100%;
  margin-bottom: 10px;
  text-align: center;
`;
export const CampaignsBoxAmountSpan = styled.div`
  font-weight: bold;
  width: 102px;
  text-align: center;
  border-bottom: 2px solid black;
`;
export const CampaignsBoxAmount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #555;
  width: 102px;

  ::after {
    content: "${(props) => props.symbol}";
    margin-left: 8px;
    color: #4caf50;
    font-size: 16px;
  }
`;

export const CardContent = styled.div`
  padding: 5px 20px 20px 20px;
`;
export const CardContentLeft = styled.div`
  position: relative;
`;
export const CardContentLeftError = styled.div`
  display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const CardContentTopRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;
export const CardContentLeftWrapper = styled.div`
  display: flex;
`;

export const CardContentRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 17px;
  width: 100%;
  font-size: 14px;
  margin-top: 20px;
`;
export const CardContentRightContainer = styled.div`
  width: 100%;
`;

export const Certificateİmage = styled.img`
  width: 120px;
  height: 120px;
`;
export const CardTitleTop = styled.div``;
export const CardTitleBottom = styled.div``;
export const CardBottom = styled.div`
  display: none;
  // display: flex;
  // justify-content: end;
  // align-items: center;
`;

export const NoCertificateText = styled.div`
  font-size: 13px;
  text-align: center;
  max-width: 120px;
`;

export const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 15px;
`;

export const ModalDownloadButton = styled.button`
  background-color: #28a745;
  color: #fff;
  padding: 10px 15px;
  font-size: 14px;
  border: none;

  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #218838;
  }
`;

export const DownloadButtonContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export const ModalCertificateImage = styled.img`
  width: auto;
  height: 530px;
`;

export const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const DropdownSelect = styled.select`
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 5px;
  appearance: none;
  cursor: pointer;
`;

export const DefaultOption = styled.option`
  display: none;
  color: #999;
`;

export const DropdownSelectOption = styled.option`
  text-align: center;
  width: 20px;
`;
export const ShareButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;
export const Label = styled.div`
  font-weight: bold;
  border-bottom: 2px solid black;
  width: 98px;
`;
export const Value = styled.p`
  font-size: 13px;
  margin: 0;
  color:grey;
`;
export const AccountPointWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 102px;
`;
export const CoinIcon = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 5px;
`;
export const CardTypeTitle = styled.span`
  font-weight: bold;
  border-bottom: 2px solid black;
`;
export const CampaignsBoxAmountTr = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`;
export const ShareButtonsWrapper = styled.div`
  position: absolute;
  top: 0;
  left: -15px;
`;
export const CertificateFoundTitle = styled.div`
  text-align: center;
  font-weight: bold;
  margin: 5px 0;
`;