/* This code defines a React functional component called `CampaignNumbersCard` that displays campaign
numbers in a styled card format. */
import React from 'react';
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import { CurrencyData, CurrencyFormat } from '../../components';
import { Skeleton } from '@mantine/core';

const CampaignNumbersCard = (props) => {
// console.log("props",props);

  const { title, campaignNumbers, loading } = props;

  const symbol = '₺';

  const { t } = useTranslation();

  const { 
    flexContainer, 
    flexDirection, 
    alignItems, 
    containerHeight, 
    marginRight, 
    mediaMarginRight,
    contentWidth,
    gap 
  } = props; 
  
  const {
    amount,
    campaignCount,
    participantCount,
    totalCampaignCount
  } = campaignNumbers;

  if (loading) {
    return (
      <NumbersCardContainer className='numbers-card' flexContainer={flexContainer} flexDirection={flexDirection} alignItems={alignItems} containerHeight={containerHeight} marginRight={marginRight} mediaMarginRight={mediaMarginRight} gap={gap}>
        <NumbersCardTitle>
          <Skeleton height={30} width={200}  />
        </NumbersCardTitle>
        <NumbersCardContent contentWidth={contentWidth}>
          <Skeleton height={30} mb="xl" />
          <Skeleton height={30} mb="xl" ml="sm" />
          <Skeleton height={30} mb="xl" />
          <Skeleton height={30} mb="xl" ml="sm" />
        </NumbersCardContent>
      </NumbersCardContainer>
    );
  }

  return (
    <>
      <NumbersCardContainer className='numbers-card' flexContainer={flexContainer} flexDirection={flexDirection} alignItems={alignItems} containerHeight={containerHeight} marginRight={marginRight} mediaMarginRight={mediaMarginRight} gap={gap}>
        <NumbersCardTitle>
          <FootballIcon src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/d37bf258-a3ef-453d-bb1f-90bbaa846300/original" alt=''/>
          <NumbersCardSpan className='numbers-card'>{title}</NumbersCardSpan>
        </NumbersCardTitle>
        <NumbersCardContent contentWidth={contentWidth}>
          <SquareCardItem className='numbers-square-card-item'>
            <SquareCardItemTitle>{t("totalAmount")}</SquareCardItemTitle>
            <SquareCardItemAmountSymbol symbol={symbol}>
            <CurrencyFormat amount={amount} />
              {/* {amount && parseFloat(amount).toLocaleString()} */}
              </SquareCardItemAmountSymbol>
          </SquareCardItem>
          <SquareCardItemSecond className='numbers-square-card-item-second'>               
            <SquareCardItemTitle>{t("campaignCount")}</SquareCardItemTitle>
            <SquareCardItemAmount>{campaignCount && parseFloat(campaignCount).toLocaleString()}</SquareCardItemAmount>
          </SquareCardItemSecond>
          <SquareCardIteThird className='numbers-square-card-item-third'>
            <SquareCardItemTitle>{t("totalParticipants")}</SquareCardItemTitle>
            <SquareCardItemAmount>{participantCount && parseFloat(participantCount).toLocaleString()}</SquareCardItemAmount>
          </SquareCardIteThird> 
          <SquareCardItemFourth>
          <SquareCardItemTitle>{t("totalCampaignCount")}</SquareCardItemTitle>
          <SquareCardItemAmount>{totalCampaignCount && parseFloat(totalCampaignCount).toLocaleString()}</SquareCardItemAmount>
          </SquareCardItemFourth>
        </NumbersCardContent>
        </NumbersCardContainer>
    </>
  )
}

export default CampaignNumbersCard

export const NumbersCardContainer = styled.div`
  display: ${(props) => (props.flexContainer ? 'flex' : 'block')};
  flex-direction: ${(props) => (props.flexDirection ? props.flexDirection : 'initial')};
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'initial')};
  height: ${(props) => (props.containerHeight ? props.containerHeight : '150px')};
  gap: ${(props) => (props.gap ? props.gap : '30px')};
  border-radius: 5px;
  padding: 20px;
  margin-right: ${props => props.marginRight || '20px'};
  background-color: white;
  border: 1px solid #dbe0e5;

  &:hover {
    box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16); 
  }

  @media (max-width: 720px) {
    margin-right: ${props => props.mediaMarginRight || '0px'};
    width: auto;
    padding: 20px 0;
  }
`;

export const NumbersCardTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
`;

export const NumbersCardSpan = styled.span`
  text-align: center;
  color: black;
`;

export const FootballIcon = styled.img`
  width: 25px;
  height: 25px;
`;

export const NumbersCardContent = styled.div`
  width: ${props => props.contentWidth || '335px'};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  font-size: 11px;
`;

export const SquareCardItem = styled.div`
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 60px;
`;
export const SquareCardItemSecond = styled.div`
  border-bottom: 1px solid #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 60px;
`;
export const SquareCardIteThird = styled.div`
  border-right: 1px solid #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 60px;
`;
export const SquareCardItemFourth = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 60px;
`;

export const SquareCardItemTitle = styled.div`
  display: flex;
  align-items: center;
  height: 35.2px;
  text-align: center;
`;
export const SquareCardItemAmount = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
`;
export const SquareCardItemAmountSymbol = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;

  ::after {
    content: "${(props) => props.symbol}";
    margin-left: 8px;
    color: #4caf50;
    font-size: 16px;
  }
`;