/* This code snippet is a React component called `CampaignOrganisationsCard` that displays a list of
organisations with their respective campaigns in an accordion format. Here's a breakdown of what the
code is doing: */
import React, { useState } from 'react';
import styled from 'styled-components';
import { JoinButton, CurrencyFormat } from "../../components";
import { useTranslation } from "react-i18next";
import { NotFound } from "../../pages";
import RemoveTurkishChars from '../../logic/RemoveTurkishChars';
import { getStartLanguage } from '../../logic/getStartLanguage';
import { Accordion } from '@mantine/core';
import { getCampaign } from '../../services/campaign/campaignService';


const CampaignOrganisationsCard = ({organisationsData, social: initialSocial}) => {

  const [social, setSocial] = useState(initialSocial);
  const [activeOrganisationId, setActiveOrganisationId] = useState(null); 

    const symbol = '₺';

    const { t } = useTranslation();
    const startLanguage = getStartLanguage();

    const handleJoinClick = async (id,title) => {
      
      if (id) {
        
        const campaignSlug = title.replace(/ /g, "-").toLowerCase(); 

        const url  = `/${startLanguage}/${RemoveTurkishChars({ text: t("social") })}/${campaignSlug}/${id}`;
        
        window.location.href = url;
    
      } else  {
        return <NotFound />
      }

    }

    const handleAccordionClick = async (orgId) => {
      try {
        setActiveOrganisationId(orgId);
  
        const campaignType = 6;
        const organisationId = orgId;

        const response = await getCampaign({ campaignType, organisationId })
  
        setSocial(response.data?.data?.data);
      } catch (error) {
        console.error("Error fetching campaign data:", error);
      }
    }

    const items = Array.isArray(organisationsData) ? organisationsData.map((item) => (
      <Accordion.Item key={item.name} value={item.name} onClick={() => handleAccordionClick(item.id)}>
          <Accordion.Control className='campaign-card-content-organization'>
            <AccordionControlContainer className='campaign-card-content-organization'>
              <LogoFirstImage src={item.logoUrlLarge ? item.logoUrlLarge : "https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/6f6a87c5-fd75-45c3-0f8c-33977a168700/original"} alt="" />
              {item.name}
            </AccordionControlContainer>

            </Accordion.Control>

            <AccordionPanel className='campaign-card-content-organization-sub'>

              {activeOrganisationId === item.id && social && social.map((list) => (
                <CampaignsBox key={list.id}>
                  <CampaignsBoxContent>
                    <LogoBoxImage src={list.detail.logoUrlLarge ? list.detail.logoUrlLarge : "https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/6f6a87c5-fd75-45c3-0f8c-33977a168700/original"} alt="" />
                    <CampaignsBoxDesc>
                      <SocialTitle>{list.title}</SocialTitle>
                      <CampaignsBoxCenter>
                        <CampaignsBoxAmount symbol={symbol}>
                          <CurrencyFormat amount={list?.detail?.homeAmount || 0} />                  
                        </CampaignsBoxAmount>
                        <ParticipantContainer>
                          <Participant>{list?.participantCount}</Participant>
                          {t("participant")}
                        </ParticipantContainer>    
                      </CampaignsBoxCenter>
                    </CampaignsBoxDesc>
                  </CampaignsBoxContent>
                  <div onClick={() => handleJoinClick(list?.id, list?.title)}>
                    <JoinButton>{t("join")}</JoinButton>
                  </div>
                </CampaignsBox>
              ))}

            </AccordionPanel>
      </Accordion.Item>
    )) : null;

  return (
    <>
      <OrganisationsDataContainer>
        

        <Accordion defaultValue="">
          {items}
        </Accordion>
      </OrganisationsDataContainer>
    </>
  )
}

export default CampaignOrganisationsCard

export const OrganisationsDataContainer = styled.div`
  padding: 10px 0;
`;
export const OrganisationsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  padding: 10px;
  border: 2px solid grey;
  border-radius: 15px;
`;

export const CampaignsBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0px;
  border-bottom: 3px inset lightgrey;
`;

export const CampaignsBoxContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px; 
`;

export const LogoFirstImage = styled.img`
  width: 40px;
  height: 40px;
`;
export const LogoBoxImage = styled.img`
  width: 30px;
  height: 30px;
`;
export const CampaignsBoxDesc = styled.div`
  width: 215px;
  height: 60px;
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 720px) {
    width: 140px;
  }
`;

export const CampaignsBoxAmount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #555;
  font-size: 12px;

  ::after {
    content: "${(props) => props.symbol}";
    margin-left: 8px;
    color: #4caf50;
    font-size: 16px;
  }
`;

export const CampaignsBoxCenter = styled.div`
  display: flex;
  margin-top: 10px;
`;

export const ParticipantContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  margin-left: 15px;
  border: 1px solid #80808030;
  border-radius: 10px;
  padding: 4px;
  background-color: #80808030;
  // background-color: var(--bg-navbar);
  color: black;
  font-size: 10px;
`;

export const Participant = styled.span`

`;

export const SocialTitle = styled.div`
  font-size: 13px;
  font-weight: 400;
`;
export const AccordionControlContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  font-size: 17px;
`;

export const AccordionPanel = styled(Accordion.Panel)`
  background-color: #F5F5F5;
`;