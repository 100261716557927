import { fansupportPrivate } from "../index";
/**
 * Sends a POST request to the '/campaign/like-dislike' endpoint with the provided campaignId.
 *
 * @async
 * @function
 * @name postLikeDisLikeCampaign
 * @param {string} campaignId - The ID of the campaign to like or dislike.
 * @return {Promise<Object>} A Promise that resolves to the response data from the server.
 * @throws {Error} If an error occurs during the request.
 */
export const postLikeDisLikeCampaign = async ( campaignId ) => {
    try {
        const data =  fansupportPrivate.post('/campaign/like-dislike', {
            campaignId: campaignId,
    })
        return data;

    } catch (error) {
        console.log("postCampaignError",error);
    }
}