import { fansupportPrivate } from "../index";
/**
 * This function is used to join a campaign by sending a POST request to the '/campaign/join' endpoint of the API.
 * It takes three parameters: campaignId (the ID of the campaign to join), joinId (the ID of the join to join), and amount (the amount to donate).
 * It returns a Promise that resolves to the response data from the API. If an error occurs during the request, it will be logged to the console.
 *
 * @param {number} campaignId - The ID of the campaign to join.
 * @param {number} joinId - The ID of the join to join.
 * @param {number} amount - The amount to donate.
 * @return {Promise<Object>} A Promise that resolves to the response data from the API.
 */
export const postCampaign = async ( campaignId, joinId, amount ) => {
    try {
        const data =  fansupportPrivate.post('/campaign/join', {
            campaignId: campaignId,
            joinId: joinId,
            amount: amount
    })
        return data;

    } catch (error) {
        console.log("postCampaignError",error);
    }
}