/**
 * CampaignType is an enumeration that maps numerical values to campaign type names.
 * It is used to represent different types of campaigns in the application.
 * 
 * - 0: Represents a 'transfer' campaign
 * - 1: Represents an 'event' campaign
 * - 2: Represents a 'championship' campaign
 * - 3: Represents a 'score' campaign
 * - 4: Represents an 'unconditional' campaign
 * - 5: Represents a 'round' campaign
 * - 6: Represents a 'social' campaign
 * 
 * CampaignTypes provides a more readable and descriptive mapping for the campaign type names
 * to their corresponding numerical values, making it easier to use these values in code.
 * 
 * - TRANSFER: Numerical value for 'transfer' campaign type (value: 0)
 * - EVENT: Numerical value for 'event' campaign type (value: 1)
 * - CHAMPIONSHIP: Numerical value for 'championship' campaign type (value: 2)
 * - SCORE: Numerical value for 'score' campaign type (value: 3)
 * - UNCONDITIONAL: Numerical value for 'unconditional' campaign type (value: 4)
 * - ROUND: Numerical value for 'round' campaign type (value: 5)
 * - SOCIAL: Numerical value for 'social' campaign type (value: 6)
 * 
 * Both objects serve to manage and identify campaign types consistently across the application.
 */
const CampaignType = {
    0: 'transfer',
    1: 'event',
    2: 'championship',
    3: 'score',
    4: 'unconditional',
    5: 'round',
    6: 'social',
};

export const CampaignTypes = {
    TRANSFER: 0,
    EVENT: 1,
    CHAMPIONSHIP: 2,
    SCORE: 3,
    UNCONDITIONAL: 4,
    ROUND: 5,
    SOCIAL: 6,
};

export default CampaignType;
