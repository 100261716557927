/**
 * getStartLanguage determines the language to be used in the application.
 *
 * It first retrieves the user's preferred language from the browser's settings using `navigator.language`.
 * The language code is then extracted by splitting the string at the hyphen and taking the first part (e.g., 'en' from 'en-US').
 * The function also checks a hardcoded `storedLanguage` value (in this case, 'tr' for Turkish) which can be used to override
 * the browser's language settings. If a stored language is specified, it is returned; otherwise, the user's browser language is returned.
 *
 * This function ensures that the application uses a consistent language setting, either from a predefined value or based on the user's browser settings.
 *
 * @returns {string} - The language code to be used in the application.
 */
export const getStartLanguage = () => {
    const originalLanguage = navigator.language;
    const newLanguage = originalLanguage.split('-')[0];
    const storedLanguage = "tr";
    return storedLanguage ? storedLanguage : newLanguage;
};
  