import { fansupportPrivate } from "../index";
/**
 * Asynchronously retrieves a list of campaigns that the user has joined. 
 * 
 * @async
 * @function
 * @name getCampaignsUserJoined
 * @param {Object} options - An object containing the following properties:
 * @param {number} options.page - The page number of campaigns to fetch.
 * @param {number} options.perPage - The number of campaigns to fetch per page.
 * @return {Promise<Object>} A Promise that resolves to campaign data in JSON format.
 * @throws {Error} If an error occurs during the request.
 */
export const getCampaignsUserJoined = async ({ page, perPage }) => {
    try {
        const data = fansupportPrivate.get('/campaign/user-joined-campaigns', {params: {
            page,
            perPage,
        }})
        return data;
    } catch (error) {
        console.log("getCampaignsUserJoined",error)
    }

}