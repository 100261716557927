/**
 * The JoinButton component is a styled button in React that allows customization of various styling
 * properties and hover effects.
 */
import React from 'react';
import styled from 'styled-components';

const JoinButton = ({ children, width, mediaWidth, height, padding, border, borderRadius, backgroundColor, textColor, fontSize, hoverBgColor, hoverColor }) => {

  const defaultWidth = '80px';
  const defaultMediaWidth = '70px';
  const defaultHeight = '35px';
  const defaultPadding = '5px';
  const defaultBorder = 'none';
  const defaultBorderRadius = '3px';
  const defaultBackgroundColor = 'linear-gradient(to right, #1b8a2f 0, #31ac47 100%)';
  const defaultTextColor = 'white';
  const defaultfontSize = '14px';
  const defaultBgHover = 'linear-gradient(to right, #1b8a2f 0, green 100%)';

  return (
    <>
      <Button
        width={width || defaultWidth}
        mediaWidth={mediaWidth || defaultMediaWidth}
        height={height || defaultHeight}
        padding={padding || defaultPadding}
        border={border || defaultBorder}
        borderRadius={borderRadius || defaultBorderRadius}
        backgroundColor={backgroundColor || defaultBackgroundColor}
        textColor={textColor || defaultTextColor}
        fontSize={fontSize || defaultfontSize} 
        hoverBgColor={hoverBgColor || defaultBgHover}
        hoverColor={hoverColor}
      >
        {children}
      </Button>
    </>
  )
}

export default JoinButton

export const Button = styled.button`
  width: ${props => props.width};
  height: ${props => props.height};
  padding: ${props => props.padding};
  border: ${props => props.border};
  border-radius: ${props => props.borderRadius};
  background: ${props => props.backgroundColor};
  color: ${props => props.textColor};
  font-size: ${props => props.fontSize}; 
  cursor: pointer;

  &:hover{
    background: ${props => props.hoverBgColor}
    color: ${props => props.hoverColor}
  }

  @media screen and (min-width: 1024px) and (max-width: 1230px) {
    width: ${props => props.mediaWidth};
  }
`;