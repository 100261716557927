/* This code snippet is a React component called `AdsCard` that displays a carousel of ads. Here's a
breakdown of what the code is doing: */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { Link } from "react-router-dom";

const AdsCard = (props) => {

  const { 
    ads,
    adsHeight
  } = props;

  const [currentAdIndex, setCurrentAdIndex] = useState(0);


  const intervalValue = ads.length > 0 ? (ads[0].switchInterval || 3) : 3;

  // console.log("ads",ads);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentAdIndex((prevIndex) => (prevIndex + 1) % ads.length);
    }, intervalValue * 1000);
    return () => clearInterval(interval);
  }, [ads.length]);

  return (
    <AdImageContainer adsHeight={adsHeight}>
      {ads && ads.length > 0 ?
      ads?.map((ad, index) => (
        <AdImageLink key={index} to={ad.url} target="_blank" rel="noopener noreferrer">
          <Adİmage
            key={index}
            src={ad.image}
            alt={``}
            isDisplayed={index === currentAdIndex}
            adsHeight={adsHeight}
          />
        </AdImageLink>
      )) : 
      ""
      }
    </AdImageContainer>
  )
}

export default AdsCard

export const AdImageContainer = styled.div`
  height: ${props => props.adsHeight || '150px'};
`;

export const Adİmage = styled.img`
  max-width: 1060px;
  height: ${props => props.adsHeight || '150px'};
  display: ${({ isDisplayed }) => (isDisplayed ? 'block' : 'none')};
  transition: opacity 0.5s ease-in-out;
  border-radius: 10px;

  @media (max-width: 900px) {
    display: none;
  }

  @media screen and (min-width: 900px) and (max-width: 1024px) {
    max-width: 600px;
    margin: 30px auto;
  }
`;

const AdImageLink = styled(Link)``;