/*
This React component, `HelpLayout`, serves as a layout template for the "Help" section of the application.
It wraps the main content of the page with a navigation bar and provides a placeholder for nested routes using React Router's `Outlet`.
Styled-components are utilized to apply specific styling to the navigation bar container.

- `NavbarAboutUs` is a component that represents the navigation bar, which is placed inside a styled wrapper (`NavbarContainerWrapper`) to apply top padding.
- `Outlet` is used to render the nested child routes within this layout, allowing for dynamic content to be displayed based on the current route.
- `NavbarContainerWrapper` is a styled `div` that adds a top padding of 60px to ensure the navigation bar is properly spaced from the top of the page.
*/
import { Outlet } from "react-router-dom";
import { NavbarAboutUs } from "../../components";
import styled from "styled-components";

const HelpLayout = () => {
  return (
    <>
    <NavbarContainerWrapper>
      <NavbarAboutUs/>
    </NavbarContainerWrapper>
    <Outlet/>
    </>
  )
}

export default HelpLayout

export const NavbarContainerWrapper = styled.div`
  padding-top: 60px;
`;