/**
 * This code defines an `SEO` component using React and `react-helmet`.
 * The `SEO` component is designed to manage the metadata for a webpage, 
 * including the page title, description, and keywords, as well as Open Graph 
 * and Twitter meta tags for better social media sharing and SEO optimization.
 * By using this component, you can dynamically set meta tags based on the content 
 * of each page, improving search engine rankings and social media visibility.
 */
import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ title, description, keywords, url }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />

      {/* Open Graph Meta Tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/6ef27b40-e393-4ae6-8c5f-0d1442da7100/original" />
      <meta property="og:url" content={url} />

      {/* Twitter Meta Tags */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/6ef27b40-e393-4ae6-8c5f-0d1442da7100/original" />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
};

export default SEO;
