/* This code snippet is a React component called `Carousels` that creates a carousel/slider component
using the Mantine library. Here's a breakdown of what the code is doing: */
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Autoplay from 'embla-carousel-autoplay';
import { Carousel } from '@mantine/carousel';
import { Image, rem } from '@mantine/core';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { getSlider } from '../../services/slider/sliderService';
import { getCountry } from '../../services/countryService';


const languageTag = {
  '6498b1e91d6341510544c3b0': 'tr',
  '6498b1e91d6341510544c3b1': 'en',
  '6498b1e91d6341510544c3b2': 'es',
};

 
const Carousels = () => {

  const [sliderData, setSliderData] = useState([]);
  const [countryData, setCountryData] = useState([]);

  const autoplay = useRef(Autoplay({ delay: 2000 }));
  const selectedSportId = useSelector(state => state.filter.sportId);
  const selectedCountryId = useSelector(state => state.filter.countryId);
  const selectedTournamentId = useSelector(state => state.filter.tournamentId);
  const selectedTeamId = useSelector((state) => state.filter.teamId);
  const languageId = useSelector((state) => state.filter.languageId);

  const fetchSlider = async () => {
    try {
      const sportId = selectedSportId;
      const countryId = selectedCountryId;
      const tournamentId = selectedTournamentId;
      const teamId = selectedTeamId;
      const page = 1;
      const perPage = 5;

      const res = await getSlider({ languageId, sportId, countryId, tournamentId, teamId, page, perPage });
      setSliderData(res?.data?.data?.data);
  
    } catch (error) {
        console.error("Request failed with error:", error);
    }
  };

  const isMobile = window.innerWidth <= 900;

  const getSlidePath = (index) => {
    switch (index) {
      case 0:
        return "/slider/football-for-the-goals";
      case 1:
        return "/slider/support-type";
      case 2:
        return "/slider/fansupport-platform";
      default:
        return "/slider";
    }
  };

  const slides = sliderData.map((list, index) => (
    <Carousel.Slide key={list.id}>
      <CarouselImageLink to={list.urlLink} >
        <Image src={isMobile ? list.mobileImageUrl : list.webImageUrl} />
      </CarouselImageLink>
    </Carousel.Slide>
  ));

  useEffect(() => {
    // fetchCountryService();
    fetchSlider();
  },[selectedSportId, selectedCountryId, selectedTournamentId, selectedTeamId])

  return (
    <Carousel 
      mx="auto"
      withIndicators
      height="auto"
      loop
      styles={{
        indicator: {
          width: rem(12),
          height: rem(4),
          transition: 'width 250ms ease',

          '&[data-active]': {
            width: rem(40),
          },
        },
        control: {
          '&[data-inactive]': {
            opacity: 0,
            cursor: 'default',
          },
        },
      }}
      plugins={[autoplay.current]}
      onMouseEnter={autoplay.current.stop}
      onMouseLeave={autoplay.current.reset}
    >
      {slides}
    </Carousel>
  );
}

export default Carousels

export const CarouselImageLink = styled(Link)``; 