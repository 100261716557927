/*
  This file defines the `AuthLayout` React component, which serves as a layout wrapper for authentication-related pages.
  It includes a `NavbarSubHome` component at the top, which is styled to have a top padding of 60px.
  The `Outlet` component from `react-router-dom` is used to render the child routes within this layout.
  The `AuthLayout` component is styled using `styled-components` for consistent layout styling.
*/
import { Outlet } from "react-router-dom";
import { NavbarSubHome } from "../../components";
import styled from "styled-components";

const AuthLayout = () => {
  return (
    <>
    <NavbarContainerWrapper>
      <NavbarSubHome/>
    </NavbarContainerWrapper>     
        
      <Outlet/>
    </>
  )
}

export default AuthLayout

export const NavbarContainerWrapper = styled.div`
  padding-top: 60px;
`;