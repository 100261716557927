/* This code snippet is a React component called `Breadcrumbs` that generates breadcrumb navigation
based on the current URL path. Here's a breakdown of what the code does: */
import React from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next'; 

const Breadcrumbs = ({ color }) => {

  const { t } = useTranslation();

  const location = useLocation();
  const pathSegments = location.pathname.split("/").filter((segment) => segment);

  const decodePathSegment = (segment) => {
    const decodedSegment = decodeURIComponent(segment);
    return decodedSegment.replace(/-/g, ' ');
  };

  const isId = (segment) => {
    // A simple heuristic to check if the segment is an ID (e.g., a 24-character hex string)
    return /^[0-9a-fA-F]{24}$/.test(segment);
  };

  const isLanguageTag = (segment) => {
    // Add known language tags to this list
    const languageTags = ['tr', 'en', 'de', 'fr'];
    return languageTags.includes(segment.toLowerCase());
  };

  return (
    <BreadcrumbsWrapper>
      <BreadcrumbsList>
        <BreadcrumbsHomeLink to="/" color={color} className={color ? "breadcrumbs": ""}>{t('homePage')}</BreadcrumbsHomeLink>
      </BreadcrumbsList>

      {pathSegments.map((segment, index) => {
        if (index === pathSegments.length - 1 && isId(segment)) {
          return null;
        }
        if (isId(segment) || isLanguageTag(segment)) {
          return null;
        }
        const decodedSegment = decodePathSegment(segment);
        const routeTo = `/${pathSegments.slice(0, index + 1).join("/")}`;
        const isLastSegment = index === pathSegments.length - 1;
        const isSecondLastSegment = index === pathSegments.length - 2;
        return (
          <BreadcrumbsList key={index}>
            <BreadcrumbsSpan color={color} className={color ? "breadcrumbs": ""}>/</BreadcrumbsSpan>
            <BreadcrumbsLink to={routeTo} isLastSegment={isLastSegment || isSecondLastSegment} color={color} className={color ? "breadcrumbs": ""}>
            {t(decodedSegment.toLowerCase())}
            </BreadcrumbsLink>
          </BreadcrumbsList>
        );
      })}
    </BreadcrumbsWrapper>
  );
};

export default Breadcrumbs;

export const BreadcrumbsWrapper = styled.div`
  display: flex;

  @media (max-width: 900px) {
    display: none;
  }
`;
export const BreadcrumbsList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(144, 144, 144);
`;
export const BreadcrumbsSpan = styled.span`
  color: ${({ color }) => color || '#909090'};
  margin: 0 5px 0 10px;
`;
export const BreadcrumbsHomeLink = styled(Link)`
  color: ${({ color }) => color || '#909090'};
  font-size: 13px;
  text-decoration: none;
  text-transform: capitalize;
`;
export const BreadcrumbsLink = styled(Link)`
  color: ${({ isLastSegment, color }) => (color ? color : isLastSegment ? "orange" : "#909090")};
  font-weight: ${({ isLastSegment }) => (isLastSegment ? "bold" : "none")};
  font-size: 13px;
  text-decoration: none;
  text-transform: capitalize;
`;