/**
 * The `SocialDetailCard` function in this JavaScript React component handles the display and
 * functionality of a social campaign card, including joining the campaign, selecting donation amounts,
 * and displaying payment modal messages.
 */
import React,{useState} from "react";
import styled from "styled-components";
import { JoinButton, CurrencyFormat } from "../../components";
import { useTranslation } from "react-i18next";
import { NotFound } from "../../pages";
import { useDisclosure } from "@mantine/hooks";
import { Modal } from "@mantine/core";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { postCampaign } from "../../services/campaign/campaignJoinService";
import RemoveTurkishChars from "../../logic/RemoveTurkishChars";
import { getStartLanguage } from "../../logic/getStartLanguage";

const windowOpener = (link) => {
  const newWindow = window.open(link, 'payment_page', 'fullscreen=yes');
  if (newWindow) {
    newWindow.moveTo(0, 0);
    newWindow.resizeTo(window.screen.width, window.screen.height);
  }

 var timer= setInterval(function(){
    if(newWindow.closed){
      window.location.reload();
      clearInterval(timer);
    }
  }, 300);
};

const SocialDetailCard = (socialCampaign) => {

  const user  = useSelector((state) => state.user);

  const symbol = '₺';

  const navigate = useNavigate();
  const { t } = useTranslation();
  const startLanguage = getStartLanguage();

  const [opened, { open, close }] = useDisclosure(false);
  const [selectAmount, setSelectAmount] = useState("");
  const [selectedTeam, setSelectedTeam] = useState();
  const [isEmpty, setIsEmpty] = useState(false);
  const [isError, setIsError] = useState(false);
  const [campaignMessage, setCampaignMessage] = useState("");
  const [iframeSrc, setIframeSrc] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [warningEmpty, setWarningEmpty] = useState(false);
  const [activeValue, setActiveValue] = useState(symbol);
  const [activeSymbol, setActiveSymbol] = useState(symbol);


  // console.log("isoo",socialCampaign.data);

  const predefinedAmounts = [50, 100, 250, 500, 1000, 5000];
  const predefinedAmountsGlobal = [10, 50, 100, 200, 500, 1000];

  const joinCampaign = async () => {
      const selectedCampaignId = socialCampaign.data.id;
      const selectedJoinId = socialCampaign.data.detail.organisationId;
      const selectedAmount = selectAmount;
      // console.log("selectedJoinId:", selectedJoinId);
      // console.log("selectedAmount:", selectedAmount);
      // console.log("selectedCampaignId:",selectedCampaignId);
      if (user.user) {
        const res = await postCampaign(selectedCampaignId, selectedJoinId, selectedAmount);
        setCampaignMessage(res?.data?.message);
        setIframeSrc(res?.data?.data)
        // console.log("message",res?.data?.message);

        if(res?.data?.data){
            // Ödeme ekranı URL'sini aldık
            const iframeSrc = res?.data?.data;
            // İframe bileşenine URL'yi ileterek ödeme ekranını gösterdim
            setIframeSrc(iframeSrc);
            //open();
            windowOpener(iframeSrc);
          // console.log("CampaignUrl",res?.data?.data);
          // console.log("KampanyaUrl",iframeSrc);
        }
        if(res?.data?.message){
          open();
        }
      }
      else{
        navigate(`/${startLanguage}/${RemoveTurkishChars({ text: t("login") })}`)
      }
  }

  const checkMinimumAmount = (amount) => {
      if ((activeSymbol === '₺') && amount < 50) {
        setWarningMessage(`Minimum tutar 50 ${activeSymbol} olmalıdır.`);
        return false;
      } else if ((activeSymbol === '$' || activeSymbol === '€') && amount < 10) {
        setWarningMessage(`Minimum tutar 10 ${activeSymbol} olmalıdır.`);
        return false;
      }
      else{
        setWarningMessage(""); // Clear the warning message
        return true;
      }
    };

  const handleAmountSelect = (amount) => {
      setSelectAmount(amount);
  };

  const handleJoinClick = () => {
      open();
  }


  const handleContinue = () => {
      if (!selectAmount || selectAmount === "") {
          setIsEmpty(true);
      } else if (!checkMinimumAmount(selectAmount)) {
          setWarningEmpty(true);
          setIsEmpty(false);
      } else {
          setIsEmpty(false);
          setWarningEmpty(false);
          joinCampaign();
          close();
      }
  };

  const handleCloseModal = () => {
      setSelectAmount("");
      setSelectedTeam(null);
      setIsEmpty(false);
      setWarningMessage("");
      close();
  };

  const currencyOptions = [
    { value: '₺', label: "₺", number: '949' },
    { value: '$', label: "$", number: '840' },
    { value: '€', label: "€", number: '978' },
  ];  

  return (
    <>
        <CampaignsBoxContent>
        <CampaignsBox>
            <LogoFirstImage
            src={socialCampaign?.data?.detail?.logoUrlLarge ? socialCampaign?.data?.detail?.logoUrlLarge : "https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/6f6a87c5-fd75-45c3-0f8c-33977a168700/original"}
            alt=""
            />
            <CampaignsBoxDesc>
            <div>{socialCampaign?.data?.title || ""}</div>

            <CampaignsBoxCenter>
                <CampaignsBoxAmount symbol={symbol}>
                <CurrencyFormat amount={socialCampaign?.data?.detail?.homeAmount || 0}/>
                </CampaignsBoxAmount>
            </CampaignsBoxCenter>
            </CampaignsBoxDesc>
            <div onClick={() => handleJoinClick(socialCampaign?.data?.id) }>
                <JoinButton>{t("join")}</JoinButton>
            </div>
        </CampaignsBox>
        </CampaignsBoxContent>

        {!campaignMessage && 
          <Modal opened={opened} onClose={handleCloseModal} title={t("payment")} centered>
          <ModalContent>
            <ModalText>{t("selectAnAmount")}</ModalText>
            <PredefinedAmounts>
            {activeSymbol === '₺' // Check if symbol is Türk lirası
                ? predefinedAmounts.map((amount) => (
                    <PredefinedAmountButton
                      key={amount}
                      onClick={() => handleAmountSelect(amount)}
                      isSelected={selectAmount === amount}
                    >
                      {amount && parseFloat(amount).toLocaleString()} {activeSymbol}
                    </PredefinedAmountButton>
                  ))
                : activeSymbol === '$' || activeSymbol === '€' || !activeSymbol // Check if symbol is Dollar or Euro
                ? predefinedAmountsGlobal.map((amount) => (
                    <PredefinedAmountButton
                      key={amount}
                      onClick={() => handleAmountSelect(amount)}
                      isSelected={selectAmount === amount}
                    >
                      {amount && parseFloat(amount).toLocaleString()} {activeSymbol}
                    </PredefinedAmountButton>
                  ))
                : null
              }
            </PredefinedAmounts>

            <CustomAmountInput
              type="number"
              placeholder={t("determineTheAmount")}
              value={selectAmount}
              onChange={(e) => setSelectAmount(e.target.value)}
              isEmpty={isEmpty}
            />
            {isEmpty && (
              <InputErrorMessage>
                {t("quantityCannotBeEmpty")}
              </InputErrorMessage>
            )}
            {warningEmpty && (
              <InputErrorMessage>
                {warningMessage}
              </InputErrorMessage>
            )}
              <InputAmountDescContainer>
                <InputAmountDesc >{t("mySupportAmount")}</InputAmountDesc>
                <InputAmount activeSymbol={activeSymbol}>{selectAmount && parseFloat(selectAmount).toLocaleString()}</InputAmount>               
              </InputAmountDescContainer>
            <ContinueButton onClick={handleContinue}>{t("cContinue")}</ContinueButton>
          </ModalContent>
          </Modal>
        }

        {campaignMessage && campaignMessage.length > 0 ? (
            <Modal
            opened={opened}
            onClose={() => {
                close();
            }}
            title={t("payment")}
            centered
            transitionProps={{ transition: "fade", duration: 200 }}
            overlayProps={{
                backgroundOpacity: 0,
                blur: 3,
            }}
            >
            <ModalContentMessage>  
                <NotFound text={campaignMessage} />
            </ModalContentMessage>
            <ModalButtonContainer>
                <ModalCloseButton
                onClick={() => {
                    close();
                }}
                >
                {t("close")}
                </ModalCloseButton>
            </ModalButtonContainer>
            </Modal>
        ) : null}    

    </>
  )
}

export default SocialDetailCard

export const CampaignsBoxContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  padding: 10px;
  border-top: 1px solid #eee;

`;
export const CampaignsBox = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
`;
export const LogoFirstImage = styled.img`
  width: 64px;
  height: 64px;
`;
export const CampaignsBoxDesc = styled.div``;

export const CampaignsBoxAmount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #555;

  ::after {
    content: "${(props) => props.symbol}";
    margin-left: 8px;
    color: #4caf50;
    font-size: 16px;
  }
`;

export const CampaignsBoxCenter = styled.div`
  display: flex;
`;

export const ModalContentMessage = styled.div`
  height: 300px;
  padding: 30px 5px;
`;

export const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 15px;
`;

export const ModalCloseButton = styled.button`
  background-color: transparent;
  color: #333;
  padding: 10px 15px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f1f1f1;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 20px;
  padding: 20px;
`;

export const ModalText = styled.div`
  font-size: 18px;
  margin-bottom: 10px;
`;

export const PredefinedAmounts = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 10px;
`;

export const PredefinedAmountButton = styled.button`
  width: 100px;
  padding: 12px 23px;
  font-size: 16px;
  border: none;
  background-color: ${(props) => (props.isSelected ? "#4caf50" : "#f0f0f0")};
  color: ${(props) => (props.isSelected ? "#ffffff" : "#000000")};
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #4caf50;
    color: #ffffff;
  }
`;

export const InputErrorMessage = styled.div`
  font-size: 14px;
  color: #f44336;
  margin-top: 5px;
`;

export const CustomAmountInput = styled.input`
  padding: 12px;
  font-size: 16px;
  border: 3px solid ${({ isEmpty }) => (isEmpty ? "#f44336" : "#ccc")};
  border: 3px solid ${({ warningMessage }) => (warningMessage ? "#f44336" : "#ccc")};
  border-radius: 4px;
  margin-bottom: 10px;
  transition: border-color 0.3s;
  position: relative;

  &:focus {
    border-color: ${({ isEmpty }) => (isEmpty ? "#f44336" : "#4caf50")};
    border-color: ${({ warningMessage }) => (warningMessage ? "#f44336" : "#4caf50")};
  }
`;

export const InputAmountDescContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 5px;
`;

export const InputAmountDesc = styled.div`

`;

export const InputAmount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  ::after {
    content: "${(props) => props.activeSymbol}";
    margin-left: 10px;
    color: green;
    font-size: 18px;
  }
`;

export const ContinueButton = styled.button`
  padding: 12px 24px;
  font-size: 18px;
  background-color: #4caf50;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #45a049;
  }
`;