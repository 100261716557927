import { fansupportPrivate } from "../index";
/**
 * Retrieves the base64-encoded user picture from the server.
 *
 * @return {Promise<Object>} The server's response containing the user picture data.
 * @throws {Error} If there was an error while retrieving the user picture.
 */
export const getUserPicture = async () => {
    try {
        const response = await fansupportPrivate.get('identity/user-picture-base64');
        return response;
    } catch (error) {
        console.error("getUserPicture Error:", error);
    }
};
