import { fansupportPrivate } from "../index";
/**
 * Asynchronously fetches campaign recommendations based on given filters.
 * @async
 * @function
 * @name getCampaignRecommended
 * @param {Object} options - An object containing the following properties:
 * @param {string} options.sportId - The ID of the sport to filter recommendations by.
 * @param {string} options.countryId - The ID of the country to filter recommendations by.
 * @param {string} options.tournamentId - The ID of the tournament to filter recommendations by.
 * @param {string} options.teamId - The ID of the team to filter recommendations by.
 * @return {Promise<Object>} A Promise that resolves to recommendations in JSON format.
 * @throws {Error} If an error occurs during the request.
 */
export const getCampaignRecommended = async ({ sportId, countryId, tournamentId, teamId }) => {
    try {
        return fansupportPrivate.get('/campaign/recommended', {params: {
            sportId, 
            countryId,
            tournamentId,
            teamId,
        }})
    } catch (error) {
        console.log("getCampaignRecommended",error)
    }    
};