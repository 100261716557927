import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationTR from "../src/locales/translationTR.json"

i18n.use(initReactI18next).init({
    resources: {
      tr: {
        translation: translationTR,
      },
    },
    lng: 'tr', // Kullanıcının tercih ettiği dil
    fallbackLng: 'tr', // Yedek dil
    interpolation: {
      escapeValue: false,
    },
  });
  
  export default i18n;