/**
 * `SupportType` Component
 * 
 * This component showcases different types of support options and application features. It is structured into two main sections:
 * 
 * 1. **ConditionalSupportBanner**: Displays a grid of support types with associated cards.
 *    - **ConditionalSupportContainer**: A container that wraps the grid of support cards.
 *    - **CardTitle**: Title for the support types section.
 *    - **ConditionalSupportContent**: Contains the grid layout for support cards.
 *      - **Grid**: Utilizes Mantine's Grid component to arrange the support cards in a responsive layout.
 *        - **Card**: Custom card component displaying information about each support type. Uses `homeRail` data for content.
 * 
 * 2. **FeaturesBanner**: Shows the application features section.
 *    - **AppFeatures**: Component that displays features of the application.
 * 
 * **Key Functionalities**:
 * - Uses `react-i18next` for handling translations, allowing dynamic content based on the user's language.
 * - Utilizes Mantine's Grid and Flex components for layout and responsive design.
 * - Employs styled-components for custom styling and to manage the appearance of various sections.
 * 
 * **Components and Elements**:
 * - `HomeMain`: Main wrapper for the component with padding for layout spacing.
 * - `ConditionalSupportBanner`: Section that displays support types with card elements.
 * - `ConditionalSupportContainer`: Container for the support types grid.
 * - `ConditionalSupportContent`: Wrapper for the grid of support cards.
 * - `CardTitle`: Styled component for the title of the support types section.
 * - `FeaturesBanner`: Section for application features with spacing for layout.
 * 
 * **Styling**:
 * - Styled-components are used for custom styling and layout management.
 * - `HomeMain` has top and bottom padding to provide spacing around the main content.
 * - `CardTitle` is styled with orange color and bold text to stand out.
 * 
 * **Usage**:
 * - This component should be used in areas where support types and application features need to be displayed in a structured and styled format.
 */
import React from "react";
import { AppFeatures, Card } from "../../components";
import { useTranslation } from "react-i18next";
import { Container, Flex, Grid } from '@mantine/core';
import { homeRail, homeRail2 } from "../../utils/config";
import styled from "styled-components";

const SupportType = () => {

    const { t } = useTranslation();

  return (
    <>
      <HomeMain className="about-us-main">
        <ConditionalSupportBanner
          id="support-conditions"
          className="double-sections"
        >
          <ConditionalSupportContainer>
            <CardTitle>{t("staticSupportTypes")}</CardTitle>

            <ConditionalSupportContent>
              <Grid justify="center" gutter="xl" mx={0}>
                {homeRail.map((data, index) => (
                  <Grid.Col md={6} lg={3}>
                    <Card
                      key={index}
                      img={data.img}
                      title={t(data.title)}
                      desc={t(data.desc)}
                      url={data.url}
                      imageWidth="50px"
                      imageHeight="50px"
                      backgroundColor="white"
                      textColor="black"
                      boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                      className="conditional-support-card"
                    />
                  </Grid.Col>
                ))}
              </Grid>
            </ConditionalSupportContent>
          </ConditionalSupportContainer>
        </ConditionalSupportBanner>
        <FeaturesBanner id="features" className="double-sections">
          <AppFeatures />
        </FeaturesBanner>
      </HomeMain>
    </>
  );
};

export default SupportType;

export const HomeMain = styled.main`
  padding: 100px 0;
`;

export const ConditionalSupportContainer = styled.div`

`;
export const ConditionalSupportContent = styled.div``;
export const ConditionalSupportBanner = styled.section``;

export const CardTitle = styled.div`
  color: orange;
  padding-bottom: 50px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
`;

export const FeaturesBanner = styled.section`
  // width: 1100px;
  margin: 50px auto;
`;
