import { fansupportPublic } from "../index";
/**
 * Fetches all countries from the API.
 * @returns {Promise<Object>} A promise that resolves to the API response.
 * @throws {Error} If there is an error during the API request.
 */
export const getAllCountry = async () => {
  try {
    const res = fansupportPublic.get('/sport/countries-all');

    return res;
    
  } catch (error) {
    console.log("getAllCountry",error)
  }
};