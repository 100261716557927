import { fansupportPublic } from "../index";
/**
 * Asynchronously retrieves news articles from the API based on the specified filter criteria.
 *
 * @async
 * @function getNews
 * @param {Object} options - An object containing the following properties:
 * @param {number} [options.id] - The ID of the news article to retrieve.
 * @param {string} [options.title] - The title of the news article to retrieve.
 * @param {string} [options.description] - The description of the news article to retrieve.
 * @param {string} [options.heading] - The heading of the news article to retrieve.
 * @param {string} [options.body] - The body of the news article to retrieve.
 * @param {string} [options.languageId] - The ID of the language the news article is in.
 * @param {string} [options.slug] - The slug of the news article to retrieve.
 * @param {Date} [options.publishDate] - The date the news article was published.
 * @param {boolean} [options.status] - The status of the news article.
 * @param {number} [options.sportId] - The ID of the sport the news article is about.
 * @param {number} [options.countryId] - The ID of the country the news article is about.
 * @param {number} [options.tournamentId] - The ID of the tournament the news article is about.
 * @param {number} [options.teamId] - The ID of the team the news article is about.
 * @param {number} [options.page] - The page number of the news articles to retrieve.
 * @param {number} [options.perPage] - The number of news articles to retrieve per page.
 * @returns {Promise<Object>} A Promise that resolves to the fetched data in JSON format.
 * @throws {Error} If there is an error during the API request.
 */
export const getNews = async (
    { 
        id, 
        title, 
        description, 
        heading, 
        body, 
        languageId, 
        slug, 
        publishDate,
        status,
        sportId,
        countryId,
        tournamentId,
        teamId,
        page, 
        perPage
    }) => {
    try {
        return fansupportPublic.get('/news',{ params: { 
            id, 
            title, 
            description, 
            heading, 
            body, 
            languageId,
            slug, 
            publishDate,
            status,
            sportId,
            countryId,
            tournamentId,
            teamId,            
            page,
            perPage
        }})   
    } catch (error) {
        console.log("getNews",error);
    }    
} 