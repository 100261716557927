/*
This React component, `HelpDetail`, fetches and displays HTML content dynamically based on a URL parameter (`type`). 
It consists of two main parts: `DisplayHtmlContent`, which renders the HTML and applies custom CSS classes to specific tags,
and `HelpDetail`, which handles fetching the document data from an external service and passing it to the display component.
Styled-components are used for consistent styling across the component.

- The `DisplayHtmlContent` component is responsible for rendering HTML content passed as a prop (`htmlContent`). It uses a `ref` to manipulate the DOM directly, adding specific CSS classes (`help` and `helpTd`) to certain elements (`p`, `span`, `td`) after the content has been rendered.
- The `HelpDetail` component fetches the appropriate document content based on the `type` parameter retrieved from the URL using `useParams`. It handles the asynchronous data fetching and manages the state of the document data, passing it to `DisplayHtmlContent` for rendering.
- Styling is applied to the main wrapper (`DocumentMain`) and the message container (`DocumentMainMessage`) using `styled-components`, ensuring the layout and presentation are consistent with the design specifications.
*/
import React, { useState, useEffect, useRef } from 'react';
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { getDocument } from '../../services/document/documentService';

const DisplayHtmlContent = ({ htmlContent }) => {
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      const pTags = contentRef.current.querySelectorAll('p');
      const spanTags = contentRef.current.querySelectorAll('span');
      const tdTags = contentRef.current.querySelectorAll('td');
      pTags.forEach(p => p.classList.add('help'));
      spanTags.forEach(span => span.classList.add('help'));
      tdTags.forEach(td => td.classList.add('helpTd'));
    }
  }, [htmlContent]);

  return (
    <div
      ref={contentRef}
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    />
  );
};

const HelpDetail = () => {
  const [documentData, setDocumentData] = useState('');

  const { type } = useParams();

  const fetchDocument = async () => {
    try {
      const res = await getDocument({ type });
      setDocumentData(res?.data?.data?.textContent || '');
    } catch (error) {
      console.log("An error occurred while fetching documents", error);
    }
  };

  useEffect(() => {
    fetchDocument();
  }, [type]);

  return (
    <DocumentMain>
      <DocumentMainMessage>
        <DisplayHtmlContent htmlContent={documentData} />
      </DocumentMainMessage>
    </DocumentMain>
  );
};

export default HelpDetail;

export const DocumentMain = styled.main`
  max-width: 1100px;
  margin: 30px auto;
  padding: 50px 0px;
`;

export const DocumentMainMessage = styled.div`
`;
