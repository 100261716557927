import React from 'react'
import { NavbarHome } from '../../components'
import Home from './Home'
import styled from "styled-components";

/* 
  HomePage Component:
  - This component serves as the main entry point for the home page layout.
  - It includes the NavbarHome component and the Home component.
*/

const HomePage = () => {
  return (
    <>
    <NavbarContainerWrapper>
        <NavbarHome/>
    </NavbarContainerWrapper>

        <Home/>
    </>
  )
}

export default HomePage

export const NavbarContainerWrapper = styled.div`
  padding-top: 60px;
`;