/*
  This file defines the `NewsFromUs` component, which is responsible for fetching and displaying news articles. It integrates with Redux to get filter criteria and uses a service to fetch news data.

  **Key Features and Functionality:**
  - **State Management**: Utilizes `useState` to manage the news data state.
  - **Redux Integration**: Uses `useSelector` to access various filter criteria from the Redux store, such as sport, country, tournament, and team IDs.
  - **Data Fetching**: Defines `fetchNews` function to get news data from a service based on filter criteria and updates the state with the fetched data.
  - **Effect Hook**: Uses `useEffect` to call `fetchNews` whenever any filter criteria change.
  - **Rendering News**: Maps over the fetched news data and renders `NewsFromUsCard` components for each news item.
  - **Styling**: Uses `styled-components` to style the component, including a background image and layout styling.

  **Components and Styling:**
  - `NewsFromUsMain`: The main container with a background image for the news section.
  - `NewsFromUsContainer`: A flex container for displaying news cards with a gap and centered layout.

  **Technical Details:**
  - **Data Fetching**: `fetchNews` fetches data from `getNews` with parameters like `languageId`, `status`, `page`, and `perPage`.
  - **Dependencies**: The `useEffect` hook depends on filter criteria (`selectedSportId`, `selectedCountryId`, `selectedTournamentId`, `selectedTeamId`), so it triggers `fetchNews` whenever any of these values change.

  This component is responsible for displaying a list of news articles based on the selected filters. It ensures that the displayed news is up-to-date with the latest available data.
*/
import React, { useEffect, useState } from 'react'
import { NewsFromUsCard } from '../../components';
import styled from 'styled-components';
import backgroundSVG from "../../assets/gradient-topography.svg"
import { useSelector } from 'react-redux';
import { getNews } from '../../services/news/NewsService';
import LanguageTag from '../../enums/LanguageTag';


const NewsFromUs = () => {

  const [newsData,setNewsData] = useState();


  const selectedSportId = useSelector(state => state.filter.sportId);
  const selectedCountryId = useSelector(state => state.filter.countryId);
  const selectedTournamentId = useSelector(state => state.filter.tournamentId);
  const selectedTeamId = useSelector((state) => state.filter.teamId);
  const languageId = useSelector((state) => state.filter.languageId);

  const fetchNews = async () => {
    const status = true;
    // const sportId = selectedSportId;
    // const countryId = selectedCountryId;
    // const tournamentId = selectedTournamentId;
    // const teamId = selectedTeamId;    
    const page = 1;
    const perPage = 25;
  
    const res = await getNews({ languageId, status, page, perPage })
  
    setNewsData(res?.data?.data?.data || []);
  };
  
  // console.log("news",newsData);

  useEffect(() => {
    fetchNews();
  },[selectedSportId, selectedCountryId, selectedTournamentId, selectedTeamId])

  return (
    <NewsFromUsMain>

      <NewsFromUsContainer>
        {newsData?.map((news) => (
          <NewsFromUsCard 
            key={news.id}
            id={news.id}
            date={news.publishDate}
            title={news.title}
            desc={news.description}
            image={news.imageUrl}
          />
        ))}        
      </NewsFromUsContainer>
    </NewsFromUsMain>
  )
}

export default NewsFromUs

export const NewsFromUsMain = styled.main`
  background-image: url(${backgroundSVG});
  background-size: cover;
  background-position: center;
`;
export const NewsFromUsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  max-width: 1240px;
  margin: 0 auto;
  padding: 30px 0;
`;