import React from "react";
import styled from "styled-components";
import notFoundIcon from "../../assets/search.svg"

/**
  * The NotFound component displays a 'Not Found' message with an icon.
  * - It uses the NotFoundContainer for layout and styling, displaying the icon and text in a centered column layout.
  * - The `width` and `margin` props allow customization of the container's width and margin.
  *
  * @param {string} text - The message text to display.
  * @param {string} [width] - Optional width for the container.
  * @param {string} [margin] - Optional margin for the container.
  */

const NotFound = ({ text, width, margin }) => {

  return (
    <NotFoundContainer width={width} margin={margin} className="not-found">
      <NotFoundIcon src={notFoundIcon} alt="Not Found" />
      <NotFoundText>{text}</NotFoundText>
    </NotFoundContainer>
  );
};

export default NotFound;

export const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f8f8f8;
  margin: ${({ margin }) => margin ? margin : "20px auto"};
  width: ${({ width }) => width ? width : "auto"};
  max-width: 300px;
  max-height: 150px;
`;

export const NotFoundIcon = styled.img`
  width: 64px;
  height: 64px;
  margin-bottom: 10px;
`;

export const NotFoundText = styled.p`
  font-size: 14px;
  color: #888;
  text-align: center;
`;
