import { fansupportPrivate } from "../index";
/**
 * Asynchronously fetches poll data from the server based on specified filters.
 *
 * @async
 * @function
 * @name getPollPrivate
 * @param {Object} options - An object containing the following properties:
 * @param {string} options.sportId - The ID of the sport to filter polls by.
 * @param {string} options.countryId - The ID of the country to filter polls by.
 * @param {string} options.tournamentId - The ID of the tournament to filter polls by.
 * @param {string} options.teamId - The ID of the team to filter polls by.
 * @param {string} options.languageId - The language to filter polls by.
 * @param {number} options.page - The page number of polls to fetch.
 * @param {number} options.perPage - The number of polls to fetch per page.
 * @return {Promise<Object>} A Promise that resolves to poll data in JSON format.
 * @throws {Error} If an error occurs during the request.
 */
export const getPollPrivate = async ({ sportId, countryId, tournamentId, teamId, languageId, page, perPage}) => {
    try {
        return fansupportPrivate.get('/poll',{ params: { 
            sportId,
            countryId,
            tournamentId,
            teamId,
            languageId,
            page,
            perPage
        }})   
    } catch (error) {
        console.log("getPoll",error);
    }    
} 