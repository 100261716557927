import { fansupportPrivate } from "./index";
/**
 * Asynchronously fetches a user's favorite teams from the server based on specified filters.
 *
 * @async
 * @function
 * @name getFavoriteTeams
 * @param {Object} options - An object containing the following properties:
 * @param {string} options.sportId - The ID of the sport to filter favorite teams by.
 * @param {number} options.page - The page number of favorite teams to fetch.
 * @param {number} options.perPage - The number of favorite teams to fetch per page.
 * @return {Promise<Object>} A Promise that resolves to favorite team data in JSON format.
 * @throws {Error} If an error occurs during the request.
 */
export const getFavoriteTeams = async ({ sportId, page, perPage }) => {
    try {
        return fansupportPrivate.get('/sport/favorite-teams', {params: {
            sportId,
            page,
            perPage,
        }})
        return DataTransfer;
    } catch (error) {
        console.log("getFavoriteTeams",error)
    }
} 