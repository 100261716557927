/* This code snippet is a React component called `MatchStaticsCard` that displays various statistics
related to a match. Here's a breakdown of what the code is doing: */
import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { RingProgress, Text } from "@mantine/core";
import { Progress } from "@mantine/core";

const MatchStaticsCard = () => {
  const {t} = useTranslation();
  return (
    <>
      <MatchStaticsContainer>

        <MatchStaticsRingProgress
        size={240}
        thickness={20}
        roundCaps
          label={
            <Text size="lg" align="center">
              %0
            </Text>
          }
          sections={[{ value: 0, color: "cyan" }]}
        />
        <RingProgressDesc>{t("winRate")}</RingProgressDesc>

        <WinTieDefeatContainer>
          <WinTieDefeatCard className="campaign-card">
            <div>{t("win")}</div>
            <Amount>0</Amount>
          </WinTieDefeatCard>
          <WinTieDefeatCard className="campaign-card">
            <div>{t("tie")}</div>
            <Amount>0</Amount>
          </WinTieDefeatCard>
          <WinTieDefeatCard className="campaign-card">
            <div>{t("defeat")}</div>
            <Amount>0</Amount>
          </WinTieDefeatCard>
        </WinTieDefeatContainer>


        <MatchStaticsProgressTitle>
          <MatchStaticsProgressTitleLeft>{t("goalScored")}</MatchStaticsProgressTitleLeft>
          <MatchStaticsProgressTitleRight>{t("concededGoal")}</MatchStaticsProgressTitleRight>
        </MatchStaticsProgressTitle>

        <MatchStaticsProgress
          radius="xl"
          size={24}
          striped
          //   animate
          sections={[
            {
              value: 50,
              color: "grape",
              label: 0,
              tooltip: "Goal Scored",
            },
            { value: 50, color: "pink", label: 0, tooltip: "Conceded Goal" },
          ]}
        />

      </MatchStaticsContainer>



      
    </>
  );
};

export default MatchStaticsCard;

export const MatchStaticsContainer = styled.div`
  width: 100%;
`;
export const MatchStaticsRingProgress = styled(RingProgress)`
  margin: auto;
`;
export const RingProgressDesc = styled.div`
  text-align: center;
  margin-bottom: 50px;
`;
export const MatchStaticsProgressTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 10px;
`;
export const MatchStaticsProgressTitleLeft = styled.div`
  color: blue;
`;
export const MatchStaticsProgressTitleRight = styled.div`
  color: red;
`;
export const MatchStaticsProgress = styled(Progress)`
  margin-bottom: 30px;
  color: white;
`;
export const WinTieDefeatContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 10px;
  margin: 50px 0;
`;
export const WinTieDefeatCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  width: 200px;
  height: 100px;
  background-color: white;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;
export const Amount = styled.div`
  font-size: 24px;
  font-weight: bold;
`;
