/**
 * RemoveTurkishChars is a utility component that normalizes and transforms a string by removing Turkish characters,
 * converting it to lowercase, replacing spaces with hyphens, and trimming whitespace.
 *
 * The `removeTurkishChars` function processes the input string by:
 * 1. Normalizing it to 'NFD' form, which separates combined characters into their base character and diacritical mark.
 * 2. Using a regular expression to remove diacritical marks (accents), which effectively removes Turkish-specific characters.
 *
 * After removing Turkish characters, the string is:
 * 1. Converted to lowercase.
 * 2. Replaced spaces with hyphens.
 * 3. Trimmed of any leading or trailing whitespace.
 *
 * This component is useful for creating URL-friendly or uniform text representations where special Turkish characters need to be handled.
 *
 * @param {Object} props - The component's props.
 * @param {string} props.text - The input text to be processed.
 * @returns {string} - The transformed string with Turkish characters removed and formatted.
 */
const RemoveTurkishChars = ({ text }) => {
  const removeTurkishChars = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };
  return removeTurkishChars(text).toLowerCase().replace(/\s+/g, '-').trim();
};

export default RemoveTurkishChars;