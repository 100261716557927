import { fansupportPublic } from "../index";
/**
 * Retrieves the list of sport statuses from the API.
 * 
 * @return {Promise} A Promise that resolves to the response from the API,
 * or rejects with an error if the request fails.
 * @throws {Error} If there is an error during the request.
 */
export const getStatuses= async () => {
  try {
    const res = fansupportPublic.get('/sport/statuses');

    return res;
    
  } catch (error) {
    console.log("getStatuses Error",error);
  }
};