import React, { useEffect, useState } from 'react'
import { getNews } from '../../services/news/NewsService';
import styled from 'styled-components';
import { Breadcrumbs } from '../../components';

/* 
  NewsFromUsDetail component is responsible for displaying the details of a specific news item.
  It fetches the news data based on the ID obtained from the URL and renders the details including
  the image, title, description, and body content.
*/

const NewsFromUsDetail = () => {

    /* 
      State variables for managing news data and title.
      - `newsData`: Stores the array of news items fetched from the API.
      - `newsTitle`: Stores the title of the news item.
    */

    const [newsData,setNewsData] = useState();
    const [newsTitle,setNewsTitle] = useState();

    /* 
      Asynchronous function to fetch news details from the server.
      - Extracts the news ID from the URL.
      - Calls `getNews` service function with the extracted ID and status.
      - Updates state variables with the fetched news data and title.
    */

    const fetchNews = async () => {
        // Extract news ID from the URL
        const url = window.location.href;
        const pathId = url.substring(url.lastIndexOf('/') + 1);

        console.log("id",pathId);

        const id = pathId;
        const status = true;
        // Fetch news data using the extracted ID
        const res = await getNews({ id, status })
        // Update state variables with the fetched news data
        setNewsData(res?.data?.data?.data || []);
        setNewsTitle(res?.data?.data?.data[0]?.title || []);
      };
      
    //   console.log("NewsFromUsDetail",newsData);

    /* 
      `useEffect` hook to call `fetchNews` function when the component mounts.
      - Ensures that news details are fetched and state is updated when the component is first rendered.
    */
    
      useEffect(() => {
        fetchNews();
    },[])

  return (
    <DetailContainerMain className='home-main'>

        <CampaignDetailBackground>
          <CampaignDetailImg src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/adee39a7-f3c1-43f4-81da-8cbd1a26f500/original" />

          <CampaignDetailTitleBreadcrumbsContainer>
            <CampaignDetailTitleContainer>
              <CampaignDetailTitle>
                {newsTitle && (
                  <>
                    {newsTitle}
                  </>                  
                )}
                </CampaignDetailTitle>
            </CampaignDetailTitleContainer>

            <Breadcrumbs />
          </CampaignDetailTitleBreadcrumbsContainer>
        </CampaignDetailBackground>

        <DetailContainer className='home-main'>
            <DetailWrapper className='campaign-card'>
                {newsData?.map((news) => (
                    <>
                        <Image src={news?.imageUrl} alt={newsData.title} />
                        <Title>{news?.title}</Title>
                        <Description className='news-from-us-detail'>{news?.description}</Description>
                        <Body className='news-from-us-detail'>{news?.body}</Body>
                    </>

                ))} 
            </DetailWrapper>
 
        </DetailContainer>
    </DetailContainerMain>
  )
}

export default NewsFromUsDetail

export const DetailContainerMain = styled.main`
    background-color: #F8F9FA;
`;

export const DetailContainer = styled.div`
    margin: 30px 0;
    background-color: #fff;
`;
export const DetailWrapper = styled.div`
    max-width: 800px;
    margin: 0 auto;
    padding: 20px 20px;
    padding-bottom: 30px;
    // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .1);    
`;

export const Title = styled.h1`
    font-size: 24px;
    margin-bottom: 10px;
`;

export const Image = styled.img`
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    margin-bottom: 20px;
    border-radius: 10px;
`;

export const Description = styled.p`
    font-size: 16px;
    color: #333;
    margin-bottom: 20px;
`;

export const Body = styled.div`
    font-size: 18px;
    line-height: 1.6;
    color: #555;
`;


export const CampaignDetailBackground = styled.div`
  width: 100%;
`;

export const CampaignDetailImg = styled.img`
  width: 100%;
  height: 300px;
`;

export const CampaignDetailTitleBreadcrumbsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  bottom: 4px;
  padding: 20px 30px;
  background-color: #033036;
`;

export const CampaignDetailTitleContainer = styled.div`
  display: flex;
`;


export const CampaignDetailTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
  color: white;
`;