import { fansupportPublic } from "../index";
/**
 * `getAds` is an asynchronous function that fetches ad data from the API based on the provided countryId, languageId, platformType, and location. It returns a Promise that resolves to the fetched data in JSON format.
 *
 * @param {Object} options - An object containing the following properties:
 * @param {string} options.countryId - The ID of the country to fetch ad data for.
 * @param {string} options.languageId - The ID of the language to fetch ad data in.
 * @param {number} options.platformType - The type of platform to fetch ad data for.
 * @param {string} options.location - The location of the ads to fetch.
 * @return {Promise<Object>} A Promise that resolves to the fetched data in JSON format.
 */
export const getAds = async ({ countryId, languageId, platformType, location }) => {
    return fansupportPublic.get('/media-content', {params: {
        countryId,
        languageId,
        platformType,
        location
    }})
}