/**
 * The `CampaignCompetitionDetailCard` component in JavaScript React handles the display and
 * functionality of a campaign competition detail card, including team selection, amount selection, and
 * joining the campaign with payment processing.
 */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { JoinButton, PaymentIframe, DateTimeFormatter } from "../../components";
import { useDisclosure } from "@mantine/hooks";
import { Modal } from "@mantine/core";
import { postCampaign } from "../../services/campaign/campaignJoinService";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { NotFound } from "../../pages";
import { getStartLanguage } from "../../logic/getStartLanguage";
import RemoveTurkishChars from "../../logic/RemoveTurkishChars";

const windowOpener = (link) => {
  const newWindow = window.open(link, 'payment_page', 'fullscreen=yes');
  if (newWindow) {
    newWindow.moveTo(0, 0);
    newWindow.resizeTo(window.screen.width, window.screen.height);
  }

 var timer= setInterval(function(){
    if(newWindow.closed){
      window.location.reload();
      clearInterval(timer);
    }
  }, 300);
};

const CampaignCompetitionDetailCard = (props) => {

  const user  = useSelector((state) => state.user);
  const navigate = useNavigate();
  const startLanguage = getStartLanguage();

  const symbol = '₺';

  const { t } = useTranslation();

  // console.log("props",props);

  // console.log("symbol",symbol);

  const [opened, { open, close }] = useDisclosure(false);
  const [selectAmount, setSelectAmount] = useState("");
  const [selectedTeam, setSelectedTeam] = useState();
  const [isEmpty, setIsEmpty] = useState(false);
  const [isError, setIsError] = useState(false);
  const [campaignMessage, setCampaignMessage] = useState("");
  const [iframeSrc, setIframeSrc] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [warningEmpty, setWarningEmpty] = useState(false);
  const [activeValue, setActiveValue] = useState(symbol);
  const [activeSymbol, setActiveSymbol] = useState(symbol);

  const predefinedAmounts = [50, 100, 250, 500, 1000, 5000];
  const predefinedAmountsGlobal = [10, 50, 100, 200, 500, 1000];


  const joinCampaign = async () => {
    const selectedCampaignId = props.campaign.id
    const selectedJoinId = selectedTeam;
    const selectedAmount = selectAmount;
    // console.log("selectedJoinId:", selectedJoinId);
    // console.log("selectedAmount:", selectedAmount);
    // console.log("selectedCampaignId:",selectedCampaignId);
    if (user.user) {
      const res = await postCampaign(selectedCampaignId, selectedJoinId, selectedAmount);
      setCampaignMessage(res?.data?.message);
      setIframeSrc(res?.data?.data)
      // console.log("message",res?.data?.message);

      if(res?.data?.data){
         // Ödeme ekranı URL'sini aldık
         const iframeSrc = res?.data?.data;
         // İframe bileşenine URL'yi ileterek ödeme ekranını gösterdim
         setIframeSrc(iframeSrc);
         //open();
         windowOpener(iframeSrc);
        // console.log("CampaignUrl",res?.data?.data);
        // console.log("KampanyaUrl",iframeSrc);
      }
      if(res?.data?.message){
        open();
      }
    }
    else{
      navigate(`/${startLanguage}/${RemoveTurkishChars({ text: t("login") })}`)
    }
  };

  const checkMinimumAmount = (amount) => {
    if ((activeSymbol === '₺') && amount < 50) {
      setWarningMessage(`Minimum tutar 50 ${activeSymbol} olmalıdır.`);
      return false;
    } else if ((activeSymbol === '$' || activeSymbol === '€') && amount < 10) {
      setWarningMessage(`Minimum tutar 10 ${activeSymbol} olmalıdır.`);
      return false;
    }
    else{
      setWarningMessage(""); // Clear the warning message
      return true;
    }
  };
  

  const handleAmountSelect = (amount) => {
    setSelectAmount(amount);
  };

  const handleTeamSelect = (team) => {
    setSelectedTeam(team);
  };

  const handleJoinButton = () => {
    if (!selectedTeam) {
      setIsError(true);  
    } else {
      setIsError(false);
      open();
    }
  };

  const handleContinue = () => {
    if (!selectAmount || selectAmount === "" || !selectedTeam) {
      setIsEmpty(true);
    } else if (!checkMinimumAmount(selectAmount)) {
      setWarningEmpty(true);
      setIsEmpty(false);
    } else {
      setIsEmpty(false);
      setWarningEmpty(false);
      joinCampaign();
      close();
    }
  };

  const handleCloseModal = () => {
    setSelectAmount("");
    setSelectedTeam(null);
    setIsEmpty(false);
    setWarningMessage("");
    close();
    window.location.reload();
  };


  return (
    <>
      <CampaignsBoxContent>
        <CampaignsBoxLeft>
          <TeamLogoContainer onClick={() => handleTeamSelect(props.homeTeam.homeJoinId)}
            isSelected={selectedTeam === props.homeTeam.homeJoinId}
            t={t}
            >
            <LogoFirstImage src={props.homeTeam.largeBadge ? props.homeTeam.largeBadge : "https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/6f6a87c5-fd75-45c3-0f8c-33977a168700/original"} alt={props.homeTeam.name || ""} />
          </TeamLogoContainer>

          <CampaignsBoxLeftDesc>
            <TeamName>{props.homeTeam.name}</TeamName>
          </CampaignsBoxLeftDesc>
        </CampaignsBoxLeft>

        <CampaignsBoxCenter>
          <CampaignsVsImage src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/ed3ebaf6-acdf-4260-1820-64b0289a9e00/original" />

          <DateTimeText>
            <DateTimeFormatter apiTarih={new Date(props.campaign.startDate)} dil="tr" />
          </DateTimeText>
          <JoinButtonContainer onClick={handleJoinButton}>
            <JoinButton disabled={!selectedTeam}>{t("join")}</JoinButton>
            {isError && ( 
              <SelectionErrorMessage>
                {t("pleaseSelectTeam")}
              </SelectionErrorMessage>
            )}
          </JoinButtonContainer>
        </CampaignsBoxCenter>

        <CampaignsBoxRight>
          <CampaignsBoxRightDesc>
            <TeamName >{props.awayTeam.name}</TeamName>
          </CampaignsBoxRightDesc>

          <TeamLogoContainer onClick={() => handleTeamSelect(props.awayTeam.awayJoinId)}
            isSelected={selectedTeam === props.awayTeam.awayJoinId}
            t={t}
            >
            <LogoSecondImage src={props.awayTeam.largeBadge ? props.awayTeam.largeBadge : "https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/6f6a87c5-fd75-45c3-0f8c-33977a168700/original"} alt={props.awayTeam.name || ""} />
          </TeamLogoContainer>
        </CampaignsBoxRight>
      </CampaignsBoxContent>

      {!campaignMessage && 
        <Modal opened={opened} onClose={handleCloseModal} title={t("payment")} centered>
          <ModalContent>
            <ModalText>{t("selectAnAmount")}</ModalText>
            <PredefinedAmounts>
              {activeSymbol === '₺' // Check if symbol is Türk lirası
                ? predefinedAmounts.map((amount) => (
                    <PredefinedAmountButton
                      key={amount}
                      onClick={() => handleAmountSelect(amount)}
                      isSelected={selectAmount === amount}
                    >
                      {amount && parseFloat(amount).toLocaleString()} {activeSymbol}
                    </PredefinedAmountButton>
                  ))
                : activeSymbol === '$' || activeSymbol === '€' || !activeSymbol // Check if symbol is Dollar or Euro
                ? predefinedAmountsGlobal.map((amount) => (
                    <PredefinedAmountButton
                      key={amount}
                      onClick={() => handleAmountSelect(amount)}
                      isSelected={selectAmount === amount}
                    >
                      {amount && parseFloat(amount).toLocaleString()} {activeSymbol}
                    </PredefinedAmountButton>
                  ))
                : null
              }
            </PredefinedAmounts>
  
            <CustomAmountInput
              type="number"
              placeholder={t("determineTheAmount")}
              value={selectAmount}
              onChange={(e) => setSelectAmount(e.target.value)}
              isEmpty={isEmpty}
            />
            {isEmpty && (
              <InputErrorMessage>
                {t("quantityCannotBeEmpty")}
              </InputErrorMessage>
            )}
            {warningEmpty && (
              <InputErrorMessage>
                {warningMessage}
              </InputErrorMessage>
            )}
              <InputAmountDescContainer>
                <InputAmountDesc >{t("mySupportAmount")}</InputAmountDesc>
                <InputAmount activeSymbol={activeSymbol}>{selectAmount && parseFloat(selectAmount).toLocaleString()}</InputAmount>               
              </InputAmountDescContainer>
            <ContinueButton onClick={handleContinue}>{t("cContinue")}</ContinueButton>
          </ModalContent>
        </Modal>
      }

      {campaignMessage && campaignMessage.length > 0 ? (
        <Modal
          opened={opened}
          onClose={() => {
            close();
          }}
          title={t("payment")}
          centered
          transitionProps={{ transition: "fade", duration: 200 }}
          overlayProps={{
            backgroundOpacity: 0,
            blur: 3,
          }}
        >
          <ModalContentMessage>  
            <NotFound text={campaignMessage} />
          </ModalContentMessage>
          <ModalButtonContainer>
            <ModalCloseButton
              onClick={handleCloseModal}
            >
              {t("close")}
            </ModalCloseButton>
          </ModalButtonContainer>
        </Modal>
      ) : null}
      
    </>
  );
};

export default CampaignCompetitionDetailCard;

export const CampaignsBoxContent = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 10px;
  border-top: 1px solid #eee;
  font-size: 13px;
`;
export const CampaignsBoxLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const LogoFirstImage = styled.img`
  width: 64px;
  height: 64px;
  cursor: pointer;
`;
export const CampaignsBoxLeftDesc = styled.div``;

const TeamLogoContainer = styled.div`
  cursor: pointer;
  border: 3px solid ${(props) => (props.isSelected ? "#4caf50" : "transparent")};
  border-radius: 4px;
  padding: 5px;
  position: relative;
  text-align: center;

  &:hover::after {
    content: "${(props) => props.t("selectTeam")}";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
    pointer-events: none;
    white-space: nowrap;
  }
`;
export const TeamName = styled.div`
  font-size: 16px;
  margin-top: 8px;
  text-align: center;
`;

export const CampaignsBoxCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CampaignsVsImage = styled.img`
  width: 30px;
  height: 30px;
`;

export const CampaignsVsDate = styled.div`
  font-size: 14px;
  color: #888;
  margin-top: 4px;
`;

export const CampaignsVsHour = styled.div`
  font-size: 14px;
  color: #888;
  margin-top: 4px;
`;
export const JoinButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
`;

export const SelectionErrorMessage = styled.div`
  font-size: 14px;
  color: #f44336;
  margin-top: 5px;
`;

export const CampaignsBoxRight = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
`;
export const LogoSecondImage = styled.img`
  width: 72px;
  height: 72px;
  cursor: pointer;
`;
export const CampaignsBoxRightDesc = styled.div``;

export const CampaignsBoxAmount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #555;

  ::after {
    content: "${(props) => props.activeSymbol}";
    margin-left: 8px;
    color: #4caf50;
    font-size: 16px;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 20px;
  padding: 20px;
`;

export const ModalText = styled.div`
  font-size: 18px;
  margin-bottom: 10px;
`;

export const PredefinedAmounts = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 10px;
`;

export const PredefinedAmountButton = styled.button`
  width: 100px;
  padding: 12px 23px;
  font-size: 16px;
  border: none;
  background-color: ${(props) => (props.isSelected ? "#4caf50" : "#f0f0f0")};
  color: ${(props) => (props.isSelected ? "#ffffff" : "#000000")};
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #4caf50;
    color: #ffffff;
  }
`;

export const InputErrorMessage = styled.div`
  font-size: 14px;
  color: #f44336;
  margin-top: 5px;
`;

export const CustomAmountInput = styled.input`
  padding: 12px;
  font-size: 16px;
  border: 3px solid ${({ isEmpty }) => (isEmpty ? "#f44336" : "#ccc")};
  border: 3px solid ${({ warningMessage }) => (warningMessage ? "#f44336" : "#ccc")};
  border-radius: 4px;
  margin-bottom: 10px;
  transition: border-color 0.3s;
  position: relative;

  &:focus {
    border-color: ${({ isEmpty }) => (isEmpty ? "#f44336" : "#4caf50")};
    border-color: ${({ warningMessage }) => (warningMessage ? "#f44336" : "#4caf50")};
  }
`;

export const InputAmountDescContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 5px;
`;

export const InputAmountDesc = styled.div`

`;

export const InputAmount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  ::after {
    content: "${(props) => props.activeSymbol}";
    margin-left: 10px;
    color: green;
    font-size: 18px;
  }
`;

export const ContinueButton = styled.button`
  padding: 12px 24px;
  font-size: 18px;
  background-color: #4caf50;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #45a049;
  }
`;

export const ModalContentMessage = styled.div`
  height: 300px;
  padding: 30px 5px;
`;
export const ModalContentUrl = styled.div`
  width: 100%;
  // height: 900px; 
  padding: 30px 5px;
`;

export const IframeModal = styled(Modal)`
  @media (max-width: 768px) {
    width: 90%;
    max-width: 90%;
  }
`;

export const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 15px;
`;

export const ModalCloseButton = styled.button`
  background-color: transparent;
  color: #333;
  padding: 10px 15px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f1f1f1;
  }
`;

export const DateTimeText = styled.small`
  max-width: 92px;
  text-align: center;
  color: #888;
  font-size: 14px;
`;
