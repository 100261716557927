import { fansupportPublic } from "../index";
/**
 * This function sends a POST request to the server to retrieve the data of an email address for password reset.
 *
 * @async
 * @function
 * @param {string} email - The email address of the user.
 * @throws {Error} If the request fails.
 * @return {Promise} A Promise that resolves to the response data or rejects with an error.
 */
export const getForgotPassword = async ( email ) => {
    try {
        const data = await fansupportPublic.post('/identity/forgot-password', {
            value: email
    })
        return data;

    } catch (error) {
        console.log("getForgotPassword", error);
    }
}