import { fansupportPublic } from "../index";
/**
 * This function checks if the username is taken.
 * It takes one parameter:
 * - Value: a string representing the username to check.
 * 
 * It returns a Promise that resolves to the response data if the request is successful,
 * or rejects with an error if the request fails.
 * The response data is an object with the following properties:
 * - isSuccess: a boolean indicating whether the request was successful or not.
 * - message: a string containing an error message if the request is unsuccessful, or null otherwise.
 * - data: an object containing the data about the username if the request is successful, or null otherwise.
 * 
 * The data object has the following properties:
 * - isTaken: a boolean indicating whether the username is taken or not.
 * 
 * Example usage:
 * 
 * const username = "example";
 * const res = await getUsername({ Value: username }); 
 * if (res.data?.data?.isTaken) {
 *   console.log("Username is taken!");
 * } else {
 *   console.log("Username is available!");
 * }
 */
export const getUsername = async ({ Value }) => {
    return fansupportPublic.get('/identity/check-username', {params: {
        Value,
    }})
}