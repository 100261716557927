import { fansupportPublic } from "./index";
/**
 * Asynchronously fetches fixtures from the server based on specified filters.
 *
 * @async
 * @function
 * @name getFixture
 * @param {Object} options - An object containing the following properties:
 * @param {string} options.sportId - The ID of the sport to filter fixtures by.
 * @param {string} options.countryId - The ID of the country to filter fixtures by.
 * @param {string} options.tournamentId - The ID of the tournament to filter fixtures by.
 * @param {string} options.seasonId - The ID of the season to filter fixtures by.
 * @param {string} options.teamId - The ID of the team to filter fixtures by.
 * @param {string} options.stageId - The ID of the stage to filter fixtures by.
 * @param {string} options.roundId - The ID of the round to filter fixtures by.
 * @return {Promise<Object>} A Promise that resolves to fixture data in JSON format.
 * @throws {Error} If an error occurs during the request.
 */
export const getFixture = async ({sportId, countryId, tournamentId, seasonId, teamId, stageId, roundId}) => {    
    return fansupportPublic.get('/sport/fixtures', { params: {
        sportId,
        countryId,
        tournamentId,
        seasonId,
        teamId,
        stageId,
        roundId,
    }})
}