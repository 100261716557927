/**
 * This code defines a reusable `Loader` component using React and styled-components.
 * The `Loader` component is a customizable loading spinner, allowing you to specify 
 * its size, color, and border thickness via props. The spinner is animated to rotate 
 * infinitely, giving the visual effect of loading. This is ideal for indicating 
 * asynchronous operations in a UI.
 */
import React from 'react'
import styled from 'styled-components'

const Loader = ({ size, color, borderSize }) => {
  return (
    <LoaderWrapper>
      <Loaders size={size} color={color} borderSize={borderSize}></Loaders>
    </LoaderWrapper>
  )
}

export default Loader

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Loaders = styled.div`
  border: ${({ borderSize }) => borderSize}px solid #f3f3f3;
  border-radius: 50%;
  border-top: ${({ borderSize }) => borderSize}px solid ${({ color }) => color};
  border-bottom: ${({ borderSize }) => borderSize}px solid ${({ color }) => color};
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;

  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`