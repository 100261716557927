// Validation schema for user registration, ensuring that username, first name, last name, phone number, email, birthday, password, and consent checkbox adhere to specific rules and include appropriate error messages for invalid input.
import { object, string, date, bool } from "yup";

const userSchema  = (t) => object({   
    // Username, first name, and last name fields: must be strings with at least 2 characters
    username: string().min(2).required(t("numberCharactersLeastTwo")),
    firstname: string().min(2).required(t("numberCharactersLeastTwo")),
    lastname: string().min(2).required(t("numberCharactersLeastTwo")),
    phoneNumber: string().min(7,t("enterValidPhoneNumber")).required(t("fieldEmpty")), // Phone number field: must be a string with at least 7 characters

    email: string().email().required(t("invalidEmailError")), // Email field: must be a valid email format

    // Birthday field: must be a valid date within a specific range (not older than 100 years and not younger than 18 years)
    birthday: date()
        .required(t("enterValidDate"))
        .min(new Date(new Date().setFullYear(new Date().getFullYear() - 100)), t("ageCannotExceed100"))
        .max(new Date(new Date().setFullYear(new Date().getFullYear() - 18)), t("notBeEighteenAge"))
        .default(() => new Date()),

    // Password field: must be a string with at least 8 characters, including at least one uppercase letter and one digit
    password: string().min(8, t("numberCharactersLeastEight"))
        .matches(/[A-Z]/, t("mustContainUppercase"))
        .matches(/[0-9]/, t("mustContainDigit"))
        .required(t("fieldEmpty")),
    // Consent field: must be checked (true) to confirm agreement
    isKvKK: bool().oneOf([true], t("confirmUserAndClearConsentAgreement")),  
})

export default userSchema