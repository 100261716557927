/**
 * The DateTimeFormatter component formats a given date and time based on the specified language and
 * format options, with an option to display only the day and month.
 * @returns The DateTimeFormatter component is returning a formatted date based on the provided API
 * date, language, and formatting options. If the date is successfully formatted, it will return the
 * formatted date. If onlyDayAndMonth is true, it will return only the day and month part of the
 * formatted date. If there is an error during the formatting process, it will log an error message to
 * the console and return an
 */
import React from 'react';

const DateTimeFormatter = ({ apiTarih, dil, onlyDayAndMonth }) => {
  const languageOptions = {
    'tr': 'tr-TR',
    'en': 'en-US',
    'es': 'es-ES'
  };

  const formatOptions = {
    'tr': {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    },
    'en': {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    },
    'es': {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    }
  };

  try {
    const dateObject = new Date(apiTarih);
    if (isNaN(dateObject.getTime())) {
      throw new Error('Invalid date');
    }

    const formattedTarih = new Intl.DateTimeFormat(languageOptions[dil], formatOptions[dil]).format(dateObject);
    
    if (onlyDayAndMonth) {
      const [day, month] = formattedTarih.split(" ");
      return <>{`${day} ${month}`}</>;
    }
    
    return <>{formattedTarih}</>;
    
  } catch (error) {
    console.error('Error formatting date:', error);
    return <></>;
  }
};

export default DateTimeFormatter;