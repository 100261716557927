/* This code snippet is defining a React functional component called `NewsFromUsCard` that represents a
card component for displaying news items. Here's a breakdown of what the code is doing: */
import React from "react";
import styled from "styled-components";
import DateTimeFormatter from "../languages-currency/DateTimeFormatter";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import RemoveTurkishChars from "../../logic/RemoveTurkishChars";
import { FaLongArrowAltRight } from "react-icons/fa";
import { getStartLanguage } from "../../logic/getStartLanguage";

const NewsFromUsCard = ({ id, date, title, desc, image }) => {


    const navigate = useNavigate();
    const { t } = useTranslation();
    const startLanguage = getStartLanguage();

    const handleCardClick = () => {
        navigate(`/${startLanguage}/${RemoveTurkishChars({ text: t("newsFromUs") })}/${id}`);
    };

  return (
    <NewsFromUsContainer className='campaign-card'>        
        <Card>
            <DateWrapper>
                <DateTitle><DateTimeFormatter apiTarih={date} dil="tr" onlyDayAndMonth={true} /></DateTitle>
                <DateMonth></DateMonth>
                
            </DateWrapper>
            <CardTitle>{title}</CardTitle>
            <CardDesc>
                {desc.substring(0, 79)}...
                <ShowMoreButton onClick={handleCardClick}>{t("showMore")}</ShowMoreButton>
            </CardDesc>
            <CardImageWrapper>
                <CardImg src={image || "https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/c039dc19-4d88-4b95-0180-445a54c94c00/original"} alt="news"></CardImg>
            </CardImageWrapper>
        </Card>
        <CardButtonWrapper>
            <CardButton onClick={handleCardClick}>
                <FaLongArrowAltRight size={22}/>
            </CardButton>
        </CardButtonWrapper>
    </NewsFromUsContainer>
  );
};

export default NewsFromUsCard;

export const NewsFromUsContainer = styled.div`
    background: var(--bg-navbar);
    border-radius: 10px;
    transition: all 0.3s;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    &:hover {
        background-color: #011f27;
        color: white;
        transition: all 0.8s;
    }
`;
export const Card = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 250px;
    height: 400px;
    padding: 30px 30px 0 30px;
    color: #fff;    
`;
export const DateWrapper = styled.div`
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 2px solid;
    display: inline-block;
    opacity: 0.7;
    transition: opacity 0.3s;
`;
export const DateTitle = styled.span`
    font-size: 32px;
    margin-bottom: 10px;
    font-weight: 500;
`;
export const DateMonth = styled.span`
    font-size: 16px;
`;
export const CardTitle = styled.div`
    font-size: 25px;
    margin-top: 10px;
    margin-bottom: 15px;
    transition: opacity 0.3s;
`;
export const CardDesc = styled.div`
  margin: 10px 0;
  line-height: 1.6em;
  font-size: 15px;
  opacity: 0.7;
  transition: opacity 0.3s;
  font-weight: 300;
`;
export const CardImageWrapper = styled.div`
    border-radius: 10px;
    margin-top: 10px;
    width: 100%;
    transition: all 0.3s;
    transform-origin: 0% 0%;
`;
export const CardImg = styled.img`
    width: 100%;
    height: 150px;
    object-fit: contain;
    border-radius: 10px;
`;

export const CardButtonWrapper = styled.div`
    display: flex;
    justify-content: end;
`;
export const CardButton = styled.button`
  background-color: transparent;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
`;
export const ShowMoreButton = styled.button`
  background: none;
  border: none;
  color: white;
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
  padding: 0;
  margin: 0;
`;