/**
 * The function `RegisterPassword` in the provided code snippet handles password input validation and
 * strength assessment in a React component.
 * @returns The `RegisterPassword` component is being returned, which includes a password input field
 * with strength meter and password requirements displayed in a popover.
 */
import React, { useState } from 'react';
import { IconX, IconCheck } from '@tabler/icons-react';
import { PasswordInput, Progress, Text, Popover, Box } from '@mantine/core';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const PasswordRequirement = ({ meets, label }) => {
  return (
    <Text
      color={meets ? 'green' : 'red'}
      sx={{ display: 'flex', alignItems: 'center' }}
      mt={7}
      size="md"
    >
      {meets ? <IconCheck size="0.9rem" /> : <IconX size="0.9rem" />} <Box ml={10}>{label}</Box>
    </Text>
  );
};

const RegisterPassword = ({ value, handleChange }) => {
  const { t } = useTranslation();

  const [popoverOpened, setPopoverOpened] = useState(false);

  const requirements = [
    { re: /[0-9]/, label: t('includesNumber') },
    { re: /[a-z]/, label: t('includesLowercaseLetter') },
    { re: /[A-Z]/, label: t('includesUppercaseLetter') },
    { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: t('includesSpecialSymbol') },
  ];

  const getStrength = (password) => {
    let multiplier = password.length > 7 ? 0 : 1;

    requirements.forEach((requirement) => {
      if (!requirement.re.test(password)) {
        multiplier += 1;
      }
    });

    return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10);
  };

  const PasswordStrength = ({ value }) => {
    const strength = getStrength(value);
    const color = strength === 100 ? 'teal' : strength > 50 ? 'yellow' : 'red';

    return (
      <>
        <Progress color={color} value={strength} size={7} mb="xs" />
        <h4>{t('passwordAdvice')}</h4>
        <StyledPasswordRequirement label={t("IncludesAtLeastCharacters")} meets={value.length > 7} />
        {requirements.map((requirement, index) => (
          <StyledPasswordRequirement
            key={index}
            label={requirement.label}
            meets={requirement.re.test(value)}
          />
        ))}
      </>
    );
  };

  return (
    <div opened={popoverOpened} position="bottom" width="target" transitionProps={{ transition: 'pop' }}>
      <div>
        <div
          onFocusCapture={() => setPopoverOpened(true)}
          onBlurCapture={() => setPopoverOpened(false)}
        >
          <StyledPasswordInput
            id="password-input" 
            name="password"
            size="lg"
            my="lg"
            placeholder={t("yourPassword")}
            value={value}
            onChange={handleChange}
          />
        </div>
      </div>
      <div style={{display: popoverOpened ? "block" : "none"}} className='animate__animated animate__fadeInRight'>
        <PasswordStrength value={value} />
      </div>
    </div>
  );
};

export default RegisterPassword;

const StyledPasswordInput = styled(PasswordInput)`
  width: 100%;

  input::placeholder {
    font-size: 14px;
    color: #999; 
  }
`;

const StyledPasswordRequirement = styled(PasswordRequirement)`
  margin-top: 10px;
`;