import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from "react-i18next";
import announcement from "../../assets/announcement.svg";
import { Breadcrumbs, CampaignCard, CampaignDonorsList, SocialDetailCard } from '../../components';
import { getCampaign } from '../../services/campaign/campaignService';

/* 
  `OrganisationsDetail` component displays detailed information about a specific campaign.
  It retrieves data based on the campaign ID extracted from the URL and renders various components,
  including details about the campaign, social support, and top donors.
*/

const OrganisationsDetail = () => {
  /* 
    State variable for storing campaign data.
    - `socialData`: Contains information about the campaign and its related data.
  */

  const [socialData, setSocialData] = useState([]);

  /* 
    `useTranslation` hook from react-i18next for handling translations.
  */
  const {t} = useTranslation();

  /* 
    Asynchronous function to fetch campaign details.
    - Extracts the campaign ID from the URL.
    - Calls the `getCampaign` service function with the campaign type and ID.
    - Updates the `socialData` state with the fetched campaign data.
  */
  const fetchOrganisationsDetail = async () => {
      // Extract campaign ID from the URL
      const url = window.location.href;
      const pathId = url.substring(url.lastIndexOf('/') + 1);
    
      const campaignType = 6; // Fixed campaign type for this detail view
      const id = pathId;
      // Fetch campaign data using the ID and campaign type
      const res = await getCampaign({ campaignType, id });
      setSocialData(res?.data?.data?.data[0]);
  }

  /* 
    `useEffect` hook to call `fetchOrganisationsDetail` when the component mounts.
    - Ensures the campaign data is fetched and state is updated upon component render.
  */

  useEffect(()=> {
    fetchOrganisationsDetail();
  },[])

  return (
    <OrganisationsDetailMain className='organisations-detail-main'>
        <OrganisationsDetailBackground>
          <OrganisationsDetailImg src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/adee39a7-f3c1-43f4-81da-8cbd1a26f500/original" />

          <OrganisationsDetailTitleBreadcrumbsContainer>
            <OrganisationsDetailTitleContainer>
              <OrganisationsDetailIcon src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/5a644523-45d9-4e66-7609-a62c110a2f00/original" />
              <OrganisationsDetailTitle>{t("socialSupportCampaign")} </OrganisationsDetailTitle>
            </OrganisationsDetailTitleContainer>

            <Breadcrumbs />
          </OrganisationsDetailTitleBreadcrumbsContainer>
        </OrganisationsDetailBackground>

        <OrganisationsDetailContent>

            <CampaignDetailJoinDescContainer>

              <CampaignTransferContent className='campaign-transfer-content'>
                {socialData && (
                  <CampaignDetailContentLeftTitle>
                    {socialData.description}
                  </CampaignDetailContentLeftTitle>
                )}
                <SocialDetailCard
                  data={socialData}
                />

              </CampaignTransferContent>
                
            </CampaignDetailJoinDescContainer>

            <CampaignDetailTopTenContainer>

              <CampaignCard
                icon={announcement}
                title={t("campaignDonors")}
                desc={t("top10List")}
                cardWidth="100%"
                responsiveSmallWidth="100%"
                responsiveWidth="100%"
                responsiveDonorListWidth="100%"
                cardHeight="none"
                contentHeight="100%"
                cardPadding="10px"
                seeAllText=" "
                contents={
                  <CampaignDonorsListContainer>

                  {socialData?.detail?.topParticipants ? (
                    <CampaignDonorsList
                      title={t("general")}
                      participants={socialData?.detail?.topParticipants}
                      logo={socialData?.detail?.logoUrlLarge}           
                    />
                  ) : (
                    <CampaignDonorsList
                      title={t("general")}                  
                    />
                  )}

                  {/* {socialData?.homeTeamParticipants ? (
                    <CampaignDonorsList
                      title={socialData?.homeTeam?.name || ""}
                      participants={socialData?.homeTeamParticipants}
                      logo={socialData?.homeTeam?.largeBadge}
                    />
                  ) : (
                    <CampaignDonorsList
                      title={socialData?.homeTeam?.name || ""}                  
                    />
                  )}

                  {socialData?.awayTeamParticipants ? (
                    <CampaignDonorsList
                      title={socialData?.awayTeam?.name || ""}
                      participants={socialData?.awayTeamParticipants}
                      logo={socialData?.awayTeam?.largeBadge}
                    />
                  ) :(
                    <CampaignDonorsList
                      title={socialData?.awayTeam?.name || ""}                  
                    />
                  )} */}

                </CampaignDonorsListContainer>
                }
              >
              </CampaignCard>
                
            </CampaignDetailTopTenContainer>

        </OrganisationsDetailContent>

    </OrganisationsDetailMain>
  )
}

export default OrganisationsDetail

export const OrganisationsDetailMain = styled.main`
    background-color: #FAFAFA;
    padding-bottom: 80px;
`;

export const OrganisationsDetailBackground = styled.div`
  width: 100%;
`;

export const OrganisationsDetailImg = styled.img`
  width: 100%;
  height: 230px;
`;

export const OrganisationsDetailTitleBreadcrumbsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  bottom: 4px;
  padding: 10px 30px;
  background: var(--bg-navbar);
`;

export const OrganisationsDetailTitleContainer = styled.div`
  display: flex;
`;

export const OrganisationsDetailIcon = styled.img`
  position: relative;
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 15px;
  padding: 5px;
`;

export const OrganisationsDetailTitle = styled.div`
  position: relative;
  // width: 250px;
  top: 15px;
  left: 25px;
  font-size: 24px;
  font-weight: 500;
  color: white;
`;

export const OrganisationsDetailContent = styled.section`
  max-width: 1100px;
  margin: 0 auto 50px auto;
`;

export const CampaignDetailJoinDescContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 22px;
  width: 100%;
  margin: 50px auto;
`;
export const CampaignTransferContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
  width: 100%;
  padding: 50px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #dbe0e5;

  &:hover {
    box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16); 
  }

  @media (max-width: 720px) {
    width: 100%;
    margin: 0 20px;
  } 
`;
export const CampaignDetailContentLeftTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
`;
export const CampaignDetailTopTenContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const CampaignDonorsListContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin: 0 20px;

  @media screen and (max-width: 1024px) {
    flex-wrap: wrap;
    // gap: 10px;
  }
`;