import { fansupportPublic } from "../index"
/**
 * Asynchronously fetches a document based on the given type.
 * 
 * @async
 * @function
 * @name getDocument
 * @param {Object} options - An object containing the following properties:
 * @param {string} options.type - The type of document to fetch.
 * @return {Promise<Object>} A Promise that resolves to the fetched document data in JSON format.
 * @throws {Error} If an error occurs during the request.
 */
export const getDocument = async ({ type }) => {
    return fansupportPublic.get('/document', {params: {
        type,
    }})
}