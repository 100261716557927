/* This code snippet is a React component named `CampaignGeneralSuccess` that displays a campaign box
with specific details. Here's a breakdown of what the code is doing: */
import React from "react";
import styled from "styled-components";
import { JoinButton, CurrencyData, CurrencyFormat } from "../../components";
import { NotFound } from "../../pages";
import {BsChevronRight} from "react-icons/bs";
import { useTranslation } from "react-i18next";
import RemoveTurkishChars from "../../logic/RemoveTurkishChars";
import CampaignType from "../../enums/CampaignType";
import { getStartLanguage } from "../../logic/getStartLanguage";

const CampaignGeneralSuccess = ({campaignData}) => {

  const symbol = '₺';


  // console.log("campaignData",campaignData);

  const { t } = useTranslation();

  const convertToTitleCase = (str) => {
    return str.charAt(0).toLowerCase() + str.slice(1);
  };

  const handleLogoClick = (team, teamId) => {

    if (team, teamId) {
      const startLanguage = getStartLanguage();
      const sportType= campaignData.detail.sportName.replace(/ /g, "-").toLowerCase();
      const formattedCampaignType = RemoveTurkishChars({ text: t("team") });
      const formattedTeamName = convertToTitleCase(team);

      window.location.href = `/${startLanguage}/${sportType}/${formattedCampaignType}/${formattedTeamName}/${teamId}`;
      // console.log(`Tıklanan takım: ${formattedTeamName}`);
      // console.log("Tıklanan takım", teamId);

    } else{
      return <NotFound />
    }


  };

  const handleJoinClick = (campaign) => {

    if (campaign) {
      const startLanguage = getStartLanguage();
      const sportType = campaignData.detail.sportName.replace(/ /g, "-").toLowerCase();
      const campaignType = campaignData.type;
      const campaignTypeName = CampaignType[campaignType];
      const formattedCampaignType = RemoveTurkishChars({ text: t(campaignTypeName) });
      const leagueTypeSlug = campaignData.detail.tournamentName?.replace(/ /g, "-").toLowerCase();
      const campaignTitle = campaignData.title.replace(/ /g, "-").toLowerCase();
      const formattedCampaignTitle = RemoveTurkishChars({ text: campaignTitle });

      const url  = `/${startLanguage}/${sportType}/${formattedCampaignType}/${leagueTypeSlug}/${formattedCampaignTitle}/${campaign}`;
      
      window.location.href = url;
  
    } else  {
      return <NotFound />
      // console.log("Geçersiz campaignId, sayfaya gidilemiyor.");
    }
  }
 
  return (
    <>
      <CampaignsBoxContent className='campaign-box-content'>
        <CampaignsBox>
          <CampaignsBoxWrapper>
            <div onClick={() => handleLogoClick(campaignData.detail.homeTeam.name, campaignData.detail.homeTeam.id)}>
              <LogoFirstImage src={ campaignData?.detail?.homeTeam?.extraLargeBadge ? campaignData?.detail?.homeTeam?.extraLargeBadge : "https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/6f6a87c5-fd75-45c3-0f8c-33977a168700/original"} alt="" />
            </div>
          
            <CampaignsBoxDesc>
              <CampaignTitle>{campaignData?.title}</CampaignTitle>

              <CampaignsBoxCenter>
                <CampaignsBoxAmount symbol={symbol}>
                <CurrencyFormat amount={campaignData?.detail?.homeAmount || 0}/>
                </CampaignsBoxAmount>          
              </CampaignsBoxCenter>
            </CampaignsBoxDesc>

          </CampaignsBoxWrapper>

          <div onClick={() => handleJoinClick(campaignData?.id) }>
            <JoinButton
                width="50px"
                backgroundColor="transparent"
                textColor="black"
              >
              <BsChevronRight size={25} />
            </JoinButton>
          </div>
        </CampaignsBox>
      </CampaignsBoxContent>
    </>
  );
};

export default CampaignGeneralSuccess;

export const CampaignsBoxContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  padding: 10px;
  border-top: 1px solid #eee;

  &:hover {
    scale: 1.1;
    transition-duration: 0.6s;    
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
`;
export const CampaignsBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
export const CampaignsBoxWrapper = styled.div`
  display: flex;
  gap: 10px;
`;
export const LogoFirstImage = styled.img`
  width: 36px;
  height: 36px;
  cursor: pointer;
`;
export const CampaignsBoxDesc = styled.div``;

export const CampaignsBoxAmount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #555;

  ::after {
    content: "${(props) => props.symbol}";
    margin-left: 8px;
    color: #4caf50;
    font-size: 16px;
  }
`;

export const CampaignsBoxCenter = styled.div`
  display: flex;
`;

export const CampaignTitle = styled.div`
  // max-width: 150px;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // white-space: nowrap;
  font-size: 13px;
`;