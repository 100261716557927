import { fansupportPublic } from "../index";
/**
 * Asynchronously retrieves the seasons of a specific tournament from the API.
 *
 * @async
 * @function
 * @name getTournamentSeasons
 * @param {Object} options - An object containing the following properties:
 * @param {string} options.tournamentId - The ID of the tournament to get seasons for.
 * @returns {Promise<Object>} A Promise that resolves to the fetched data in JSON format.
 * @throws {Error} If there is an error during the API request.
 */
export const getTournamentSeasons= async ({ tournamentId }) => {
    return fansupportPublic.get('/sport/tournament-seasons',{ params: { 
        tournamentId,
    }})
}