/**
 * The `GoalKingCard` component renders a card displaying an avatar and name of a football player,
 * along with an icon.
 * @returns The `GoalKingCard` component is being returned, which consists of a styled card container
 * (`GoalKingCardContainer`) with an avatar wrapper (`GoalKingAvatarWrapper`) containing an image
 * (`GoalKingImage`) and a description, as well as an icon (`GoalKingIcon`).
 */
import React from 'react';
import styled from 'styled-components';

const GoalKingCard = () => {
  return (
    <>
        <GoalKingCardContainer>

            <GoalKingAvatarWrapper>
                <GoalKingImage src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/8df7e448-aadc-415f-2741-4628ba979100/original" />
                <GoalKingImageDesc>Henry Onyekuru</GoalKingImageDesc>
            </GoalKingAvatarWrapper>

            <GoalKingIcon src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/ad470bf7-b1a6-4546-6322-6b96e7bacb00/original" />

        </GoalKingCardContainer>
    </>
  )
}

export default GoalKingCard

export const GoalKingCardContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 20px 30px;
`;

export const GoalKingAvatarWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
`;

export const GoalKingImage = styled.img`
    width: 60px;
    height: 60px;
    border-radius: 50%;
`;

export const GoalKingImageDesc = styled.div`
`;

export const GoalKingIcon = styled.img`
width: 50px;
height: 50px;
`;