import { fansupportPublic } from ".."
/**
 * Asynchronously fetches top 100 campaigns based on given filters.
 * @async
 * @function
 * @name getSportTopOneHundred
 * @param {Object} options - An object containing the following properties:
 * @param {string} options.sportId - The ID of the sport to filter campaigns by.
 * @param {string} options.countryId - The ID of the country to filter campaigns by.
 * @param {string} options.tournamentId - The ID of the tournament to filter campaigns by.
 * @param {string} options.teamId - The ID of the team to filter campaigns by.
 * @return {Promise<Object>} A Promise that resolves to campaigns in JSON format.
 * @throws {Error} If an error occurs during the request.
 */
export const getSportTopOneHundred = async ({ sportId, countryId, tournamentId, teamId}) => {
    return fansupportPublic.get('/campaign/top-100', {params: {
        sportId,
        countryId,
        tournamentId,
        teamId,
    }})
}