/* The above code is a React component called `Filterr` that is used to render a filter interface for
selecting sports, countries, tournaments, teams, and seasons. Here is a breakdown of what the code
is doing: */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { getTeamsThunk, getStandingsThunk, getFixtureThunk, getCountryThunk, getTournamentThunk, getRoundThunk, setTeamId, setSportId, setCountryId, setTournamentId, setSeasonId, getSeasonsThunk, setStageId, setRoundId } from "../../redux/features/filter/filterSlice.js"; 
import { getTournamentSeasons } from "../../services/sport/tournamentSeasonsService.js";
import { useNavigate } from "react-router-dom";
import { FaTrash } from "react-icons/fa";


const Filterr = (props) => {

  const SPORT_ID_KEY = "selectedSportId";
  const COUNTRY_ID_KEY = "selectedCountryId";
  const TOURNAMENT_ID_KEY = "selectedTournamentId";
  const TEAM_ID_KEY = "selectedTeamId";
  const SEASON_ID_KEY = "selectedSeasonId";

  const { contentWidth, margin, useNavigateProp, showTeamSelect = true, showSeasonSelect = false } = props;  

  // const [seasonsData, setSeasonsData] = useState([]);

  const filterTeams = useSelector((state) => state.filter.filterTeams);
  const seasonsData = useSelector((state) => state.filter.seasonsData);
  const currentCountryId = useSelector((state) => state.filter.countryId);
  const currentTournamentId = useSelector((state) => state.filter.tournamentId);
  const currentTeamId = useSelector((state) => state.filter.teamId);
  const currentSeasonId = useSelector((state) => state.filter.seasonId);

  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    sportId,
    countryId,
    tournamentId,
    roundId,
    sportData,
    countryData,
    tournamentData,
    language,
  } = useSelector((state) => state.filter);

  const {t} = useTranslation();

  const getFilter = async () => {
    dispatch(getCountryThunk(sportId));
    dispatch(getTournamentThunk({countryId, sportId}));
  };

  const removeTurkishChars = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g,'');
  };

  const sortedSportData = sportData ? [...sportData].sort((a, b) => a.enumId - b.enumId) : [];

  const selectSportID = async (e) => {
    const newSportId = e.target.value;
    const selectedSport = e.target.options[e.target.selectedIndex].text;
    const formattedSport = removeTurkishChars(selectedSport.toLowerCase()).replace(/\s+/g,'-');
    localStorage.setItem(SPORT_ID_KEY, newSportId);
    localStorage.setItem("selectedSportName",formattedSport);
    dispatch(setSportId(newSportId));
    dispatch(setTeamId(""));
    localStorage.removeItem(TEAM_ID_KEY);
    localStorage.removeItem("selectedTeam");
    dispatch(setSeasonId(""));
    localStorage.removeItem("selectedSeasonId");
    dispatch(setStageId(""));
    localStorage.removeItem("stageIds");
    localStorage.removeItem("stageIdsName");
    dispatch(setRoundId(""));
    localStorage.removeItem("roundIds");
    dispatch(getCountryThunk(newSportId));
    dispatch(getTournamentThunk({countryId, sportId: newSportId}));
  };

  const selectCountryId = async (e) => {
    localStorage.removeItem("selectedTeamName");
    localStorage.removeItem("selectedTournamentName");
    localStorage.removeItem(TEAM_ID_KEY);
    localStorage.removeItem(TOURNAMENT_ID_KEY);
    dispatch(setTournamentId(""));
    dispatch(setTeamId(""));
    dispatch(setSeasonId(""));
    localStorage.removeItem("selectedSeasonId");
    dispatch(setStageId(""));
    localStorage.removeItem("stageIds");
    localStorage.removeItem("stageIdsName");
    dispatch(setRoundId(""));
    localStorage.removeItem("roundIds");
    const newCountryId = e.target.value;
    const selectedCountry = e.target.options[e.target.selectedIndex].text;
    const formattedCountry = removeTurkishChars(selectedCountry.toLowerCase()).replace(/\s+/g,'-');
    localStorage.setItem("selectedCountryName",formattedCountry);
    localStorage.setItem(COUNTRY_ID_KEY, newCountryId);
    dispatch(getTournamentThunk({countryId: newCountryId, sportId}))
  };

  const selectTournamentId = (e) => {
    // localStorage.removeItem("selectedTeam");
    const newTournamentId = e.target.value;
    const selectedTournament = e.target.options[e.target.selectedIndex].text;
    const formattedTournament = removeTurkishChars(selectedTournament.toLowerCase()).replace(/\s+/g,'-');
    localStorage.setItem("selectedTournamentName",formattedTournament);
    localStorage.setItem(TOURNAMENT_ID_KEY, newTournamentId);
    dispatch(getSeasonsThunk({tournamentId: newTournamentId}));
    dispatch(setRoundId(""));
    localStorage.removeItem("roundIds");
    dispatch(getTeamsThunk({sportId, countryId, tournamentId: newTournamentId}));
    dispatch(getStandingsThunk({sportId, countryId, tournamentId: newTournamentId}));
  };

  const selectTeamId = (e) => {
    const newTeamId = e.target.value;
    const selectedTeam = e.target.options[e.target.selectedIndex].text;
    const formattedTeam = removeTurkishChars(selectedTeam.toLowerCase()).replace(/\s+/g,'-');
    localStorage.setItem("selectedTeamName",formattedTeam);
    localStorage.setItem(TEAM_ID_KEY, newTeamId);
    dispatch(setTeamId(newTeamId));
  };

  const selectSeasonId = (e) => {
    const newSeasonId = e.target.value;
    localStorage.setItem(SEASON_ID_KEY,newSeasonId);
    dispatch(setSeasonId(newSeasonId));
  };

  // const fetchTournamentSeasons = async () => {
  //   const res = await getTournamentSeasons({tournamentId: currentTournamentId})
  //   setSeasonsData(res);
  // }

  const handleResetFilter = () => {

    if (useNavigateProp) {
      localStorage.removeItem(SPORT_ID_KEY);
      localStorage.removeItem(COUNTRY_ID_KEY);
      localStorage.removeItem(TOURNAMENT_ID_KEY);
      localStorage.removeItem(TEAM_ID_KEY);
      localStorage.removeItem(SEASON_ID_KEY);
      localStorage.removeItem("selectedTeam");
      localStorage.removeItem("selectedSportName");
      localStorage.removeItem("selectedCountryName");
      localStorage.removeItem("selectedTournamentName");
      localStorage.removeItem("selectedTeamName");
      localStorage.removeItem("stageIds");
      localStorage.removeItem("stageIdsName");
      localStorage.removeItem("roundIds");
  
      dispatch(setSportId(""));
      dispatch(setCountryId(""));
      dispatch(setTournamentId(""));
      dispatch(setTeamId(""));
      dispatch(setSeasonId(""));
      dispatch(setRoundId(""));
    } else {
      localStorage.removeItem(SPORT_ID_KEY);
      localStorage.removeItem(COUNTRY_ID_KEY);
      localStorage.removeItem(TOURNAMENT_ID_KEY);
      localStorage.removeItem(TEAM_ID_KEY);
      localStorage.removeItem(SEASON_ID_KEY);
      localStorage.removeItem("selectedTeam");
      localStorage.removeItem("selectedSportName");
      localStorage.removeItem("selectedCountryName");
      localStorage.removeItem("selectedTournamentName");
      localStorage.removeItem("selectedTeamName");
      localStorage.removeItem("stageIds");
      localStorage.removeItem("stageIdsName");
      localStorage.removeItem("roundIds");
  
      dispatch(setSportId(""));
      dispatch(setCountryId(""));
      dispatch(setTournamentId(""));
      dispatch(setTeamId(""));
      dispatch(setSeasonId(""));
      dispatch(setRoundId(""));
      navigate("/");
    }

  };

  useEffect(() => {
    getFilter();
    // fetchTournamentSeasons();
  }, []);

  useEffect(()=> {
      dispatch(getCountryThunk(sportId));
      dispatch(getTournamentThunk({countryId, sportId}))
      dispatch(getTeamsThunk({countryId, sportId, tournamentId}))
  }, [language, dispatch])

  return (
    <>
      <FilterContainer margin={margin}>

      {/* <ClearButtonContainer>
        <ClearButton  onClick={handleResetFilter}>
          <TrashIcon />
          {t("cleanFilter")}
        </ClearButton>
      </ClearButtonContainer>


        <SelectFirst max-width="960px">

            <SelectContainer contentWidth={contentWidth}>
              <FilterSelectLabel className="filter-select-label">{t("selectSportType")}</FilterSelectLabel>
              <FilterSelect onChange={selectSportID}>
                <option selected={!selectSportID}>{t("selectSportType")}</option>
                {sportData?.length ? sortedSportData.map((sport) => (
                  <option value={sport.id} key={sport.id} selected={sport.id === selectSportID || sport.id === localStorage.getItem(SPORT_ID_KEY)}>
                    {sport.name}
                  </option>
                )) : <option disabled selected>{t("Not found..")}</option>}
              </FilterSelect>
            </SelectContainer>
          
            <SelectContainer contentWidth={contentWidth}>
              <FilterSelectLabel className="filter-select-label">{t("selectCountry")}</FilterSelectLabel>
              <FilterSelect placeholder="Select country" value={currentCountryId} onChange={selectCountryId}>
                <option selected={!currentCountryId}>{t("selectCountry")}</option>
                {countryData?.length ? countryData.map((country) => (
                  <option value={country.id} key={country.id} selected={country.id === currentCountryId}>
                    {country.name}
                  </option>
                )) : <option disabled selected>{t("Not found..")}</option>}
              </FilterSelect>
            </SelectContainer>



        </SelectFirst> */}


          {/* <SelectContainer contentWidth={contentWidth}>
              <FilterSelectLabel className="filter-select-label">{t("selectLeague")}</FilterSelectLabel>
              <FilterSelect placeholder="Select tournament" value={currentTournamentId} onChange={selectTournamentId}>
              <option selected={!currentTournamentId}>{t("selectLeague")}</option>
                {tournamentData?.length ? tournamentData.map((list) => (
                  <option value={list.id} key={list.id}>
                    {list.name}
                  </option>
                )) : <option disabled selected>{t("Not found..")}</option>}
              </FilterSelect>
          </SelectContainer>

          {showTeamSelect && (
            <SelectContainer contentWidth={contentWidth}>
              <FilterSelectLabel className="filter-select-label">{t("selectTeam")}</FilterSelectLabel>
              <FilterSelect placeholder="Select teams" value={currentTeamId} onChange={selectTeamId}>
                <option selected={!currentTeamId}>{t("selectTeam")}</option>
                {filterTeams?.length ? (
                  filterTeams.map((list) => (
                    <option value={list.id} key={list.id}>
                      {list.name}
                    </option>
                  ))
                ) : (
                  <option disabled selected>
                    {t("allTeams")}
                  </option>
                )}
              </FilterSelect>
            </SelectContainer>
          )} */}
          {showSeasonSelect && (
            <SelectContainer contentWidth={contentWidth}>
            <FilterSelectLabel className="filter-select-label">{t("selectSeason")}</FilterSelectLabel>
            <FilterSelect placeholder={t("selectSeason")} value={currentSeasonId} onChange={selectSeasonId}>
            <option selected={!currentSeasonId}>{t("selectSeason")}</option>
              {seasonsData?.length ? seasonsData.map((list) => (
                <option value={list.id} key={list.id}>
                  {list.name}
                </option>
              )) : <option disabled selected>{t("Not found..")}</option>}
            </FilterSelect>
            </SelectContainer>
          )}



      </FilterContainer>
    </>
  );
};

export default Filterr;

export const FilterContainer = styled.div`
  max-width: 960px;
  margin: ${(props) => props.margin || '60px auto'};
  padding: 15px;

  @media screen and (min-width: 900px) and (max-width: 1412px) {
    width: 68%
  }
`;

export const SelectFirst = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  max-width: ${(props) => props.width || '960px'};
`;

export const SelectSecond = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  max-width: ${(props) => props.width || '960px'};
`;

export const SelectThird = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 10px;
  max-width: ${(props) => props.width || '960px'};

  @media screen and (min-width: 990px) and (max-width: 1412px) {
    justify-content: center;
  }

  @media (max-width: 990px) {
    justify-content: center;
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.contentWidth || "100%"};
  margin: 10px 0;

  @media (max-width: 500px){
    width: ${(props) => props.contentWidth || "100%"}; 
  }
`;

export const FilterSelectLabel = styled.div`
  margin: 7px 10px;
  text-align: start;
  font-size: 13px;
  font-weight: bold;
  position: inherit;
  color: var(--color);
`;

export const FilterSelect = styled.select`
  height: 50px;
  padding: 0 20px 0 10px;
  border-radius: 10px;
  font-size: 15px;

  // background-color: #f1f1f1;
  // color: #333;
  // border: none;
  // outline: none;
  // cursor: pointer;

  // &:hover {
  //   background-color: #ddd;
  // }
`;

export const ClearButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
`;

export const ClearButton = styled.button`
  background-color: #e74c3c;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #c0392b;
  }
`;

const TrashIcon = styled(FaTrash)`
  margin-right: 8px;
`;