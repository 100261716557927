/**
 * MediaSize is an object that defines various media query breakpoints used for responsive design.
 * It provides a set of standard size values that can be used to adjust the layout and styling 
 * of components based on the screen width.
 *
 * - small: Represents screens with a width up to 600px, typically used for mobile devices.
 * - medium: Represents screens with a width up to 900px, commonly used for tablets and small desktops.
 * - large: Represents screens with a width up to 1200px, generally used for larger desktops and wide screens.
 *
 * This object helps in maintaining consistency across the application when applying responsive styles
 * by centralizing the breakpoint values.
 */
const MediaSize = {
    small: "600px",
    medium: "900px",
    large: "1200px",
}

export default MediaSize;