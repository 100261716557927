import { fansupportPrivate } from "../index"
/**
 * Asynchronously fetches a certificate based on the given campaign ID, size, and format.
 * @async
 * @function
 * @name getCertificate
 * @param {Object} options - An object containing the following properties:
 * @param {string} options.campaignId - The ID of the campaign to fetch the certificate for.
 * @param {number} options.size - The size of the certificate to fetch.
 * @param {string} options.format - The format of the certificate to fetch.
 * @return {Promise<Object>} A Promise that resolves to the fetched certificate data in JSON format.
 * @throws {Error} If an error occurs during the request.
 */
export const getCertificate = async ({ campaignId, size, format }) => {
    try {
        const data = fansupportPrivate.get('/document/certificate', {params: {
            campaignId,
            size,
            format
        }})

        return data;
    } catch (error) {
        console.log("getCertificate",error)
    }
}