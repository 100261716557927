import { fansupportPublic } from "./index";
/**
 * Asynchronously fetches teams from the server based on specified filters.
 *
 * @async
 * @function
 * @name getTeams
 * @param {Object} options - An object containing the following properties:
 * @param {string} options.sportId - The ID of the sport to filter teams by.
 * @param {string} options.countryId - The ID of the country to filter teams by.
 * @param {string} options.tournamentId - The ID of the tournament to filter teams by.
 * @return {Promise<Object>} A Promise that resolves to team data in JSON format.
 * @throws {Error} If an error occurs during the request.
 */
export const getTeams = async ({sportId, countryId, tournamentId}) => {
    return fansupportPublic.get('/sport/filter',{ params: { 
        sportId,
        countryId,
        tournamentId, 
    }})
} 