/* This code snippet is a React component called `RemoveFavoritesCard` that is responsible for
rendering a list of favorite teams with an option to remove them. Here's a breakdown of what the
code is doing: */
import React,{ useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { FavoritesButton } from "../../components";
import { NotFound } from "../../pages";
import { CiCircleRemove } from "react-icons/ci";
import { getDeleteFavoriteTeam } from "../../services/sport/deleteFavoriteTeamService"; 
import { ToastContainer, toast } from "react-toastify"; // Import Toastify
import "react-toastify/dist/ReactToastify.css";
import { getStartLanguage } from "../../logic/getStartLanguage";
import RemoveTurkishChars from "../../logic/RemoveTurkishChars";

const RemoveFavoritesCard = ({favouriteTeamsData, width, fontSize}) => {

    const [favoriteTeams, setFavoriteTeams] = useState([]);

    const {t} = useTranslation();

    // console.log("RemoveFavoritesCard",favouriteTeamsData);

    const isTeamFavorite = (teamId) => favoriteTeams.includes(teamId);

    const handleRemoveClick = (teamId, tournamentId) => {
      // console.log("Remove button clicked for team ID:", teamId);
      // console.log("Remove button clicked for team ID:", tournamentId);
      fetchDeleteFavoriteTeam(teamId, tournamentId);
    };

    const fetchDeleteFavoriteTeam = async (teamId, tournamentId) => {
      try {
          const res = await getDeleteFavoriteTeam({ teamId, tournamentId });
          setFavoriteTeams(res?.data?.data);
  
        if (res?.data?.data === true) {
          toast.error(t("favoriteTeamRemoved")); 
          const updatedFavoriteTeams = favoriteTeams.filter((id) => id !== teamId);
          localStorage.setItem("favoriteTeams", JSON.stringify(updatedFavoriteTeams));
          window.location.reload();
        }

        // setTimeout(() => {
        //   window.location.reload();
        // }, 5000);
  
      } catch (error) {
          console.log("Request failed with error:", error);
      }
  };
  
  // console.log("addfavoriteTeams",favoriteTeams || {});

  const convertToTitleCase = (str) => {
    return str.charAt(0).toLowerCase() + str.slice(1);
  };

  const handleLogoClick = (team, teamId, sportName) => {

    if (team, teamId) {
      const startLanguage = getStartLanguage();
      const formattedTeamName = RemoveTurkishChars({ text: team });
      const sportType = convertToTitleCase(sportName);

      window.location.href = `/${startLanguage}/${sportType}/${RemoveTurkishChars({ text: t("team") })}/${formattedTeamName}/${teamId}`;
      // console.log(`Tıklanan takım: ${formattedTeamName}`);
      console.log("Tıklanan takım", teamId);

    } else{
      return <NotFound />
    }

  };

  return (
    <>
      <ToastContainer />
      <NotFoundsContainer width={width}>
        {favouriteTeamsData.length ? (
          favouriteTeamsData.map((favourite) => (
            <FavoritesCardContainer key={favourite.id} className="remove-favorites-card-container">
              <FavoritesCardLeft
                onClick={() => handleLogoClick(favourite.teamName, favourite.teamId, favourite.sportName)}
              >
                <TeamsLogo src={favourite.largeBadge ? favourite.largeBadge : "https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/6f6a87c5-fd75-45c3-0f8c-33977a168700/original"} alt={favourite.name} />
                <TeamsName>{favourite.teamName}</TeamsName>
              </FavoritesCardLeft>

              <FavoritesCardRight>
                <FavoritesButton 
                  fontSize={fontSize}
                  text={t("remove")} 
                  icon={<CiCircleRemove size={25} />}
                  isAdded={false} 
                  onClick={() => handleRemoveClick(favourite.teamId, favourite.tournamentId)} 
                />              
              </FavoritesCardRight>
            </FavoritesCardContainer>
          ))
        ) : (
          <NotFound text={t("notFoundTeams")} />
        )}
      </NotFoundsContainer>
    </>
  )
}

export default RemoveFavoritesCard

export const FavoritesCardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
`;

export const FavoritesCardLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  @media (max-width: 720px){
    justify-content: start;
    width: 165px;
  }
  
`;

export const TeamsLogo = styled.img`
  width: 36px;
  height: 36px;
`;

export const TeamsName = styled.span``;

export const FavoritesCardRight = styled.div``;

export const NotFoundsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.width || ""};
`;

export const RemoveButton = styled.button`
  background-color: transparent;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 8px 12px;
  cursor: pointer;

  &:hover {
    background-color: #e74c3c;
  }
`;