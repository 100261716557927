/**
 * The VerifyPhoneCheck component handles the final step of the phone verification process.
 * It displays a success or error icon based on the verification status and provides options 
 * for the user to continue or resend the OTP if verification failed.
 * The component integrates various styled components and animations to enhance user experience.
 * It conditionally renders different elements and actions based on whether the phone verification 
 * was successful or not.
 */
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Stepper, Button, Group } from "@mantine/core";
import { useSelector } from 'react-redux';
import Filterr from '../filter/Filterr';
import GeneralButton from '../button/GeneralButton';
import AddFavoritesCard from '../card/AddFavoritesCard';
import { useTranslation } from 'react-i18next';


const VerifyPhoneCheck = ({ prevStep, nextStep }) => {
  const { phoneVerificationData } = useSelector((state) => state.user);

  const { t } = useTranslation();
  
  const phoneVerificationCheck = phoneVerificationData?.data || false;
  return (
    <Stepper.Step label="Final step" description="Get full access">

      <Verification>
    
        {/* <VerifyPrevButton
          leftIcon={<IoMdArrowBack size="32px" />}
          variant="default"
          onClick={prevStep}
        ></VerifyPrevButton> */}
        
        {phoneVerificationCheck ? (
          <CircularBackground>
            <CheckmarkIcon>
              <CheckmarkPath d="M10 20 L15 25 L30 10" />
            </CheckmarkIcon>
          </CircularBackground> 
        ) : (
          <ErrorBackground>
            <ErrorIcon>
              <ErrorPath d="M10 10 L30 30 M30 10 L10 30" />
            </ErrorIcon>
          </ErrorBackground>
        )}

        {phoneVerificationCheck ? 
        <>
          {t("VerifiedText")}                     
          {t("wantToAddTeamToProfile")}                     
        </>
        : 
        <>
          <StatusText>
            {t("notVerifiedText")}
          </StatusText>
          <Group position="center" mt="xl">
            <Button variant="default" onClick={prevStep}>
              {t("resendOtp")}
            </Button>
          </Group>
        </>                       
        }

      </Verification>

      {phoneVerificationCheck ? 
        <>
          <Filterr contentWidth="100%" />
          <p>{t("teams")}</p>
          <AddFavoritesCard />


          <GeneralButton
          width="100%"
          onClick={nextStep}
          >
            {t("cContinue")}                   
          </GeneralButton>
        </>
        : 
        <>                
        </>                       
      }
       
    </Stepper.Step>
  )
}

export default VerifyPhoneCheck

export const Verification = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 450px;
  height: 250px;
  padding: 25px 15px;
  margin: 20px auto;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  @media (max-width: 720px){
    width: 100%;
    gap: 0px;
    padding: 0px;
  }
`;
export const CircularBackground = styled.div`
  width: 100px;
  height: 100px;
  border: 6px solid #00cc00;
  border-radius: 50%; 
  display: flex;
  align-items: center;
  justify-content: center;
  animation: rotate 2s linear infinite;
`;
export const VerifyPrevButton = styled(Button)`
  border: 0;
`;
export const CheckmarkIcon = styled.svg`
  width: 40px;
  height: 40px;
`;
export const drawCheckmark = keyframes`
  0% {
    stroke-dashoffset: 100;
  }
  100% {
    stroke-dashoffset: 0;
  }
`;
export const CheckmarkPath = styled.path`
  fill: none;
  stroke: #00cc00;
  stroke-width: 6;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 100;
  stroke-dashoffset: 100;
  animation: ${drawCheckmark} 1s ease-in-out forwards;
`;
export const ErrorBackground = styled.div`
  width: 100px;
  height: 100px;
  border: 6px solid red;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: rotate 2s linear infinite; 
`;
export const ErrorIcon = styled.svg`
  width: 40px;
  height: 40px;
`;
export const shakeError = keyframes`
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(5px);
  }
`;
export const ErrorPath = styled.path`
  fill: none;
  stroke: red;
  stroke-width: 6;
  stroke-linecap: round;
  stroke-linejoin: round;
  animation: ${shakeError} 0.5s ease-in-out 5; /* Shake animasyonu */
`;
export const StatusText = styled.p`
  font-size: 17px;
  width: 350px;
  text-align: center;
  font-weight: 400;
`;