/**
 * The `CampaignDonorsThreeCard` component in JavaScript React renders a card displaying donor
 * information with avatars, usernames, team names, and donation amounts in a styled layout.
 * @returns The `CampaignDonorsThreeCard` component is being returned, which consists of styled
 * components for displaying donor information in a card format. The component includes the donor's
 * avatar, username, team name, and donation amount formatted with a currency symbol. The styling is
 * done using styled-components in React.
 */
import React from "react";
import styled from "styled-components";
import { CurrencyData, CurrencyFormat } from "../../components";

const CampaignDonorsThreeCard = (
  { 
    username,
    desc, 
    firstAmount, 
    joinId, 
    logoFirst, 
    homeId,
    homeLogo,
    awayId, 
    awayLogo, 
    isOdd 
  }
  ) => {

  let avatarSource;

  if (homeId === joinId) {
    avatarSource = homeLogo ? homeLogo : "https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/5a644523-45d9-4e66-7609-a62c110a2f00/original";
  } else if (awayId === joinId) {
    avatarSource = awayLogo ? awayLogo : "https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/5a644523-45d9-4e66-7609-a62c110a2f00/original";
  } else {
    avatarSource = logoFirst ? logoFirst : "https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/5a644523-45d9-4e66-7609-a62c110a2f00/original";
  }
  
  const symbol = '₺';
  
  return (
    <CardContainer isOdd={isOdd} className="campaign-donors-three-card-container">
      <AvatarContainer>

        <Avatar src={avatarSource} alt={username} />

        <CardContent>
            <Username>{username}</Username>
            <TeamName>{desc}</TeamName>
        </CardContent>
      </AvatarContainer>
      <Amount symbol={symbol}>
      <CurrencyFormat amount={firstAmount || 0}/>
      </Amount>
    </CardContainer>
  );
};

export default CampaignDonorsThreeCard;

export const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
  // border-top: 1px solid #eee;
  background-color: ${props => (props.isOdd ? "#f5f5f5" : "white")};
`;

export const AvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Avatar = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 50%;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
`;

export const Username = styled.div`
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
`;

export const TeamName = styled.small`
  color: #808080;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Amount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: green;
  font-size: 18px;
  font-weight: 500;

  ::after {
    content: "${(props) => props.symbol}";
    margin-left: 8px;
    color: #4caf50;
    font-size: 16px;
  }
`;
