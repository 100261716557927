/* This code defines a React functional component called `CampaignTransfer` that takes `campaignData`
as a prop. Within the component, it handles click events for logo and join buttons, and redirects
the user to different pages based on the data provided. */
import React from "react";
import styled from "styled-components";
import { JoinButton, CurrencyFormat } from "../../components";
import { NotFound } from "../../pages";
import {BsChevronRight} from "react-icons/bs";
import { useTranslation } from "react-i18next";
import RemoveTurkishChars from "../../logic/RemoveTurkishChars";
import { getStartLanguage } from "../../logic/getStartLanguage";

const CampaignTransfer = ({ campaignData }) => {

  const symbol = '₺';

  const { t } = useTranslation();

  const convertToTitleCase = (str) => {
    return str.charAt(0).toLowerCase() + str.slice(1);
  };

  const handleLogoClick = (team, teamId) => {

    if (team, teamId) {
      const startLanguage = getStartLanguage();
      const sportType= campaignData.detail.sportName.replace(/ /g, "-").toLowerCase();
      const formattedCampaignType = RemoveTurkishChars({ text: t("team") });
      const formattedTeamName = convertToTitleCase(team);

      window.location.href = `/${startLanguage}/${sportType}/${formattedCampaignType}/${formattedTeamName}/${teamId}`;
      // console.log(`Tıklanan takım: ${formattedTeamName}`);
      // console.log("Tıklanan takım", teamId);

    } else{
      return <NotFound />
    }


  };

  const handleJoinClick = (campaign) => {

    if (campaign) {
      const startLanguage = getStartLanguage();
      const sportType = campaignData.detail.sportName.replace(/ /g, "-").toLowerCase();
      const formattedCampaignType = RemoveTurkishChars({ text: t("transfer") });
      const leagueTypeSlug = campaignData.detail.tournamentName?.replace(/ /g, "-").toLowerCase();
      const campaignTitle = campaignData.title.replace(/ /g, "-").toLowerCase();
      const formattedCampaignTitle = RemoveTurkishChars({ text: campaignTitle });

      const url  = `/${startLanguage}/${sportType}/${formattedCampaignType}/${leagueTypeSlug}/${formattedCampaignTitle}/${campaign}`;
      
      window.location.href = url;
  
    } else  {
      return <NotFound />
      // console.log("Geçersiz campaignId, sayfaya gidilemiyor.");
    }
  }

  return (
    <>
      <CampaignsBoxContent className='campaign-box-content'>
        <CampaignsBox>
          <CampaignsBoxWrapper>

            <div onClick={() => handleLogoClick(campaignData.detail.homeTeam.name, campaignData.detail.homeTeam.id)}>
            <LogoFirstImage src={ campaignData?.detail?.homeTeam?.extraLargeBadge ? campaignData?.detail?.homeTeam?.extraLargeBadge : "https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/6f6a87c5-fd75-45c3-0f8c-33977a168700/original"} alt="" />
            </div>

            <CampaignsBoxDesc>
              <CampaignsBoxDescTitle>{campaignData?.title || ""}</CampaignsBoxDescTitle>

              <CampaignsBoxCenter>
                <CampaignsBoxAmount symbol={symbol}>
                <CurrencyFormat amount={campaignData?.detail?.homeAmount || 0}/>
                </CampaignsBoxAmount>
              </CampaignsBoxCenter>
            </CampaignsBoxDesc>

          </CampaignsBoxWrapper>

          <div onClick={() => handleJoinClick(campaignData?.id) }>
            <JoinButton
                width="50px"
                mediaWidth="20px"
                backgroundColor="transparent"
                textColor="black"
              >
              <BsChevronRight size={25} />
            </JoinButton>
          </div>
        </CampaignsBox>
      </CampaignsBoxContent>
    </>
  );
};

export default CampaignTransfer;

export const CampaignsBoxContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  padding: 10px;
  border-top: 1px solid #eee;

  &:hover {
    scale: 1.1;
    transition-duration: 0.6s;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
`;
export const CampaignsBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
export const CampaignsBoxWrapper = styled.div`
  display: flex;
  gap: 10px;
`;
export const LogoFirstImage = styled.img`
  width: 36px;
  height: 36px;
  cursor: pointer;

  @media screen and (min-width: 1024px) and (max-width: 1514px) {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
`;
export const CampaignsBoxDesc = styled.div``;

export const CampaignsBoxAmount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #555;

  ::after {
    content: "${(props) => props.symbol}";
    margin-left: 8px;
    color: #4caf50;
    font-size: 16px;
  }
`;

export const CampaignsBoxCenter = styled.div`
  display: flex;
`;
export const CampaignsBoxDescTitle = styled.div`
  @media screen and (min-width: 1280px) and (max-width: 1320px) {
    font-size: 13px;
  }
  @media screen and (min-width: 1024px) and (max-width: 1280px) {
    font-size: 10px;
  }
`;
