/* The above code is a React component called `PollCard` that displays a poll questionnaire to users.
Here is a breakdown of what the code is doing: */
import React, { useEffect, useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import { Dialog, Text, Button } from "@mantine/core";
import styled from "styled-components";
import { postAnswer } from "../../services/poll/pollAnswerService";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPollUserAnswers } from "../../services/poll/pollUserAnswersService";
import { getPoll } from "../../services/poll/pollService";
import { FaArrowRight } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { NotFound } from "../../pages";
import LanguageTag from "../../enums/LanguageTag";
import { getPollPrivate } from "../../services/poll/pollServicePrivate";
import RemoveTurkishChars from "../../logic/RemoveTurkishChars";
import { getStartLanguage } from "../../logic/getStartLanguage";

const PollCard = ({ useDialog }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [postAnswerData, setPostAnswerData] = useState();
  const [answers, setAnswers] = useState([]);
  const [opened, { toggle, close }] = useDisclosure(true);
  const [pollData, setPollData] = useState([]);
  const [pollUserAnswers, setPollUserAnswers] = useState([]);

  const ContentComponent = useDialog ? Dialog : DivContent;

  const navigate = useNavigate();
  const { t } = useTranslation();
  const startLanguage = getStartLanguage();

  const user  = useSelector((state) => state.user);

  const selectedSportId = useSelector(state => state.filter.sportId);
  const selectedCountryId = useSelector(state => state.filter.countryId);
  const selectedTournamentId = useSelector(state => state.filter.tournamentId);
  const selectedTeamId = useSelector((state) => state.filter.teamId);
  const languageId = useSelector((state) => state.filter.languageId);

  const handleAnswer = (answer) => {
    const newAnswers = [...answers];
    newAnswers[currentQuestionIndex] = answer;
    setAnswers(newAnswers);
  };

  const handleNext = async () => {
    const currentAnswer = answers[currentQuestionIndex];
    const currentAnswerId = pollData[currentQuestionIndex].questions.find(question => question.questionText === currentAnswer);
    const currentQuestionId = pollData[currentQuestionIndex]
  
    if (currentQuestionIndex < pollData.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);

      if(user.user){
        try {
          const pollId = currentQuestionId.id;
          const answerId = currentAnswerId.id;
    
          const res = await postAnswer({ pollId, answerId });
  
          setPostAnswerData(res || {});
          
        } catch (error) {
          console.error("Error", error);
        }
      }
      else {
        navigate(`/${startLanguage}/${RemoveTurkishChars({ text: t("login") })}`)
      }

    } else {
      // console.log("Anket tamamlandı, cevaplar: ", answers.filter(answer => answer !== null));
      close();
    }
  };

  

  const fetchPoll = async () => {

    const sportId = selectedSportId;
    const startCountryId = localStorage.getItem("startCountryId");
    const countryId = selectedCountryId === "" ? startCountryId : selectedCountryId;
    const tournamentId = selectedTournamentId;
    const teamId = selectedTeamId;
    const page = 1;
    const perPage = 5; 

    if(user.user){
      const resPoll = await getPollPrivate({ sportId, countryId, tournamentId, teamId, languageId, page, perPage })

      if (!resPoll?.data?.data?.data || resPoll.data.data.data.length === 0) {
        close();
      } else {
        setPollData(resPoll.data.data.data);
      }
    }
    else {
      const resPoll = await getPoll({ sportId, countryId, tournamentId, teamId, languageId, page, perPage })

      if (!resPoll?.data?.data?.data || resPoll.data.data.data.length === 0) {
        close();
      } else {
        setPollData(resPoll.data.data.data);
      }
    }
  }

  useEffect(() => {
    fetchPoll();
  },[selectedSportId, selectedCountryId, selectedTournamentId, selectedTeamId])

 
  return (
    <>
      <ContentComponent opened={opened} onClose={close} withCloseButton size="lg" radius="md" className="poll-card-dark">
        {pollData && pollData.length > 0 ? ( // pollData varsa ve boş değilse
          <Content>
            <Text size="md" mb="md" fw={"bold"}>
              {t("pollTitle")}
            </Text>
            {currentQuestionIndex < pollData.length && (
              <>
                <QuestionText>{pollData[currentQuestionIndex].title}</QuestionText>
                <OptionsContainer>
                  {pollData[currentQuestionIndex].questions.map((question, index) => (
                    <Option key={index} onClick={() => handleAnswer(question.questionText)}>
                      <RadioButton type="radio" name="option" id={`option-${index}`} checked={answers[currentQuestionIndex] === question.questionText} />
                      <RadioLabel htmlFor={`option-${index}`}>{question.questionText}</RadioLabel>
                    </Option>
                  ))}
                </OptionsContainer>

                <ButtonContainer>
                  <Button
                    variant="filled"
                    color="green"
                    onClick={handleNext}
                  >              
                    <span style={{ marginRight: "10px" }}>{t("next")}</span>
                    <FaArrowRight size={12} />
                  </Button>  
                </ButtonContainer>    
                
              </>
            )}
          </Content>
        ) : (
          <NotFound margin="42px auto" text={t("pollWarning")} />
        )}
      </ContentComponent>
    </>
  );
};

export default PollCard;

export const Content = styled.div`
  padding: 5px;
`;

export const QuestionText = styled(Text)`
  font-weight: 600;
  margin-bottom: 10px;
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Option = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const RadioButton = styled.input`
  margin-right: 5px;
`;

export const RadioLabel = styled.span`
  cursor: pointer;
`;

export const DivContent = styled.div`
  width: auto;
  background-color: white;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #dbe0e5;

  &:hover {
    box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16); 
  }

  @media (max-width: 720px) {
    width: auto;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
`;